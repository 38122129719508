import React, { useContext, useEffect, useState } from 'react';
import { Container } from './styles';
import { DELETE_AUDITORIUM } from '../../services/endpoints';

// components
import Dialogodeconfirmacao from '../Dialogodeconfirmacao';

// icons
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';

// utils images
import imageUrl from '../../utils/imageUrl';

// components
import Backdrop from '../Backdrop';
import Novoauditorio from '../Novoauditorio';

// context
import { EdicaoEvento } from '../../context/edicaoEvento';

function DetalhesAuditorio({ program, user, event, atualizar }) {
  const { getDataAuditoriums, listAuditoriums, getDataGeneralSchedules } =
    useContext(EdicaoEvento);
  const queryNumber = Number(window.location.pathname.split('/')[2]);
  const [backdrop, setBackdrop] = React.useState(false);

  const [dados] = useState(program ? program : {});
  const [dadosPrevia, setDadosPrevia] = useState(program ? program : []);
  const [dadosNovos, setDadosNovos] = useState([]);

  const [update, setUpdate] = useState(false);
  const [active, setActive] = useState(false);
  const [confirmacao, setConfirmacao] = useState([false, '']);
  const [showImage, setShowimage] = useState(true);

  const [abaselected, setAbaselected] = useState(0);

  const [dialogoNewAuditorium, setDialogoNewAuditorium] = React.useState({
    status: false,
    editar: false,
    dados: '',
  });

  const cancelarAtualizazao = () => {
    setUpdate(!update);
    setDadosNovos(dados);
    setDadosPrevia(dados);
  };

  useEffect(() => {
    if (listAuditoriums?.length === 0)
      getDataAuditoriums(queryNumber, user.token);
  }, [listAuditoriums?.length, queryNumber]);

  useEffect(() => {
    setShowimage(false);
    setTimeout(() => setShowimage(true), 1);
  }, [abaselected]);

  return (
    <Container update={update} active={active}>
      <div className="header">
        <div
          className="left"
          onClick={() => (update ? '' : setActive(!active))}
        >
          <span className="icon">&#10148;</span>
          <h2 className="title">Auditórios</h2>
        </div>
      </div>
      <div className="content">
        <div className="campoDeEdicao">
          <div className="header2">
            <button
              className="cadastroManual"
              onClick={() =>
                setDialogoNewAuditorium({
                  status: true,
                  editar: false,
                  dados: '',
                })
              }
            >
              + Adicionar
            </button>
          </div>
        </div>
        <div className="div__group_auditoriums">
          <div className="div__group_head">
            {listAuditoriums?.length !== 0 &&
              listAuditoriums?.map((unitAuditorium, idx) => (
                <button
                  className={idx === abaselected && 'selected'}
                  onClick={() => setAbaselected(idx)}
                >
                  {unitAuditorium.name}
                </button>
              ))}
          </div>

          <div className="div__body_auditoriums">
            {listAuditoriums?.filter((aud, idx) => idx === abaselected)
              .length !== 0 ? (
              <div
                className="div__oneRow"
                style={{ justifyContent: 'space-between' }}
              >
                <div className="div__oneColumn">
                  <div className="div__body_name">
                    <b>Nome:</b>{' '}
                    {
                      listAuditoriums?.find((aud, idx) => idx === abaselected)
                        ?.name
                    }
                  </div>

                  <div className="div__body_description">
                    <b>Descrição:</b>{' '}
                    <p>
                      {listAuditoriums?.find((aud, idx) => idx === abaselected)
                        ?.description || (
                        <span style={{ color: 'rgba(0,0,0,0.5)' }}>
                          Sem descrição
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <div classname="div__oneColumn">
                  <div className="div__options_buttons">
                    <button
                      className="btn__edit_auditorium"
                      onClick={() =>
                        setDialogoNewAuditorium({
                          status: true,
                          editar: true,
                          dados: listAuditoriums?.find(
                            (aud, idx) => idx === abaselected
                          ),
                        })
                      }
                    >
                      <EditIcon /> Editar
                    </button>
                    <button
                      className="btn__delete_auditorium"
                      onClick={() =>
                        setConfirmacao([
                          true,
                          listAuditoriums?.find(
                            (aud, idx) => idx === abaselected
                          ),
                        ])
                      }
                    >
                      <DeleteIcon /> Deletar
                    </button>
                  </div>
                  <div className="div__body_image">
                    {listAuditoriums?.find((aud, idx) => idx === abaselected)
                      ?.image ? (
                      showImage && (
                        <img
                          src={imageUrl(
                            listAuditoriums?.find(
                              (aud, idx) => idx === abaselected
                            )?.image
                          )}
                          alt="img_name"
                          loading="lazy"
                        />
                      )
                    ) : (
                      <>
                        <CropOriginalIcon />
                        <span>Nenhuma imagem vinculada</span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <h3>Nenhum auditório cadastrado</h3>
            )}
          </div>
        </div>
      </div>

      {dialogoNewAuditorium.status ? (
        <Novoauditorio
          editar={dialogoNewAuditorium.editar}
          dados={dialogoNewAuditorium.dados}
          tamanho="sm"
          user={user}
          event_id={queryNumber}
          success={() => {
            getDataAuditoriums(queryNumber, user.token);
            setDialogoNewAuditorium({
              status: false,
              editar: false,
              dados: '',
            });
          }}
          negar={() =>
            setDialogoNewAuditorium({ status: false, editar: false, dados: '' })
          }
        />
      ) : (
        ''
      )}
      {confirmacao[0] && (
        <Dialogodeconfirmacao
          tamanho="xs"
          message={`Deseja realmente deletar o auditório ${confirmacao[1].name}?`}
          confirmar={async () => {
            await DELETE_AUDITORIUM(
              confirmacao[1],
              () => getDataAuditoriums(queryNumber, user.token),
              setBackdrop,
              user.token
            );
            setConfirmacao([false, '']);
            getDataAuditoriums(queryNumber, user.token);
            getDataGeneralSchedules(queryNumber, setBackdrop, user.token);
          }}
          negar={() => setConfirmacao([false, ''])}
        />
      )}
      {backdrop && <Backdrop />}
    </Container>
  );
}

export default DetalhesAuditorio;
