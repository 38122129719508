import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function MaxWidthDialog(props) {
  const [open, setOpen] = React.useState(true);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState(props.tamanho);

  const handleClose = () => {
    setOpen(false);
    props.negar();
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Confirmação</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p style={{ fontSize: '16px' }}>{props.message}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.negar()} color="" variant="contained">
            Não
          </Button>
          <Button
            onClick={() => props.confirmar()}
            color="secondary"
            variant="contained"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
