import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  // backgroundColor:'transparent',
  border: 0px;
  border-radius: 6px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  //  max-width:1000px;
  margin: auto;
  padding: 2rem;

  header {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #d3d3d3;

    h1 {
      font-weight: 800;
      color: rgba(0, 0, 0, 0.8);
    }

    p {
      color: rgba(0, 0, 0, 0.7);
      font-weight: 600;
    }
  }

  .container__button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    button {
      border: none;
      outline: none;
      border-radius: 0.25rem;

      padding: 0.6rem 1rem;
      background: ${(props) => props.theme.colors.primaryDark};

      font-weight: 700;
      color: var(--white);
      text-transform: uppercase;
    }
  }

  .posts {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: left;
    align-items: left;
  }
`;

export const Post = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .post {
    margin: 15px 15px 0px 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);

    background-image: url(${(props) => props.background});
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
    transition: 1s;
    border-radius: 1rem 1rem 0 0;

    :hover {
      /* transform: scale(1.1); */
      .postInfo {
        transition: 1s;
        margin-top: 0px;
      }
      .postDesc {
        animation: 3s fadeIn;
        display: -webkit-box;
      }
      .gradient {
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.9)
        );
      }
      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }
  }

  .gradient {
    width: 300px;
    height: 200px;
    /* border-radius: 5%;  */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.5)
    );
  }

  .postInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5%;
    padding: 1rem;
    margin-top: 100px;
  }

  .postCat {
    font-family: 'Varela Round', Arial, Helvetica, sans-serif !important;
    font-weight: bold;
    font-size: 14px;
    color: #f6921e;
    line-height: 19px;
    //margin-top: 15px;
    margin-right: 10px;
    //cursor: pointer;
    /* transform: brightness(1.5); */
  }

  .postTitle {
    font-family: 'Josefin Sans', Arial, Helvetica, sans-serif !important;
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    //margin-top: 15px;
    color: white;
    cursor: pointer;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .postDate {
    font-family: 'Lora', serif !important;
    font-style: italic;
    font-size: 13px;
    font-weight: 400;
    color: white;
    //margin-top: 15px;
  }

  .postDesc {
    font-family: 'Varela Round', Arial, Helvetica, sans-serif !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: white;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: none;
    //display: -webkit-box;+
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .rascu {
    font-weight: 100;
    margin: auto;
    margin-left: 1rem;
    font-size: 12px;
    background-color: #e65100;
    padding: 0.3rem;
    color: #fff;
    border-radius: 5px;
    left: 0;
    height: auto;
    font-weight: 400;
  }
  .publi {
    font-weight: 100;
    margin: auto;
    margin-left: 1rem;
    font-size: 12px;
    background-color: #00c853;
    padding: 0.3rem;
    color: #fff;
    border-radius: 5px;
    font-weight: 400;
    height: auto;
  }

  .agend {
    font-weight: 100;
    margin: auto;
    margin-left: 1rem;
    font-size: 12px;
    background-color: #9e9e9e;
    padding: 0.3rem;
    color: #fff;
    border-radius: 5px;
    font-weight: 400;
    height: auto;
  }

  @media (max-width: 765px) {
    .post {
      transform: scale(0.8);
      margin: 0rem 0rem;
    }

    .post:hover {
      transform: scale(0.9);
    }
  }

  .buttons {
    display: flex;
    flex: 1;

    width: 300px;

    flex-direction: row;
    background: ${(props) => props.theme.colors.primaryDark};
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    border-radius: 0 0 1rem 1rem;
    padding: 0rem;

    justify-content: flex-end;

    button {
      display: flex;
      height: 4rem;
      width: 3.5rem;
      background: none;
      border: 0;
      border-radius: 0;
      /* margin-right: 1rem; */
      margin-top: -0.5rem;
      justify-content: center;
      align-items: center;
      background-color: var(--white);

      &:first-child {
        border-radius: 1rem 0 0 0;
      }
      &:last-child {
        border-radius: 0 0 1rem 0;
      }

      :hover {
        background: #a7a7a7;
        /* border-radius: 50%; */
        /* transform: scale(0.9); */

        &.delete {
          background: #f44336;
        }

        svg {
          color: #fff !important;
        }
      }
    }
  }
`;
