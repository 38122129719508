import styled from 'styled-components';
export const Container = styled.div`
  .tituloTabela {
    font-weight: 800;
    font-size: 14px;
    padding: 1rem;
  }
  .tituloTabela:hover {
    background-color: rgba(255, 255, 255, 0) !important;
  }
  .ul {
    display: flex;
    flex-direction: row;
    font-size: 13px;
    padding: 0.5rem 1rem;
    border: 1px solid var(--gray-200);
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;

    .li {
      align-items: center;
      display: flex;
      flex-direction: row;
      width: 100%;

      small {
        border-radius: 1rem;
        margin: 0rem 0.5rem;
        padding: 0.4rem 0.5rem;
        cursor: default;
      }
      .smal1 {
        background-color: #009cc7;
        color: #fff;
      }
      .smal2 {
        background-color: #0b1d45;
        color: #fff;
      }
      .smallnormal {
        background-color: #f7e373;
        color: brown;
        margin-left: -0.2rem;
      }
      .smallvip {
        /* background-color: orange; */
        background-image: linear-gradient(to top right, orange, yellow);
        color: brown;
        min-width: 4rem;
        text-align: center;
        margin-left: -0.2rem;
      }
    }
  }
  .ul:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .botaoBaixarExcel {
    background-color: #217346;
    margin: 1rem auto;
    align-self: center;
    border: 0px;
    color: #fff;
    padding: 1rem 1.25rem;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    img {
      width: 20px;
      margin-right: 0.5rem;
    }
  }
  .botaoBaixarExcel:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }

  .searchButton {
    border-radius: 5px;
    padding: 0.75rem 1rem;
    background-color: ${(props) => props.theme.colors.primaryDark};
    color: #fff;
    border: 0px solid rgba(0, 0, 0, 0);
    margin: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3rem;
    //align-self: flex-end;
  }

  .MenuDireita {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .cadastroManual {
      background-color: ${(props) => props.theme.colors.primaryDark};
      /* margin: 0.8rem auto; */
      align-self: center;
      border: 0px;
      color: #fff;
      padding: 1.15rem 1.25rem;
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      img {
        width: 20px;
        margin-right: 0.5rem;
      }
    }
    .cadastroManual:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    }
  }

  .evento_options {
    margin-left: 1rem;
  }

  .selectMenu {
    display: flex;
    flex-direction: row;
    padding: 0rem 2rem;
    margin-top: 0rem;

    .selecao {
      display: flex;
      flex-direction: row;
      justify-content: center;
      justify-items: center;
      align-items: center;
      padding: 0rem 0.2rem;

      input {
        margin-right: 0.3rem;
      }
    }
  }

  .selectMenu2 {
    display: flex;
    flex-direction: row;
    padding: 1rem 1rem;
    margin-top: -1rem;

    .selecao {
      display: flex;
      flex-direction: row;
      justify-content: center;
      justify-items: center;
      align-items: center;
      padding: 0rem 0.2rem;
      padding-left: 0.5rem;

      input {
        margin-right: 0.3rem;
      }
    }
  }

  @media only screen and (max-width: 675px) {
    .campos_de_busca {
      display: flex;
      flex-direction: column !important;
    }
  }
`;
