import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

// icone do excel
import excelicon from '../../files/images/excel-icon.png';

// backdrop
import Backdrop from '../../components/Backdrop';
// alert
import Snackbar from '@material-ui/core/Snackbar';

import { Conteudo } from './styles';
//campo de data
import 'date-fns';
import { isAfter } from 'date-fns';

import format from 'date-fns/format';
import xlsx from 'json-as-xlsx';

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(true);
  const [loading] = React.useState(false);

  const [alerta, setAlerta] = React.useState({
    open: false,
    status: '',
    message: '',
  });

  const handleClose = () => {
    setOpen(false);
    props.closeDialog();
  };

  var convert = function () {
    var columns = [
      {
        label: 'Data de Inscrição',
        value: (row) =>
          format(new Date(row.created_at), 'dd/MM/yyyy') +
          ' às ' +
          format(new Date(row.created_at), 'HH:mm'),
      }, // Top level data
      { label: 'Cód. Inscrição', value: (row) => row.id }, // Top level data
      { label: 'Nome', value: (row) => row.name }, // Run functions
      { label: 'E-mail', value: (row) => row.email }, // Run functions
      { label: 'CPF', value: (row) => row.cpf }, // Run functions
      { label: 'Telefone', value: (row) => row.phone }, // Run functions
      { label: 'Estado', value: (row) => row.state }, // Run functions
      { label: 'Cidade', value: (row) => row.city }, // Run functions
      { label: 'Bairrro', value: (row) => row.district }, // Run functions
      { label: 'Endereço', value: (row) => row.street }, // Run functions
      { label: 'Número', value: (row) => row.house_number }, // Run functions
      {
        label: 'Complemento',
        value: (row) => row.complement || '-',
      }, // Run functions
      {
        label: 'Categoria',
        value: (row) => row.category || 'Normal',
      },
      {
        label: 'Visitante',
        value: (row) => (row.is_exhibitor ? 'Não' : 'Sim'),
      }, // Run functions
      { label: 'Nome da Credencial', value: (row) => row.credential_name }, // Run functions
      { label: 'Empresa/Instituição', value: (row) => row.credential_company }, // Run functions
      { label: 'Ocupação/Cargo', value: (row) => row.credential_role }, // Run functions
      //{ label: 'Tipo de Inscrição', value: (row) => row.type_inscription }, // Run functions

      {
        label: 'Tipo de Inscrição',
        value: (row) =>
          row.type_inscription
            ? row.type_inscription === 'Outro'
              ? `Outro - ${row.another_type_inscription || 'Não informado'}`
              : row.type_inscription
            : 'Não informado',
      },
      {
        label: 'Aceitou os termos de uso',
        value: (row) => (row.agree_privacy_policies ? 'Sim' : 'Não'),
      },
      {
        label: 'Permissão para usar imagem',
        value: (row) => (row.allow_use_image ? 'Sim' : 'Não'),
      },
      {
        label: 'Quem indicou',
        value: (row) =>
          row.who_indicated
            ? row.who_indicated === 'Outro'
              ? `Outro - ${row.another_indication || 'Não informado'}`
              : row.who_indicated
            : 'Não informado',
      },
      {
        label: 'Presente',
        value: (row) =>
          props.listaeventos.filter((e) => e.id === row.event_id)[0].start_date
            ? !isAfter(
                new Date(
                  props.listaeventos.filter(
                    (e) => e.id === row.event_id
                  )[0].start_date
                ),
                new Date()
              )
              ? row.participated
                ? 'Sim'
                : 'Não'
              : ' - '
            : ' - ',
      },

      // { label: 'Tipo', value: row => (row[2]) }, // Deep props
      // { label: 'Coordenadas', value: row => (row[3])},
      // { label: 'UTMY-N', value: row => (row[4])},
      // { label: 'UTMX-E', value: row => (row[5])}
    ];

    var content = [props.dadosclient];
    // console.log("dados: ", content);

    let data = [
      {
        sheet: 'Mailing',
        columns: columns,
        content: content,
      },
    ];

    var settings = {
      fileName: `${props.dadosclient.id} - ${props.dadosclient.name}`, // The name of the spreadsheet
      extraLength: 3, // A bigger number means that columns should be wider
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };

    var download = true; // If true will download the xlsx file, otherwise will return a buffer

    // xlsx(columns, content, settings, download);
    xlsx(data, settings);
  };

  const qualCurso = (e) => {
    if (e.id === props.dadosclient.event_id) {
      return e;
    }
  };

  var curso2 = props.listaeventos.filter(qualCurso);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'body'}
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">{"Confirme os dados preenchidos"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Conteudo id="sua_div">
              <h3>Dados do Inscrito</h3>
              {/* <Divider/> */}
              <div className="linhaInfo">
                <div className="dadoInfo">
                  <label>Evento:</label>{' '}
                  {format(new Date(curso2[0].start_date), 'dd/MM/yyyy')}{' '}
                  {curso2[0].city} - {curso2[0].state}
                </div>
                <div className="dadoInfo">
                  <label>Data da Inscrição: </label>{' '}
                  {format(
                    new Date(props.dadosclient.created_at),
                    'dd/MM/yyyy'
                  ) +
                    ' às ' +
                    format(new Date(props.dadosclient.created_at), 'HH:mm')}
                </div>
                <div className="dadoInfo">
                  <label>Inscrição: </label> {props.dadosclient.id}
                </div>
                <div className="dadoInfo">
                  <label>Categoria: </label>{' '}
                  {props.dadosclient.category || 'Normal'}
                </div>
                <div className="dadoInfo">
                  <label>Visitante: </label>{' '}
                  {props.dadosclient.is_exhibitor ? 'Não' : 'Sim'}
                </div>
                <div className="dadoInfo">
                  <label>Nome: </label> {props.dadosclient.name}
                </div>
                <div className="dadoInfo">
                  <label>E-mail: </label> {props.dadosclient.email}
                </div>
                <div className="dadoInfo">
                  <label>CPF: </label> {props.dadosclient.cpf || ' - '}
                </div>
                <div className="dadoInfo">
                  <label>RNE: </label> {props.dadosclient.rne || ' - '}
                </div>
                <div className="dadoInfo">
                  <label>Telefone/Whatsapp: </label> {props.dadosclient.phone}
                </div>
                <div className="dadoInfo">
                  <label>Estado: </label> {props.dadosclient.state}
                </div>
                <div className="dadoInfo">
                  <label>Cidade: </label> {props.dadosclient.city}
                </div>
                <div className="dadoInfo">
                  <label>Bairro: </label> {props.dadosclient.district}
                </div>
                <div className="dadoInfo">
                  <label>Endereço: </label> {props.dadosclient.street}
                </div>
                <div className="dadoInfo">
                  <label>Número: </label> {props.dadosclient.house_number}
                </div>
                <div className="dadoInfo">
                  <label>Complemento: </label>{' '}
                  {props.dadosclient.complement || 'Não informado'}
                </div>
                <div>
                  <label>Aceitou os termos de uso: </label>
                  {props.dadosclient.agree_privacy_policies ? 'Sim' : 'Não'}
                </div>
                <div>
                  <label>Permissão para usar imagem: </label>
                  {props.dadosclient.allow_use_image ? 'Sim' : 'Não'}
                </div>
                <div>
                  <label>Quem indicou: </label>
                  {props.dadosclient.who_indicated
                    ? props.dadosclient.who_indicated === 'Outro'
                      ? `Outro - ${
                          props.dadosclient.another_indication ||
                          'Não informado'
                        }`
                      : props.dadosclient.who_indicated
                    : 'Não informado'}
                </div>
                <div>
                  <label>
                    Possui serviços de IPTV, telefonia, etc? Quais:{' '}
                  </label>
                  {props?.dadosclient?.has_service_tv_iptv || 'Não informado'}
                </div>
                <div>
                  <label>Possui rede de Fibra Ótica FTTX: </label>
                  {props?.dadosclient?.has_fiber_optic ? 'SIM' : 'NÃO'}
                </div>
                <div>
                  <label>Tem ASN: </label>
                  {props?.dadosclient?.has_asn ? 'SIM' : 'NÃO'}
                </div>
                <div>
                  <label>Porte da empresa: </label>
                  {props?.dadosclient?.company_size &&
                    props?.dadosclient?.company_size}
                </div>
                <div>
                  <label>Quantidade de link contratado: </label>
                  {props?.dadosclient?.contracted_link &&
                    props?.dadosclient?.contracted_link}
                </div>
              </div>

              <h4>Para Credenciais</h4>

              <div className="linhaInfo">
                <div className="dadoInfo">
                  <label>Nome: </label> {props.dadosclient.credential_name}
                </div>
                <div className="dadoInfo">
                  <label>Empresa/Instituição: </label>{' '}
                  {props.dadosclient.credential_company}
                </div>
                <div className="dadoInfo">
                  <label>Ocupação/Cargo: </label>{' '}
                  {props.dadosclient.credential_role}
                </div>
                <div className="dadoInfo">
                  <label>Tipo de Inscrição: </label>{' '}
                  {props.dadosclient.type_inscription
                    ? props.dadosclient.type_inscription === 'Outro'
                      ? `Outro - ${
                          props.dadosclient.another_type_inscription ||
                          'Não informado'
                        }`
                      : props.dadosclient.type_inscription
                    : 'Não informado'}
                </div>
              </div>
              <div className="campoBaixar">
                <button className="botaoBaixarExcel" onClick={() => convert()}>
                  {' '}
                  <img src={excelicon} alt="icon_excel" />
                  Baixar dados
                </button>
              </div>
            </Conteudo>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Voltar
          </Button>
          {/* <Button onClick={e=> props.confirmar()} variant="contained" style={{backgroundColor:'#f2a100',color:'#fff'}} >
            Confirmar
          </Button> */}
        </DialogActions>
        {loading ? <Backdrop /> : ''}
        {alerta.open ? (
          <Snackbar
            alerta={alerta}
            fechar={(e) => setAlerta({ open: false, status: '', message: '' })}
          />
        ) : (
          ''
        )}
      </Dialog>
    </div>
  );
}
