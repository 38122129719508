import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import { format, compareAsc } from 'date-fns';

// ICONES
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';

import { Container } from './styles';

// icones
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

// mascara
import InputMask from 'react-input-mask';
import api from '../../services/api';

export default function ScrollDialog(props) {
  const [open, setOpen] = React.useState(props.onInscricao);
  const [scroll] = React.useState('paper');

  const [dados, setDados] = React.useState({
    event_id: props.edicao ? props.edicao.event_id : '',
    cadastro: props.edicao
      ? props.edicao.is_exhibitor
        ? 'expositor'
        : 'visitante'
      : 'visitante',
    category: props.edicao ? props.edicao.category : '',
    name: props.edicao ? props.edicao.name : '',
    mail: props.edicao ? props.edicao.email : '',
    cpf: props.edicao ? props.edicao.cpf : '',
    phone: props.edicao ? props.edicao.phone : '',
    state: props.edicao ? props.edicao.state : '',
    city: props.edicao ? props.edicao.city : '',
    street: props.edicao ? props.edicao.district : '',
    adress: props.edicao ? props.edicao.street : '',
    number: props.edicao ? props.edicao.house_number : '',
    complement: props.edicao ? props.edicao.complement : '',
    name_credential: props.edicao ? props.edicao.credential_name : '',
    empresa_credential: props.edicao ? props.edicao.credential_company : '',
    ocupation_credential: props.edicao ? props.edicao.credential_role : '',
    type_inscription: props.edicao ? props.edicao.type_inscription : '',
    another_type_inscription: props.edicao
      ? props.edicao.another_type_inscription
      : null,
    termos: props.edicao ? props.edicao.agree_privacy_policies : true,
    consentimento: props.edicao ? props.edicao.allow_use_image : true,
    who_indicated: props.edicao ? props.edicao.who_indicated : '',
    another_indication: props.edicao ? props.edicao.another_indication : null,
    has_fiber_optic: props.edicao ? props.edicao.has_fiber_optic : null,
    has_service_tv_iptv: props.edicao ? props.edicao.has_service_tv_iptv : '',
    has_asn: props.edicao ? props.edicao.has_asn : '',
    company_size: props.edicao ? props.edicao.company_size : '',
    contracted_link: props.edicao ? props.edicao.contracted_link : '',
  });

  const [enviado, setEnviado] = React.useState(false);
  const [emProcesso, setEmProcesso] = React.useState(false);

  const [messageError, setMessageError] = React.useState('');

  const handleClose = () => {
    setOpen(false);
    props.offInscricao();
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleChange = (e) => {
    setDados({ ...dados, [e.target.name]: e.target.value });
  };

  const config = {
    headers: {
      Authorization: 'Bearer ' + props.user.token,
      'Content-Type': 'application/json',
    },
  };

  const enviar = async (e) => {
    e.preventDefault();
    setEmProcesso(true);

    let inscricao = {
      is_exhibitor: dados.cadastro === 'visitante' ? false : true,
      event_id: dados.event_id,
      name: dados.name,
      email: dados.mail,
      cpf: dados.cpf,
      category: dados.category,
      phone: dados.phone,
      state: dados.state,
      city: dados.city,
      district: dados.street,
      street: dados.adress,
      house_number: dados.number,
      complement: dados.complement,
      credential_name: dados.name_credential,
      credential_company: dados.empresa_credential,
      credential_role: dados.ocupation_credential,
      type_inscription: dados.type_inscription,
      another_type_inscription:
        dados.type_inscription === 'Outro'
          ? dados.another_type_inscription
          : dados.type_inscription === 'profissional da área' ||
            dados.type_inscription === 'estudante da área' ||
            dados.type_inscription === 'empresa da área'
          ? dados.type_inscription + ' - ' + dados.another_type_inscription
          : null,
      agree_privacy_policies: dados.termos,
      allow_use_image: dados.consentimento,
      who_indicated: dados.who_indicated === '' ? null : dados.who_indicated,
      another_indication:
        dados.who_indicated === 'Outro' ? dados.another_indication : null,
      has_service_tv_iptv:
        process.env.REACT_APP_TYPE_SITE === 'isp'
          ? dados.has_service_tv_iptv
          : null,
      has_fiber_optic:
        process.env.REACT_APP_TYPE_SITE === 'isp'
          ? dados.has_fiber_optic === 'true'
            ? true
            : false
          : null,
      has_asn:
        process.env.REACT_APP_TYPE_SITE === 'isp'
          ? dados.has_asn === 'true'
            ? true
            : false
          : null,
      company_size:
        process.env.REACT_APP_TYPE_SITE === 'isp' ? dados.company_size : null,
      contracted_link:
        process.env.REACT_APP_TYPE_SITE === 'isp'
          ? dados.contracted_link
          : null,
    };

    if (props.edicao) {
      await api
        .put(`/enrollments/${props.edicao.id}`, inscricao, config)
        .then((a) => {
          setEnviado(true);
        })
        .catch((error) => {
          // console.log(error.response);
          const message = error.response.data.messages
            ? error.response.data.messages[0].message
            : error.response.data.message;
          setMessageError(message);
        });
    } else {
      await api
        .post('/enrollments', inscricao)
        .then((a) => {
          setEnviado(true);
        })
        .catch((error) => {
          //console.log(error.response);
          const message = error.response.data.messages
            ? error.response.data.messages[0].message
            : error.response.data.message;
          setMessageError(message);
          setEmProcesso(false);
        });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        fullWidth
        maxWidth="md"
        scroll={'body'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullScreen={window.innerWidth > 675 ? false : true}
      >
        <Container>
          {!enviado ? (
            <form onSubmit={enviar}>
              <DialogTitle id="scroll-dialog-title">Inscrição</DialogTitle>
              <DialogContent dividers={scroll === 'paper'}>
                <DialogContentText
                  id="scroll-dialog-description"
                  ref={descriptionElementRef}
                  tabIndex={-1}
                >
                  {/* <h5>Eu sou</h5> */}
                  {/* <div className="tipoDeInscrito">
                    <div className="tipoVisitante">
                      <input
                        type="radio"
                        id="visitante"
                        name="tipoinscricao"
                        value="visitante"
                        onChange={() =>
                          setDados({ ...dados, cadastro: 'visitante' })
                        }
                      />
                      <label
                        for="visitante"
                        className={
                          dados.cadastro == 'visitante' ? 'cadSelect' : ''
                        }
                      >
                        Visitante
                      </label>
                    </div>
                    <div className="tipoExpositor">
                      <input
                        type="radio"
                        id="expositor"
                        name="tipoinscricao"
                        value="expositor"
                        onChange={() =>
                          setDados({ ...dados, cadastro: 'expositor' })
                        }
                      />
                      <label
                        for="expositor"
                        className={
                          dados.cadastro == 'expositor' ? 'cadSelect' : ''
                        }
                      >
                        Expositor
                      </label>
                    </div>
                  </div> */}
                  <label>
                    <ArrowForwardIosIcon /> Informações Gerais
                  </label>
                  <div className="reparticao">
                    <select
                      required
                      style={{ flex: 1 }}
                      onChange={handleChange}
                      name="category"
                      value={dados.category}
                    >
                      <option default value="" disabled selected>
                        Categoria da inscrição
                      </option>
                      <option value="Normal">Normal</option>
                      <option value="VIP">VIP</option>
                    </select>
                  </div>

                  <div className="reparticao">
                    <input
                      type="text"
                      placeholder="Nome completo"
                      name="name"
                      required
                      onChange={handleChange}
                      value={dados.name}
                    />
                  </div>
                  <div className="reparticao">
                    <input
                      type="email"
                      placeholder="E-mail"
                      name="mail"
                      required
                      onChange={handleChange}
                      value={dados.mail}
                    />
                  </div>
                  <div className="reparticao">
                    {/* <input type="text" placeholder="CPF" required /> */}
                    <InputMask
                      mask="999.999.999-99"
                      placeholder="CPF"
                      name="cpf"
                      required
                      maskChar=" "
                      onChange={handleChange}
                      value={dados.cpf}
                    />
                    <InputMask
                      mask="(99) 99999-9999"
                      placeholder="Telefone/Whatsapp"
                      name="phone"
                      required
                      maskChar=" "
                      onChange={handleChange}
                      value={dados.phone}
                    />
                  </div>

                  <div className="reparticao">
                    <select
                      required
                      style={{ flex: 1 }}
                      onChange={handleChange}
                      name="state"
                      value={dados.state}
                    >
                      <option default value="" disabled selected>
                        Estado
                      </option>
                      <option value="AC">AC</option>
                      <option value="AL">AL</option>
                      <option value="AP">AP</option>
                      <option value="AM">AM</option>
                      <option value="BA">BA</option>
                      <option value="CE">CE</option>
                      <option value="DF">DF</option>
                      <option value="ES">ES</option>
                      <option value="GO">GO</option>
                      <option value="MA">MA</option>
                      <option value="MT">MT</option>
                      <option value="MS">MS</option>
                      <option value="MG">MG</option>
                      <option value="PA">PA</option>
                      <option value="PB">PB</option>
                      <option value="PR">PR</option>
                      <option value="PE">PE</option>
                      <option value="PI">PI</option>
                      <option value="RJ">RJ</option>
                      <option value="RN">RN</option>
                      <option value="RS">RS</option>
                      <option value="RO">RO</option>
                      <option value="RR">RR</option>
                      <option value="RC">SC</option>
                      <option value="SP">SP</option>
                      <option value="SE">SE</option>
                      <option value="TO">TO</option>
                    </select>
                    <input
                      type="text"
                      placeholder="Cidade"
                      required
                      style={{ flex: 3 }}
                      onChange={handleChange}
                      name="city"
                      value={dados.city}
                    />
                  </div>
                  <div className="reparticao">
                    <input
                      type="text"
                      placeholder="Bairro"
                      required
                      onChange={handleChange}
                      name="street"
                      value={dados.street}
                    />
                  </div>
                  <div className="reparticao">
                    <input
                      type="text"
                      placeholder="Endereço"
                      required
                      onChange={handleChange}
                      name="adress"
                      value={dados.adress}
                    />
                  </div>
                  <div className="reparticao">
                    <InputMask
                      style={{ flex: 1 }}
                      mask="999999999"
                      placeholder="Número"
                      name="number"
                      required
                      maskChar=" "
                      onChange={handleChange}
                      value={dados.number}
                    />
                    <input
                      type="text"
                      placeholder="Complemento"
                      style={{ flex: 3 }}
                      onChange={handleChange}
                      name="complement"
                      value={dados.complement}
                    />
                  </div>

                  <div className="reparticao ladoalado">
                    <select
                      style={{ flex: 1 }}
                      onChange={handleChange}
                      name="who_indicated"
                      value={dados.who_indicated}
                    >
                      <option default value="" disabled selected>
                        Como você nos encontrou ou onde/de quem ouviu falar
                        sobre nós?
                      </option>
                      {/* <option value=""> </option> */}
                      <option value="Site da ExpoISP">Site da Expo ISP</option>
                      <option value="E-mail">E-mail</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Instagram">Instagram</option>
                      <option value="Grupos de Whatsapp">
                        Grupos de Whatsapp
                      </option>
                      <option value="Indicação de amigos">
                        Indicação de amigos
                      </option>
                      <option value="Outro">Outro</option>
                    </select>
                    <input
                      type="text"
                      placeholder={
                        dados.who_indicated === 'Outro' ? 'Onde?' : '-'
                      }
                      style={{ flex: 4 }}
                      onChange={handleChange}
                      name="another_indication"
                      value={
                        dados.who_indicated === 'Outro'
                          ? dados.another_indication
                          : ''
                      }
                      disabled={dados.who_indicated === 'Outro' ? false : true}
                    />
                  </div>

                  <label>
                    <ArrowForwardIosIcon /> Informações para Credenciais
                  </label>
                  <div className="reparticao">
                    <select
                      required
                      style={{ flex: 1 }}
                      onChange={handleChange}
                      name="event_id"
                      value={dados.event_id}
                    >
                      <option defaultChecked value="" disabled selected>
                        Evento
                      </option>
                      {props.listaeventos.map((a, i) => {
                        let dateEvent = format(
                          new Date(a.end_date ? a.end_date : a.start_date),
                          'yyyy-MM-dd'
                        );
                        let currentDate = format(new Date(), 'yyyy-MM-dd');

                        let eventOccurred = compareAsc(
                          new Date(dateEvent),
                          new Date(currentDate)
                        );

                        return (
                          eventOccurred !== -1 && (
                            <option value={a.id} key={i}>
                              {a.start_date
                                ? format(new Date(a.start_date), 'dd/MM/yyyy') +
                                  ' - ' +
                                  a.city
                                : '(Sem Data) - ' + a.city}
                            </option>
                          )
                        );
                      })}
                    </select>
                  </div>
                  <div className="reparticao">
                    <input
                      type="text"
                      placeholder="Nome para credencial"
                      maxlength="15"
                      required
                      onChange={handleChange}
                      name="name_credential"
                      value={dados.name_credential}
                    />
                  </div>
                  <div className="reparticao">
                    <input
                      type="text"
                      placeholder="Empresa/Instituição"
                      maxlength="25"
                      required
                      onChange={handleChange}
                      name="empresa_credential"
                      value={dados.empresa_credential}
                    />
                  </div>
                  <div className="reparticao">
                    <input
                      type="text"
                      placeholder="Cargo/Ocupação"
                      maxlength="25"
                      required
                      onChange={handleChange}
                      name="ocupation_credential"
                      value={dados.ocupation_credential}
                    />
                  </div>
                  <div className="reparticao">
                    <select
                      required
                      style={{ flex: 1 }}
                      onChange={handleChange}
                      name="type_inscription"
                      value={dados.type_inscription}
                    >
                      <option defaultChecked value="" disabled selected>
                        Tipo de inscrição
                      </option>
                      <option value="profissional da área">
                        Profissional da área
                      </option>
                      <option value="estudante da área">
                        Estudante da área
                      </option>
                      <option value="empresa da área">Empresa da área</option>
                      <option value="Provedor de internet">
                        Provedor de internet
                      </option>
                      <option value="Operadora de link/transporte">
                        Operadora de link/transporte
                      </option>
                      <option value="fabricante/importador">
                        Fabricante/importador
                      </option>
                      <option value="Instalação/manutenção">
                        Instalação/manutenção
                      </option>
                      <option value="Integrador">Integrador</option>
                      <option value="Orgãos de governo/instituições de ensino">
                        Orgãos de governo/instituições de ensino
                      </option>
                      <option value="revendedor/distribuidor">
                        Revendedor/distribuidor
                      </option>
                      <option value="Outro">Outro</option>
                      <option value="Não aplicado">Não aplicado</option>
                    </select>
                    <input
                      type="text"
                      placeholder={
                        dados.type_inscription === 'Outro' ||
                        dados.type_inscription === 'profissional da área' ||
                        dados.type_inscription === 'estudante da área' ||
                        dados.type_inscription === 'empresa da área'
                          ? 'Qual tipo de inscrição/área de atuação?'
                          : '-'
                      }
                      required
                      style={{ flex: 4 }}
                      onChange={handleChange}
                      name="another_type_inscription"
                      value={
                        dados.type_inscription === 'Outro' ||
                        dados.type_inscription === 'profissional da área' ||
                        dados.type_inscription === 'estudante da área' ||
                        dados.type_inscription === 'empresa da área'
                          ? dados.another_type_inscription
                          : ''
                      }
                      disabled={
                        dados.type_inscription !== 'Outro' &&
                        dados.type_inscription !== 'profissional da área' &&
                        dados.type_inscription !== 'estudante da área' &&
                        dados.type_inscription !== 'empresa da área'
                          ? true
                          : false
                      }
                    />
                  </div>
                  {process.env.REACT_APP_TYPE_SITE === 'isp' && (
                    <div className="reparticao">
                      <select
                        required
                        style={{ flex: 1 }}
                        onChange={handleChange}
                        name="has_fiber_optic"
                        value={dados.has_fiber_optic}
                      >
                        <option defaultChecked value="" disabled selected>
                          Você possui rede de Fibra Ótica FTTX?
                        </option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                      </select>

                      <select
                        required
                        style={{ flex: 1 }}
                        onChange={handleChange}
                        name="has_asn"
                        value={dados.has_asn}
                      >
                        <option defaultChecked value="" disabled selected>
                          Tem ASN?
                        </option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                      </select>

                      <input
                        type="text"
                        placeholder="Possui serviços de IPTV, Telefonia, etc? Quais?"
                        required
                        style={{ flex: 4 }}
                        onChange={handleChange}
                        name="has_service_tv_iptv"
                        value={dados.has_service_tv_iptv}
                      />
                    </div>
                  )}
                  {process.env.REACT_APP_TYPE_SITE === 'isp' && (
                    <div className="reparticao">
                      <select
                        required
                        style={{ flex: 1 }}
                        onChange={handleChange}
                        name="company_size"
                        value={dados.company_size}
                      >
                        <option defaultChecked value="" disabled selected>
                          Porte da empresa
                        </option>
                        <option value="até 300 clientes">
                          Até 300 clientes
                        </option>
                        <option value="até 500 clientes">
                          Até 500 clientes
                        </option>
                        <option value="até 1000 clientes">
                          Até 1.000 clientes
                        </option>
                        <option value="até 3000 clientes">
                          Até 3.000 clientes
                        </option>
                        <option value="até 5000 clientes">
                          Até 5.000 clientes
                        </option>
                        <option value="até 10000 clientes">
                          Até 10.000 clientes
                        </option>
                        <option value="até 20000 clientes">
                          Até 20.000 clientes
                        </option>
                        <option value="até 50000 clientes">
                          Até 50.000 clientes
                        </option>
                        <option value="mais de 50000 clientes">
                          Mais de 50.000 clientes
                        </option>
                        <option value="não aplicado">
                          Não sou provedor ou operadora
                        </option>
                      </select>

                      <select
                        required
                        style={{ flex: 1 }}
                        onChange={handleChange}
                        name="contracted_link"
                        value={dados.contracted_link}
                      >
                        <option defaultChecked value="" disabled selected>
                          Quantidade de link contratado
                        </option>
                        <option value="até 300MB">Até 300MB</option>
                        <option value="até 500MB">Até 500MB</option>
                        <option value="até 1GB">Até 1GB</option>
                        <option value="até 3GB">Até 3GB</option>
                        <option value="até 5GB">Até 5GB</option>
                        <option value="até 10GB">Até 10GB</option>
                        <option value="até 20GB">Até 20GB</option>
                        <option value="mais de 20GB">Mais de 20 GB</option>
                        <option value="não aplicado">
                          Não sou provedor ou operadora
                        </option>
                      </select>
                    </div>
                  )}
                  <div className="reparticao ladoalado">
                    <input
                      type="checkbox"
                      id="termos"
                      name="termos"
                      style={{ flex: 1 }}
                      required
                      checked={dados.termos}
                      value={true}
                      onChange={() =>
                        setDados({ ...dados, termos: !dados.termos })
                      }
                    />
                    <label for="termos" style={{ flex: 20 }}>
                      Declaro e li e concordo com as &nbsp;
                      <a href="/termos" target="_blank">
                        políticas de privacidade
                      </a>
                    </label>
                  </div>
                  <div className="reparticao ladoalado">
                    <input
                      type="checkbox"
                      id="concentimento"
                      name="concentimento"
                      style={{ flex: 1 }}
                      required
                      checked={dados.consentimento}
                      value={true}
                      onChange={() =>
                        setDados({
                          ...dados,
                          consentimento: !dados.consentimento,
                        })
                      }
                    />
                    <label for="concentimento" style={{ flex: 20 }}>
                      Autorizo o uso da minha imagem (foto e vídeo) para ser
                      utilizado nos canais de comunicação e na divulgação dos
                      eventos da Start Produções e Eventos, tais como EXPOSOLAR,
                      EXPOISP Brasil e EXPOISP Expedição.
                    </label>
                  </div>
                </DialogContentText>
              </DialogContent>
              {messageError && (
                <Alert
                  style={{ margin: '1rem 2rem' }}
                  variant="outlined"
                  severity="error"
                >
                  {messageError.replace('!', '')}. Corrija e tente novamente.
                </Alert>
              )}

              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="primary"
                  className="cancelar"
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  // color="primary"
                  className="confirmar"
                  disabled={emProcesso}
                >
                  {props.edicao ? 'Atualizar' : 'Inscrever-se'}
                </Button>
              </DialogActions>
            </form>
          ) : (
            <div className="realizadoComSucesso">
              <lottie-player
                // ref={this.myRef} // 2. set the reference for the player
                id="firstLottie"
                // controls
                autoplay
                speed="0.5"
                mode="normal"
                src="https://assets8.lottiefiles.com/packages/lf20_PaEutX.json"
                style={{ width: '150px' }}
              ></lottie-player>
              <h3>
                {props.edicao ? 'Edição' : 'Inscrição'} realizada com sucesso!
              </h3>
              <label>Siga as redes sociais</label>
              <div className="divisaoTitu"></div>
              <div className="icones">
                <a
                  href="https://www.facebook.com/expoispbrasil/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.instagram.com/expoispbrasil/?hl=pt-br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon />
                </a>
                <a
                  href="https://www.facebook.com/expoispbrasil/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedInIcon />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCihzh1ZNOezHh2rpU3f08Ow/featured?view_as=subscriber"
                  target="_blank"
                  rel="noreferrer"
                >
                  <YouTubeIcon />
                </a>
                {/* <YouTubeIcon /> */}
              </div>
              <button onClick={handleClose}>Voltar</button>
            </div>
          )}
        </Container>
      </Dialog>
    </div>
  );
}
