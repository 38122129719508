import React, { useCallback, useEffect, useState } from 'react';

import { Container } from './styles';

// icones
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';

// dropdown
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

// api
import api from '../../../services/api';

import Dialogodeconfirmacao from '../../../components/Dialogodeconfirmacao';
import Novopalestrante from '../../../components/Novopalestrante';
import Backdrop from '../../../components/Backdrop';

function Configuracoes(props) {
  const [backdrop, setBackdrop] = useState(false);
  const [confirmacao, setConfirmacao] = useState([false, '']);

  const [filtro, setFiltro] = React.useState({
    tipo: '',
    descricao: '',
  });

  const [dialogoApoiador, setDialogoApoiador] = React.useState({
    status: false,
    editar: false,
    dados: '',
  });

  // chamada api
  const [speakers, setSpeakers] = useState([]);

  const getSpeakers = useCallback(async () => {
    setBackdrop(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    await api
      .get(`/speakers`, config)
      .then((response) => {
        setSpeakers(response.data);
        setBackdrop(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, [props.user.token, setBackdrop]);

  const removeSpeakers = async (id) => {
    setBackdrop(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    await api
      .delete(`/speakers/${id}`, config)
      .then((response) => {
        setBackdrop(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };
  //fim

  const filtroAplicado = (e) => {
    if (filtro.tipo && filtro.descricao) {
      if (filtro.tipo === 'evento') {
        if (e.event_id === filtro.descricao) return e;
      }
      if (filtro.tipo === 'nome') {
        if (e.name.toLowerCase().indexOf(filtro.descricao.toLowerCase()) > -1)
          return e;
      }
    } else {
      return true;
    }
  };

  let lista2 = speakers.filter(filtroAplicado);
  lista2.sort(function (a, b) {
    return a.name.localeCompare(b.name);
  });

  useEffect(() => {
    getSpeakers();
  }, [getSpeakers]);

  return (
    <Container>
      <header>
        <h1>Pessoas</h1>
        <p>
          Adicione ou exclua pessoas que poderão ser vinculados a palestras e
          panéis nos eventos.
        </p>
      </header>
      <div className="funcoesdefiltro">
        <div className="filtros">
          <FormControl
            variant=""
            margin="dense"
            style={{
              margin: '0rem 1rem',
              marginTop: '-0.0rem',
              width: '200px',
              textAlign: 'left',
            }}
          >
            <InputLabel id="id-selctor-zone">{'Filtrar'}</InputLabel>
            <Select
              labelId="id-selctor-zone"
              id="id-selctor-zone-select"
              value={filtro.tipo}
              onChange={(e) =>
                setFiltro({ ...filtro, tipo: e.target.value, descricao: '' })
              }
              label="Tipo"
            >
              <MenuItem value="" default>
                <em>Nenhum</em>
              </MenuItem>
              <MenuItem value={'nome'}>Nome</MenuItem>
            </Select>
          </FormControl>

          <TextField
            onChange={(e) => {
              setFiltro({ ...filtro, descricao: e.target.value });
            }}
            value={filtro.descricao}
            id="outlined-basic"
            label={filtro.tipo === 'nome' ? 'Buscar' : ' - '}
            disabled={filtro.tipo ? false : true}
            // variant="outlined"
            margin="dense"
            // className={classes.inputFiltro}
            style={{ margin: '0rem 0rem', marginLeft: '0rem', width: '250px' }}
            // onKeyUp={()=>setPage(0)}
          />
        </div>
        <div className="MenuDireita">
          <button
            className="cadastroManual"
            onClick={() =>
              setDialogoApoiador({ status: true, editar: false, dados: '' })
            }
          >
            {' '}
            + Nova Pessoa
          </button>
        </div>
      </div>

      <div className="cards-area">
        {lista2.length > 0 ? (
          lista2.map((speaker, i) => (
            <div className="card" key={speaker.id}>
              <img src={speaker.image_url} alt="" />

              <h3>{speaker.name}</h3>
              <p>{speaker.formation}</p>

              <div className="buttons">
                <button
                  className="delete"
                  onClick={(e) => {
                    setConfirmacao([true, speaker.id]);
                  }}
                >
                  <DeleteIcon style={{ color: '#f44336' }} />
                </button>

                {/* <button 
                  className="view"
                  onClick={async () => {}}
                >
                  <VisibilityIcon style={{ color: '#555' }}/>
                </button> */}

                <button
                  className="edit"
                  onClick={async (e) => {
                    setDialogoApoiador({
                      status: true,
                      editar: true,
                      dados: speaker,
                    });
                  }}
                >
                  <CreateIcon style={{ color: '#555' }} />
                </button>
              </div>
            </div>
          ))
        ) : (
          <h2>Nenhum palestrante encontrado !</h2>
        )}
      </div>
      {backdrop && <Backdrop />}
      {confirmacao[0] ? (
        <Dialogodeconfirmacao
          tamanho="xs"
          message="Tem certeza de que deseja apagar este palestrante?"
          confirmar={async () => {
            await removeSpeakers(confirmacao[1]);
            await getSpeakers();
            setConfirmacao([false, '']);
          }}
          negar={() => setConfirmacao([false, ''])}
        />
      ) : (
        ''
      )}
      {dialogoApoiador.status ? (
        <Novopalestrante
          user={props.user}
          editar={dialogoApoiador.editar}
          dados={dialogoApoiador.dados}
          id={dialogoApoiador.id}
          tamanho="xs"
          negar={() =>
            setDialogoApoiador({ status: false, editar: false, dados: '' })
          }
          atualizar={async () => {
            setDialogoApoiador({ status: false, editar: false, dados: '' });
            await getSpeakers();
          }}
        />
      ) : (
        ''
      )}
    </Container>
  );
}

export default Configuracoes;
