import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';

import { DropzoneArea } from 'material-ui-dropzone';
import { Container } from './styles';

import api from '../../services/api';
// tema
import { useTheme } from 'styled-components';
import {
  GET_LIST_EXHIBITORS,
  GET_LIST_SPONSORS,
} from '../../services/endpoints';
import { Autocomplete } from '@material-ui/lab';
import imageUrl from '../../utils/imageUrl';

export default function MaxWidthDialog(props) {
  const theme = useTheme();
  const [open] = React.useState(true);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState(props.tamanho);
  const [messageError, setMessageError] = React.useState('');
  const [listsponsors, setListsponsors] = useState([]);
  const [listexhibitors, setListexhibitors] = useState([]);

  const [dados, setDados] = React.useState({
    name: props.editar ? props.dados.name : '',
    link: props.editar ? props.dados.link : '',
    image: props.editar ? props.dados.image_url : '',
    inHome: props.editar ? props.dados.inHome : false,
  });

  const [files, setFiles] = React.useState([]);
  const handleChange = (e) => {
    setFiles(e);
  };

  const enviarForm = async (type) => {
    let supporterRequest = {
      name: dados.name,
      link: dados.link,
      inHome: dados.inHome,
      inHomeExpedition: dados.inHome,
    };

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'multipart/form-data',
      },
    };

    const data = new FormData();

    data.append('name', supporterRequest.name);
    data.append('link', supporterRequest.link);
    data.append('image', files[0] ? files[0] : null);
    data.append('inHome', supporterRequest.inHome);
    data.append('inHomeExpedition', supporterRequest.inHomeExpedition);

    props.editar
      ? await api
          .put(`/supporters/${props.dados.id}`, data, config)
          .then((a) => {
            setMessageError('');
            props.atualizar();
          })
          .catch((error) => {
            console.log(error.response);
            const message = error.response.data.messages
              ? error.response.data.messages[0].message
              : error.response.data.message;
            setMessageError(message);
          })
      : await api
          .post('/supporters', data, config)
          .then((a) => {
            setMessageError('');
            props.atualizar();
          })
          .catch((error) => {
            console.log(error.response);
            const message = error.response.data.messages
              ? error.response.data.messages[0].message
              : error.response.data.message;
            setMessageError(message);
          });
  };

  async function createImageBlob(urlImage) {
    let response = await fetch(urlImage);
    let data = await response.blob();
    let metadata = {
      type: 'image/jpeg',
    };
    let file = new File([data], 'test.jpg', metadata);
    setFiles([file]);
    // ... do something with the file or return it
  }

  function orderData(a, b) {
    return a.name.localeCompare(b.name);
  }

  useEffect(() => {
    GET_LIST_SPONSORS(setListsponsors, props.user.token);
    GET_LIST_EXHIBITORS(setListexhibitors, props.user.token);
  }, []);

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        scroll={'body'}
        //onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <Container>
          <form>
            <DialogTitle id="max-width-dialog-title">
              {props.editar ? 'Editar Apoiador' : '+ Novo Apoiador'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div className="subtitulo">
                  Preencha corretamente os campos abaixo para{' '}
                  {props.editar ? 'editar' : 'adicionar'} o apoiador.
                </div>
                <div className="formulario">
                  <p style={{ fontSize: '16px' }}>{props.message}</p>
                  <TextField
                    required
                    id="standard-basic"
                    className="campo"
                    label="Nome"
                    onChange={(e) =>
                      setDados({ ...dados, name: e.target.value })
                    }
                    value={dados.name}
                  />
                  <TextField
                    id="standard-basic"
                    className="campo"
                    label="Link"
                    onChange={(e) =>
                      setDados({ ...dados, link: e.target.value })
                    }
                    value={dados.link}
                  />
                  <FormControl
                    required
                    variant=""
                    margin="dense"
                    className="apresentarHome"
                  >
                    <InputLabel required id="id-selctor-zone">
                      {'Apoiador deve aparecer na Home?'}
                    </InputLabel>
                    <Select
                      labelId="id-selctor-zone"
                      id="id-selctor-zone-select"
                      value={dados.inHome}
                      onChange={(e) =>
                        setDados({ ...dados, inHome: e.target.value })
                      }
                      label="Apoiador deve aparecer na Home?"
                      margin="dense"
                      required
                    >
                      {/* <MenuItem value="" default>
                          <em>Nenhum</em>
                      </MenuItem>                   */}
                      <MenuItem value={true}>Sim</MenuItem>
                      <MenuItem value={false}>Não</MenuItem>
                    </Select>
                  </FormControl>
                  <br />
                  <DropzoneArea
                    onChange={handleChange}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                    filesLimit={'1'}
                    dropzoneText="Arraste a imagem para este campo ou clique aqui para buscar"
                    maxFileSize="6000000"
                    getFileAddedMessage={(fileName) =>
                      `Arquivo ${fileName} adicionado com sucesso.`
                    }
                    getFileRemovedMessage={(fileName) =>
                      `Arquivo ${fileName} removido.`
                    }
                    getFileLimitExceedMessage={(filesLimit) =>
                      `Número máximo de ${filesLimit} arquivos excedido.`
                    }
                    getDropRejectMessage={() =>
                      `Tipo de arquivo não permitido.`
                    }
                  />

                  {props.editar || dados.image ? (
                    <div className="imagematual">
                      <h3>Imagem atual</h3>
                      <img
                        alt="imagem_atual"
                        src={dados.image}
                        style={{ width: '100%' }}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="formulario">
                  <p style={{ fontSize: '16px' }}>
                    <details>
                      <summary>Adicionar de Patrocinador/Expositor</summary>
                      <div className="div__autocomplete">
                        <Autocomplete
                          id="combo-box-demo"
                          className="autoComplete"
                          options={[...listsponsors, ...listexhibitors].sort(
                            orderData
                          )}
                          // value={['um','dois','três']}
                          getOptionLabel={(event) =>
                            event.name + ' - ' + event.type
                          }
                          style={{ width: '100%' }}
                          noOptionsText={
                            'Patrocinador/Expositor não encontrado'
                          }
                          onChange={(event, newValue) => {
                            setDados({
                              ...dados,
                              name: newValue ? newValue?.name : '',
                              link: newValue ? newValue?.link : '',
                              image: newValue ? imageUrl(newValue?.image) : '',
                            });
                            newValue
                              ? createImageBlob(imageUrl(newValue?.image))
                              : setFiles([]);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Selecionar Patrocinador/Expositor"
                              variant="standard"
                            />
                          )}
                          clearText="Limpar"
                        />
                      </div>
                    </details>
                  </p>
                </div>
              </DialogContentText>
            </DialogContent>

            {messageError && (
              <Alert
                style={{ margin: '1rem 2rem' }}
                variant="outlined"
                severity="error"
              >
                {messageError.replace('!', '')}. Corrija e tente novamente.
              </Alert>
            )}

            <DialogActions>
              <Button
                onClick={() => props.negar()}
                color=""
                variant="contained"
              >
                Cancelar
              </Button>
              <Button
                onClick={() => enviarForm()}
                style={{
                  backgroundColor: theme.colors.primaryDark,
                  color: '#fff',
                }}
                variant="contained"
              >
                {props.editar ? 'Editar' : 'Adicionar'}
              </Button>
            </DialogActions>
          </form>
        </Container>
      </Dialog>
    </React.Fragment>
  );
}
