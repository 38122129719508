import {
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react';

import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

// api
import api from '../services/api';

export const EventsContext = createContext({});

export function EventsContextProvider({ children, user }) {
  const [events, setEvents] = useState([]);

  const getData = useCallback(async () => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      },
    };

    await api
      .get(`/events/vera`, config)
      .then((response) => {
        let events = response.data;

        let eventsFormatted = [];
        events.map((event) => {
          let date_event = event.start_date
            ? format(new Date(event.start_date), 'dd/MM/yyyy', { locale: ptBR })
            : 'SEM DATA';
          let date_event_long = event.start_date
            ? format(new Date(event.start_date), "dd ' de ' MMMM ' de ' yyyy", {
                locale: ptBR,
              })
            : 'SEM DATA';
          let localization = `${event.city} - ${event.state}`;

          let newEvent = {
            id: event.id,
            is_national_event: event.is_national_event,
            type: event.is_national_event ? 'Nacional' : 'Expedição',
            state: event.state,
            city: event.city,
            localization,
            address: event.address,
            is_published: event.is_published,
            start_date: event.start_date,
            start_date_formatted: date_event,
            date_event_long,
            option: `${date_event} ${localization} ${
              event.is_national_event ? 'Nacional' : 'Expedição'
            }`,
            option_long: `${date_event_long} - ${localization}`,
            created_at: event.created_at,
          };

          return eventsFormatted.push(newEvent);
        });
        setEvents(eventsFormatted);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user.token]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <EventsContext.Provider
      value={{
        events,
        getData,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
}

export function useEvents() {
  return useContext(EventsContext);
}
