import React, { useState, useCallback } from 'react';

import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import { DropzoneArea } from 'material-ui-dropzone';
import { Container } from './styles';
import api from '../../services/api';
import { TextField } from '@material-ui/core';

// icons
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LinkIcon from '@material-ui/icons/Link';

// libs
import { useParams } from 'react-router-dom';
import Dialogodeconfirmacao from '../Dialogodeconfirmacao';

import { toast } from 'react-toastify';

function DetalhesFotos(props) {
  console.log(props.event);
  let { id } = useParams();
  const [update, setUpdate] = useState(false);

  const [dados, setDados] = useState([]);
  // const [setDadosEditar] = useState([]);

  const [linkAlbum, setLinkAlbum] = useState(
    props.event ? props.event.link_album : 'oi'
  );

  const [active, setActive] = useState(false);
  const { openLightbox } = useLightbox();

  const listPhotos = useCallback(() => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
      },
    };

    api
      .get(`/photos/${id}`, config)
      .then((response) => {
        let photos = response.data;
        setDados(photos);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [props.user.token, id]);

  const [confirmDelete, setConfirmDelete] = useState([false, '']);
  const deletePhoto = (id) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
      },
    };

    api
      .delete(`photos/${id}`, config)
      .then((a) => {
        setConfirmDelete([false, '']);
        listPhotos();
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  const [files, setFiles] = React.useState([]);
  const handleChange = (e) => {
    setFiles(e);
  };

  const cancelarAtualizazao = () => {
    setUpdate(false);
    // setDadosEditar([]);
    listPhotos();
  };

  const atualizarPhotos = async (e) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    files.map(async (file) => {
      let data = new FormData();
      let idTemp = +id;
      data.append('image', file ? file : null);
      data.append('event_id', idTemp);
      await api
        .post(`/photos/`, data, config)
        .then((a) => {})
        .catch((error) => {
          // console.log('oi');
          // console.log('erro na requisição: ', error);
          // toast.error(error.response?.message)
        });
    });

    await api
      .put(
        `/events/${props.event.id}/link_album`,
        { link_album: linkAlbum },
        config
      )
      .then((response) => {
        props.atualizar();
        setLinkAlbum('');
        setUpdate(!false);
        setFiles([]);
        listPhotos();
      })
      .catch((error) => {
        error.response.data.messages.map((erro) => toast.error(erro.message));
      });
  };

  React.useEffect(() => {
    if (dados.length === 0) listPhotos();
  }, [dados.length, listPhotos]);

  return (
    <Container update={update} active={active}>
      <div className="header">
        <div
          className="left"
          onClick={() => (update ? '' : setActive(!active))}
        >
          <span className="icon">&#10148;</span>
          <h2 className="title">Imagens</h2>
        </div>

        <div className="button">
          {update ? (
            <>
              <button className="salvarinfo" onClick={atualizarPhotos}>
                Salvar informações
              </button>
              <button className="cancel" onClick={() => cancelarAtualizazao()}>
                Cancelar
              </button>
            </>
          ) : (
            <button
              className="cancel"
              onClick={() => {
                // setDadosEditar(dados);
                setUpdate(true);
                setActive(true);
              }}
            >
              Editar
            </button>
          )}
        </div>
      </div>
      <div className="content">
        <div className="a">
          <SRLWrapper>
            <div className="div__campo_de_texto">
              {update ? (
                <TextField
                  placeholder={'https://www...'}
                  fullWidth
                  label="Link para o álbum de imagens"
                  value={linkAlbum}
                  onChange={(e) => setLinkAlbum(e.target.value)}
                />
              ) : (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <b>Link:</b>{' '}
                  {props.event.link_album ? (
                    <a
                      href={props.event.link_album}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LinkIcon /> {props.event.link_album}
                    </a>
                  ) : (
                    <p>Não possui link</p>
                  )}
                </div>
              )}
            </div>
            <div className="container">
              {update && dados.length < 3 && (
                <div className="dropzone">
                  <DropzoneArea
                    onChange={handleChange}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                    filesLimit={dados.length <= 3 ? 3 - dados.length : 0}
                    dropzoneText="Arraste a imagem para este campo ou clique aqui para buscar"
                    maxFileSize="6000000"
                    getFileAddedMessage={(fileName) =>
                      `Arquivo ${fileName} adicionado com sucesso.`
                    }
                    getFileRemovedMessage={(fileName) =>
                      `Arquivo ${fileName} removido.`
                    }
                    getFileLimitExceedMessage={(filesLimit) =>
                      `Número máximo de ${filesLimit} arquivos excedido.`
                    }
                    getDropRejectMessage={(maxFileSize) => {
                      return maxFileSize.size > 6 * 1024 * 1024
                        ? `Imagem deve ser menor que 6Mb.`
                        : `Tipo de arquivo não permitido.`;
                    }}
                  />
                </div>
              )}
              {dados.map((image, index) => (
                <div key={image.id} className="image-card">
                  {update && (
                    <div className="icones">
                      <div
                        className="icone-vision"
                        onClick={() => openLightbox(index)}
                      >
                        <VisibilityIcon />
                      </div>
                      <div
                        className="icone-del"
                        onClick={() => {
                          setConfirmDelete([true, image.id]);
                        }}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  )}
                  <a href={image.image_url}>
                    <div className="icone-image">
                      <img
                        className="image"
                        src={image.image_url}
                        alt={image.image}
                      />
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </SRLWrapper>
        </div>
      </div>

      {confirmDelete[0] ? (
        <Dialogodeconfirmacao
          tamanho="xs"
          message="Tem certeza de que deseja apagar esta foto?"
          confirmar={() => deletePhoto(confirmDelete[1])}
          negar={() => setConfirmDelete([false, ''])}
        />
      ) : (
        ''
      )}
    </Container>
  );
}

export default DetalhesFotos;
