import styled from 'styled-components';

export const EventCard = styled.div`
  background: url(${(props) => props.imgBackground}) no-repeat;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  width: 20rem;
  height: 20rem;
  border-radius: 1rem;
  box-shadow: 0rem 0rem 0.5rem rgba(0, 0, 0, 0.5);

  margin: 1rem 1.5rem;

  .gradient {
    display: flex;
    flex: 1;
    flex-direction: column;
    ${(props) =>
      (!props.isPublished &&
        'background: linear-gradient(rgba(153, 153, 153, 1), rgba(153, 153, 153, 0.8))') ||
      (props.isNational
        ? `background: linear-gradient(304.79deg, rgba(${props.theme.colors.primaryLightRgb}, 0.71) 13.05%, rgba(${props.theme.colors.secondaryRgb}, 0.71) 87.46%)`
        : `background: linear-gradient(to right bottom, ${props.theme.colors.secondary}, rgba(${props.theme.colors.primaryLightRgb}, 0.5))`)};
    height: 80%;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 1rem 1rem 0 0;
    position: relative;
    padding-top: 3rem;

    .state {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 1rem;
      display: flex;
      width: 15rem;
      height: 5rem;

      justify-content: center;
      align-items: center;

      margin-bottom: 1rem;

      h1 {
        width: 12rem;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 600;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .id_corner {
      position: absolute;
      min-width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0px;
      top: 0px;
      background-color: ${(props) =>
        (!props.isPublished && '#333') ||
        (props.isNational ? 'var(--white)' : props.theme.colors.secondary)};

      color: ${(props) =>
        (!props.isPublished && 'var(--white)') ||
        (props.isNational ? props.theme.colors.primary : 'var(--white)')};
      font-size: 16px;
      font-weight: 600;
      padding: 1rem;
      border-radius: 1rem 0px 60% 0px;
      box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
    }

    .type_event_corner {
      position: absolute;
      min-width: 50px;
      display: block;
      justify-content: center;
      align-items: center;
      right: 0px;
      top: 0px;
      background-color: ${(props) =>
        (!props.isPublished && '#333') ||
        (props.isNational ? 'var(--white)' : props.theme.colors.secondary)};
      color: ${(props) =>
        (!props.isPublished && 'var(--white)') ||
        (props.isNational ? props.theme.colors.primary : 'var(--white)')};
      font-style: italic;
      font-weight: 600;
      font-size: ${(props) => (props.isPublished ? '16px' : '12px')};
      padding: 0.5rem 0.5rem 0.5rem 1rem;
      border-radius: 0px 1rem 0px 2rem;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    }
  }

  .options {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 20%;

    .date {
      display: flex;
      flex-direction: column;
      background: ${(props) =>
        (!props.isPublished && '#4d4d4d') ||
        (props.isNational
          ? props.theme.colors.secondary
          : props.theme.colors.secondary)};

      align-items: center;
      justify-content: center;
      width: 5rem;
      border-radius: 0 0 0 1rem;

      span {
        color: #fff;
        font-weight: 600;
        font-size: 1.2rem;
        text-align: center;
      }

      h1 {
        color: #fff;
        font-size: 1.5rem;
      }
    }

    .buttons {
      display: flex;
      flex: 1;
      width: 60%;

      //
      /* padding: 0.2rem; */
      flex-direction: row;
      background: ${(props) =>
        (!props.isPublished && '#4d4d4d') ||
        (props.isNational
          ? props.theme.colors.secondary
          : props.theme.colors.secondary)};
      justify-content: flex-end;
      align-items: center;
      border-radius: 0 0 1rem 0;

      a {
        margin: 0;
        padding: 0;
        z-index: 5;
        overflow: hidden;
        margin-top: -0.25rem;

        &:first-child {
          border-radius: 1rem 0 0 0;
        }
        &:last-child {
          border-radius: 0 0 1rem 0;
        }
      }

      button {
        //padding: 1rem 1rem;
        display: flex;
        height: 4.5rem;
        height: 4.5rem;
        width: 3.5rem;
        background: none;
        border: 0;
        border-radius: 0;
        /* margin-right: 1rem; */
        background: var(--white);
        justify-content: center;
        align-items: center;

        color: #777;

        svg {
          justify-content: center;
          align-items: center;
        }

        :hover {
          background: #a7a7a7;

          &.delete {
            background: ${(props) => props.theme.colors.delete} !important;
          }

          svg {
            color: #fff !important;
          }
        }
      }
    }
  }
`;
