import { useEffect } from 'react';
import { toast } from 'react-toastify';
// coockies
import Cookies from 'universal-cookie';
import api from './api';

const cookies = new Cookies();

let config = {
  headers: {
    Authorization: 'Bearer ' + cookies.get('EBSSAadSisTem')?.token,
    'Content-Type': 'application/json',
  },
};

function refreshToken(token) {
  return {
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };
}

// auditoriums
export async function POST_NEW_AUDITORIUM(
  dataValues,
  setSucceess,
  setBackdrop,
  token,
) {
  const auxConfig = refreshToken(token);
  setBackdrop(true);
  const data = new FormData();
  data.append('event_id', dataValues.event_id);
  data.append('name', dataValues.name);
  dataValues.description && data.append('description', dataValues.description);
  dataValues.image && data.append('image', dataValues.image);

  await api
    .post(`auditoriums`, data, auxConfig || config)
    .then((res) => {
      toast.success('Auditório adicionado com sucesso.');

      setSucceess();
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      console.log(error.response.data);
    })
    .finally(() => setBackdrop(false));
}

export async function GET_LIST_AUDITORIUMS(eventId, setValue, token) {
  const auxConfig = refreshToken(token);
  await api
    .get(`auditoriums?event_id=${eventId}`, auxConfig || config)
    .then((res) => {
      setValue(res?.data);
    })
    .catch((error) => {
      console.log(error?.response?.data);
      // console.log('[recebendo erro]', error.response.data);
    });
}

export async function EDIT_AUDITORIUM(
  dataValues,
  setSuccess,
  setBackdrop,
  token,
) {
  const auxConfig = refreshToken(token);
  setBackdrop(true);
  const data = new FormData();
  data.append('event_id', dataValues.event_id);
  dataValues.name && data.append('name', dataValues.name);
  dataValues.description && data.append('description', dataValues.description);
  dataValues.image && data.append('image', dataValues.image);

  await api
    .put(`auditoriums/${dataValues.id}`, data, auxConfig || config)
    .then((res) => {
      toast.success('Auditório editado com sucesso.');

      setSuccess();
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      console.log(error.response.data);
    })
    .finally(() => setBackdrop(false));
}

export async function DELETE_AUDITORIUM(
  dataValues,
  setSuccess,
  setBackdrop,
  token,
) {
  const auxConfig = refreshToken(token);
  setBackdrop(true);

  await api
    .delete(`auditoriums/${dataValues?.id}`, auxConfig || config)
    .then((res) => {
      toast.success('Auditório deletado com sucesso.');
      setSuccess();
      console.log('[depois do sucesso, chegando]');
    })
    .catch((error) => {
      // toast.error(error.response.data.message);
      // console.log('[ERROR]=> File delete error');
    })
    .finally(() => setBackdrop(false));
}

// schedules
export async function GET_LIST_GENERAL_SCHEDULES(
  eventId,
  setValue,
  setBackdrop,
  token,
) {
  const auxConfig = refreshToken(token);
  setBackdrop(true);
  await api
    .get(
      `shedules?event_id=${eventId}&auditorium_id=false`,
      auxConfig || config,
    )
    .then((res) => setValue(res.data))
    .catch((error) => console.log(error.response.data))
    .finally(() => setBackdrop(false));
}

export async function SET_NEW_SCHEDULE(
  dataShedule,
  setSuccess,
  setBackdrop,
  token,
) {
  const auxConfig = refreshToken(token);
  setBackdrop(true);

  await api
    .post(`/shedules`, dataShedule, auxConfig || config)
    .then((response) => {
      // atualizar shedules
      setSuccess();
      toast.success('Programação adicionada com sucesso.');
    })
    .catch((error) => {
      console.log(error.response.data.message);
    })
    .finally(() => setBackdrop(false));
}

export async function EDIT_SCHEDULE(
  sheduleId,
  dataShedule,
  setSuccess,
  setBackdrop,
  token,
) {
  const auxConfig = refreshToken(token);
  setBackdrop(true);

  await api
    .put(`/shedules/${sheduleId}`, dataShedule, auxConfig || config)
    .then((response) => {
      // atualizar shedules
      setSuccess();
      toast.success('Programação editada com sucesso.');
    })
    .catch((error) => {
      console.log(error.response.data.message);
    })
    .finally(() => setBackdrop(false));
}

// banners

export async function GET_LIST_BANNERS(eventId, setValue, setBackdrop, token) {
  const auxConfig = refreshToken(token);
  setBackdrop(true);
  await api
    .get(`banners?event_id=${eventId}`, auxConfig || config)
    .then((res) => setValue(res.data))
    .catch((error) => console.log(error.response.data))
    .finally(() => setBackdrop(false));
}

export async function POST_NEW_BANNER(
  dataValues,
  setSucceess,
  setBackdrop,
  token,
) {
  const auxConfig = refreshToken(token);
  setBackdrop(true);
  const data = new FormData();
  data.append('event_id', dataValues.event_id);
  data.append('image_web', dataValues.image_web);
  dataValues.link && data.append('link', dataValues.link);
  dataValues.show_mobile_img &&
    dataValues.image_mobile &&
    data.append('image_mobile', dataValues.image_mobile);

  await api
    .post(`banners`, data, auxConfig || config)
    .then((res) => {
      toast.success('Banner adicionado com sucesso.');

      setSucceess();
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      console.log(error.response.data);
    })
    .finally(() => setBackdrop(false));
}

export async function EDIT_BANNER(dataValues, setSucceess, setBackdrop, token) {
  const auxConfig = refreshToken(token);
  setBackdrop(true);
  const data = new FormData();
  dataValues.image_web && data.append('image_web', dataValues.image_web);
  dataValues.link && data.append('link', dataValues.link);
  dataValues.show_mobile_img &&
    dataValues.image_mobile &&
    data.append('image_mobile', dataValues.image_mobile);
  dataValues.removeMobile && data.append('remove_mobile', true);

  await api
    .put(`banners/${dataValues.id}`, data, auxConfig || config)
    .then((res) => {
      toast.success('Banner editado com sucesso.');

      setSucceess();
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      console.log(error.response.data);
    })
    .finally(() => setBackdrop(false));
}

export async function DELETE_BANNER(
  dataValues,
  setSucceess,
  setBackdrop,
  token,
) {
  const auxConfig = refreshToken(token);
  setBackdrop(true);

  await api
    .delete(`banners/${dataValues.id}`, auxConfig || config)
    .then((res) => {
      toast.success('Banner deletado com sucesso.');
      setSucceess();
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      console.log(error.response.data);
    })
    .finally(() => setBackdrop(false));
}

// supporters
export async function GET_LIST_SUPPORTERS(setValue, token) {
  const auxConfig = refreshToken(token);
  // setBackdrop(true);

  await api
    .get(`/supporters`, auxConfig || config)
    .then((response) => {
      response.data.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      let auxArray = response.data.map((dataValue) => ({
        ...dataValue,
        type: 'APOIADOR',
      }));

      setValue(auxArray);
    })
    .catch((error) => {
      console.log(error.response.data.message);
    });
  // .finally(() => setBackdrop(false));
}
// exhibitors
export async function GET_LIST_EXHIBITORS(setValue, token) {
  const auxConfig = refreshToken(token);
  // setBackdrop(true);

  await api
    .get(`/exhibitors`, auxConfig || config)
    .then((response) => {
      response.data.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      let auxArray = response.data.map((dataValue) => ({
        ...dataValue,
        type: 'EXPOSITOR',
      }));
      setValue(auxArray);
    })
    .catch((error) => {
      console.log(error.response.data.message);
    });
  // .finally(() => setBackdrop(false));
}
// sponsors
export async function GET_LIST_SPONSORS(setValue, token) {
  const auxConfig = refreshToken(token);
  // setBackdrop(true);

  await api
    .get(`/sponsors`, auxConfig || config)
    .then((response) => {
      response.data.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      let auxArray = response.data.map((dataValue) => ({
        ...dataValue,
        type: 'PATROCINADOR',
      }));
      setValue(auxArray);
    })
    .catch((error) => {
      console.log(error.response.data.message);
    });
  // .finally(() => setBackdrop(false));
}
