import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';

import api from '../../services/api';

import { DropzoneArea } from 'material-ui-dropzone';
import { Container } from './styles';

import { useHistory } from 'react-router';

import 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { uf } from '../../utils/defaultData';
import { useEvents } from '../../context/eventsContext';

// temas
import { useTheme } from 'styled-components';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function Modalcriarevento(props) {
  // tema
  const theme = useTheme();
  const history = useHistory();
  const { getData } = useEvents();

  // descrições padrões
  const defaultDescription =
    '<div><h5>Visão geral do evento</h5><p>Somos o encontro para quem pensa no futuro, e investe no hoje! Reunimos o melhor do setor de provedores de internet e levamos até você.</p><p> Queremos destacar todo o processo de produção desse meio e apresentar um processo integrado e totalmente atento as novas realidades que vivemos, por meio de parceiros e palestrantes que irão apresentar as inovações e produtos do mercado em um evento expedição que percorrerá o Brasil de Norte a Sul.</p><p>A EXPO Brasil ISP é uma feira voltada para aqueles que procuram novas oportunidades, fornecedores, parcerias, conhecimento e network.</p></div>';

  const defaultDescriptionSolar =
    '<div><h5>Visão geral do evento</h5><p>Somos o encontro para quem pensa no futuro, e investe no hoje! Reunimos o melhor do setor de energia solar fotovoltaica e levamos até você.</p><p>Acreditamos que o futuro já chegou e pensar em energia solar é uma peça fundamental. Queremos destacar todo o processo de produção desse meio e apresentar um processo integrado e totalmente atento as novas realidades que vivemos, por meio de parceiros e palestrantes que irão apresentar as inovações e produtos do mercado em um evento expedição que percorrerá o Brasil de Norte a Sul.</p><p>A EXPO Brasil Solar é uma feira voltada para aqueles que procuram novas oportunidades, fornecedores, parcerias, conhecimento e network.</p></div>';

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState(props.tamanho);

  const [messageError, setMessageError] = React.useState('');

  const [dados, setDados] = React.useState({
    address: '',
    city: '',
    state: '',
    place: '',
    description: '',
    image_description: '',
    start_date: null,
    end_date: null,
    is_national_event: false,
  });

  const [files, setFiles] = React.useState([]);

  const validateHtml = (htmlCode) => {
    let aux = new DOMParser().parseFromString(htmlCode, 'text/html');
    return aux?.body?.textContent?.trim() || '';
  };

  const enviarForm = async (e) => {
    //e.preventDefault();

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'multipart/form-data',
      },
    };

    const data = new FormData();

    if (!eventWithoutDate && (!dados.start_date || !dados.end_date)) {
      setMessageError(
        'Os campos de "Início do Evento", "Final do Evento"são obrigatórios'
      );
      return;
    }

    if (dados.city && dados.state && dados.is_national_event !== '') {
      data.append('address', dados.address);
      data.append('city', dados.city);
      data.append('state', dados.state);
      data.append('place', dados.place);
      data.append(
        'description',
        validateHtml(dados.description)
          ? dados.description
          : process.env.REACT_APP_TYPE_SITE === 'isp'
          ? defaultDescription
          : defaultDescriptionSolar
      );
      data.append('start_date', dados.start_date);
      data.append('end_date', dados.end_date);
      data.append('image_description', files[0]);
      data.append('is_national_event', dados.is_national_event);

      await api
        .post('/events', data, config)
        .then((a) => {
          setMessageError('');
          handleClose();
          getData();
          history.push(`/editar-evento/${a.data.id}`);
        })
        .catch((error) => {
          console.log(error.response);
          const message = error.response.data.messages
            ? error.response.data.messages[0].message
            : error.response.data.message;
          setMessageError(message);
        });
    } else {
      setMessageError(
        'Os campos de "Cidade", "UF" e "Tipo de Evento" são obrigatórios'
      );
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setDados({
      address: '',
      city: '',
      state: '',
      place: '',
      description: '',
      image_description: '',
      start_date: new Date(),
      end_date: null,
      is_national_event: '',
    });
    setMessageError('');
    setOpen(false);
  };

  const handleChange = (e) => {
    setFiles(e);
  };

  const [eventWithoutDate, setEventWithoutDate] = React.useState(true);

  return (
    <React.Fragment>
      <div>
        <Button
          variant="contained"
          onClick={handleOpen}
          className="Button"
          style={{ backgroundColor: theme.colors.primaryDark, color: '#fff' }}
        >
          Adicionar Evento
        </Button>
      </div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        scroll={'body'}
        // onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <Container>
          <form>
            <DialogTitle id="max-width-dialog-title">
              {props.editar ? 'Editar Evento' : '+ Novo Evento'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div className="subtitulo">
                  Preencha corretamente os campos abaixo para{' '}
                  {props.editar ? 'editar' : 'adicionar'} o Evento.
                </div>
                <div className="formulario">
                  <p style={{ fontSize: '16px' }}>{props.message}</p>

                  <TextField
                    id="standard-full-width"
                    label="Endereço"
                    value={dados.address}
                    onChange={(e) =>
                      setDados({ ...dados, address: e.target.value })
                    }
                    fullWidth
                    margin="normal"
                    variant="standard"
                  />

                  <div className="local-data">
                    <FormControl
                      required
                      className={classes.formControSelect}
                      style={{ width: '40%', marginRight: '1rem' }}
                    >
                      <InputLabel id="demo-simple-select-required-label">
                        Tipo de Evento
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        defaultValue=""
                        onChange={function (e) {
                          e.target.value
                            ? setDados({
                                ...dados,
                                is_national_event: e.target.value,
                                state: 'SP',
                                city: 'São Paulo',
                              })
                            : setDados({
                                ...dados,
                                is_national_event: e.target.value,
                              });
                        }}
                        className={classes.selectEmpty}
                      >
                        <MenuItem value={false}>Expedição</MenuItem>-
                        <MenuItem value={true}>Nacional</MenuItem>-
                      </Select>
                    </FormControl>

                    <TextField
                      label="Local"
                      id="standard-full-width"
                      defaultValue={dados.place}
                      className={classes.textField}
                      style={{ width: '60%' }}
                      variant="standard"
                      onChange={(e) =>
                        setDados({ ...dados, place: e.target.value })
                      }
                    />
                  </div>

                  <div className="cidade-uf">
                    <FormControl
                      required
                      className={classes.formControSelect}
                      style={{ width: '40%', marginRight: '1rem' }}
                    >
                      <InputLabel id="demo-simple-select-required-label">
                        UF
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        defaultValue=""
                        displayEmpty
                        onChange={function (e) {
                          if (dados.is_national_event) {
                            e.target.value === 'SP'
                              ? setDados({
                                  ...dados,
                                  state: e.target.value,
                                  city: 'São Paulo',
                                })
                              : setDados({
                                  ...dados,
                                  state: e.target.value,
                                  city: 'Olinda',
                                });
                          } else {
                            setDados({ ...dados, state: e.target.value });
                          }
                        }}
                        className={classes.selectEmpty}
                      >
                        {uf
                          .filter((value) =>
                            dados.is_national_event
                              ? value === 'SP' || value === 'PE'
                              : value
                          )
                          .map((item, index) => {
                            return (
                              <MenuItem
                                selected={item === 'SP' ? true : false}
                                value={item}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>

                    <TextField
                      id="standard-margin-name"
                      label="Cidade *"
                      inputProps={{
                        readOnly: dados.is_national_event ? true : false,
                      }}
                      //defaultValue={dados.city}
                      value={dados.city}
                      onChange={(e) =>
                        setDados({ ...dados, city: e.target.value })
                      }
                      style={{ width: '60%' }}
                      className={classes.textField}
                      variant="standard"
                    />
                  </div>

                  <div className="date-date">
                    {eventWithoutDate ? (
                      <>
                        <p>Data do evento: </p>
                        <div className="paiCheckboxContainer">
                          <p>Em breve</p>
                          <div className="checkboxContainer">
                            <input
                              type="checkbox"
                              name="date_check"
                              id="date_check"
                              value={true}
                              checked
                              onChange={(e) => {
                                setDados({
                                  ...dados,
                                  start_date: new Date().toISOString(),
                                  end_date: null,
                                });
                                setEventWithoutDate(!eventWithoutDate);
                              }}
                            />

                            <label for="date_check">Evento sem data</label>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="div__inLine">
                        <div className="date-date-date">
                          <div className="div__inLine">
                            <p>Início do evento: </p>

                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={ptBR}
                            >
                              <DateTimePicker
                                value={dados.start_date}
                                format="dd/MM/yyyy HH:mm"
                                ampm={false}
                                maxDate={
                                  dados.end_date
                                    ? dados.end_date
                                    : new Date('2100-01-01')
                                }
                                onChange={(e) => {
                                  setDados({
                                    ...dados,
                                    start_date: e.toISOString(),
                                  });
                                  setEventWithoutDate(false);
                                }}
                                style={{ width: '50%' }}
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                          <div className="div__inLine">
                            <p>Final do evento: </p>

                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={ptBR}
                            >
                              <DateTimePicker
                                value={dados.end_date}
                                format="dd/MM/yyyy HH:mm"
                                ampm={false}
                                minDate={dados.start_date}
                                onChange={(e) => {
                                  setDados({
                                    ...dados,
                                    end_date: e.toISOString(),
                                  });
                                  setEventWithoutDate(false);
                                }}
                                style={{ width: '50%' }}
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </div>
                        <div className="div__inColumn">
                          <div className="checkboxContainer">
                            <input
                              type="checkbox"
                              name="date_check"
                              id="date_check"
                              value={false}
                              onChange={() => {
                                setDados({
                                  ...dados,
                                  start_date: null,
                                  end_date: null,
                                });

                                setEventWithoutDate(!eventWithoutDate);
                              }}
                            />

                            <label for="date_check">Evento sem data</label>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <TextField
                    id="standard-full-width"
                    label="Descrição"
                    multiline
                    defaultValue={dados.description}
                    onChange={(e) =>
                      setDados({ ...dados, description: e.target.value })
                    }
                    fullWidth
                    // margin="normal"
                    variant="standard"
                  />

                  <br />
                  <DropzoneArea
                    onChange={handleChange}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                    filesLimit={'1'}
                    dropzoneText="Arraste a imagem para este campo ou clique aqui para buscar"
                    maxFileSize="6000000"
                    getFileAddedMessage={(fileName) =>
                      `Arquivo ${fileName} adicionado com sucesso.`
                    }
                    getFileRemovedMessage={(fileName) =>
                      `Arquivo ${fileName} removido.`
                    }
                    getFileLimitExceedMessage={(filesLimit) =>
                      `Número máximo de ${filesLimit} arquivos excedido.`
                    }
                    getDropRejectMessage={() =>
                      `Tipo de arquivo não permitido.`
                    }
                  />

                  {props.editar ? (
                    <div className="imagematual">
                      <h3>Imagem atual</h3>
                      <img src={dados.image} alt="iamge_atual" />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </DialogContentText>
            </DialogContent>

            {messageError && (
              <Alert
                style={{ margin: '1rem 2rem' }}
                variant="outlined"
                severity="error"
              >
                {messageError.replace('!', '')}. Corrija e tente novamente.
              </Alert>
            )}

            <DialogActions>
              <Button
                onClick={() => handleClose()}
                color=""
                variant="contained"
              >
                Cancelar
              </Button>
              <Button
                onClick={() => enviarForm()}
                style={{
                  backgroundColor: theme.colors.primaryDark,
                  color: '#fff',
                }}
                variant="contained"
              >
                {props.editar ? 'Editar' : 'Adicionar'}
              </Button>
            </DialogActions>
          </form>
        </Container>
      </Dialog>
    </React.Fragment>
  );
}
