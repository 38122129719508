import styled from 'styled-components';

export const Container = styled.div`

    .subtitulo{
        font-size: 14px;
    }

    .divisaoEtapas{
        display:flex;
        flex-direction: row;
        justify-content:center;
        
        .subDiv{
            display:flex;
            background-color: #e0e0e0;
            margin: 0.25rem;
            padding: 0.25rem;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            justify-content:center;
            align-items:center;
        }
        .ativo{
            background-color: #245C9D;
            color:#fff;
        }
    }

    .formulario{
       
        display: flex !important;
        flex-direction: column !important;
        
        .parte1{
            display: flex !important;
            flex-direction: column !important;
            justify-content: center;
            text-align: center;
            padding: 1rem 0rem;

            .botoes{
                button{
                    padding: 0.75rem 1.5rem;
                    margin: 0.5rem;
                    border: 1px solid rgba(0,0,0,0.1);
                    border-radius: 10px;
                    color: rgba(0,0,0,0.8);
                }
                button:hover{
                    border: 1px solid #245C9D;
                    color: #fff;
                    background-color: #245C9D;
                }
            }
        }
    }

    form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
    }
`;
