import React, { useCallback, useEffect, useState } from 'react';

import { Container } from './styles';

// icons
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '../../../components/Backdrop';
// dropdown
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import format from 'date-fns/format';

// api
import api from '../../../services/api';

import Dialogodeconfirmacao from '../../../components/Dialogodeconfirmacao';
import Novoexpositor from '../../../components/Novoexpositor';
import imageUrl from '../../../utils/imageUrl';

function Configuracoes(props) {
  const [backdrop, setBackdrop] = useState(false);

  const [dadosfilter, setDadosfilter] = useState([]);

  const [eventos, setEventos] = useState([]);
  const [filtro, setFiltro] = React.useState({
    tipo: '',
    descricao: '',
  });

  const [dialogoApoiador, setDialogoApoiador] = React.useState({
    status: false,
    editar: false,
    dados: '',
    id: '',
  });

  const listEvents = useCallback(() => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
      },
    };

    api
      .get('/events', config)
      .then((response) => {
        let events = response.data;

        setEventos([...events.nextEvents, ...events.previousEvents]);
        // console.log(events);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.user.token]);

  // chamada api
  const [exhibitors, setExhibitors] = useState([]);

  const getExhibitors = useCallback(async () => {
    setBackdrop(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    await api
      .get(`/exhibitors`, config)
      .then((response) => {
        response.data.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
        setExhibitors(response.data);
        setBackdrop(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, [props.user.token, setBackdrop]);

  const removeExhibitors = async (id) => {
    setBackdrop(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    await api
      .delete(`/exhibitors/${id}`, config)
      .then((response) => {
        setBackdrop(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };
  //fim

  const aplicarFiltro = async (e) => {
    setFiltro({ ...filtro, descricao: e.target.value });

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    if (e) {
      await api
        .get(`/related-exhibitors-event/${e.target.value}`, config)
        .then((response) => {
          setDadosfilter(response.data);
          // console.log('dados vincu: ', response.data);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    } else {
      setDadosfilter([]);
    }
  };

  const [confirmacao, setConfirmacao] = useState([false, '']);

  useEffect(() => {
    getExhibitors();
    if (eventos.length === 0) listEvents();
  }, [getExhibitors, eventos.length, listEvents]);

  return (
    <Container>
      <header>
        <h1>Expositores</h1>
        <p>
          Adicione ou exclua expositores que poderão ser vinculados a eventos.
        </p>
      </header>
      <div className="funcoesdefiltro">
        <div className="filtros">
          <FormControl
            variant=""
            margin="dense"
            style={{
              margin: '0rem 1rem',
              marginTop: '-0.0rem',
              width: '200px',
              textAlign: 'left',
            }}
          >
            <InputLabel id="id-selctor-zone">{'Filtrar'}</InputLabel>
            <Select
              labelId="id-selctor-zone"
              id="id-selctor-zone-select"
              value={filtro.tipo}
              onChange={(e) =>
                setFiltro({ ...filtro, tipo: e.target.value, descricao: '' })
              }
              label="Tipo"
            >
              <MenuItem value="" default>
                <em>Nenhum</em>
              </MenuItem>
              <MenuItem value={'evento'}>Evento</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant=""
            margin="dense"
            style={{
              margin: '0rem 1rem',
              marginTop: '-0.0rem',
              width: '200px',
              textAlign: 'left',
            }}
          >
            <InputLabel id="id-selctor-zone">
              {' '}
              {filtro.tipo === 'evento' ? 'Selecionar' : '-'}
            </InputLabel>
            <Select
              labelId="id-selctor-zone"
              id="id-selctor-zone-select"
              value={filtro.descricao}
              onChange={(e) => aplicarFiltro(e)}
              label="Estado"
              disabled={filtro.tipo === 'evento' ? false : true}
            >
              <MenuItem value="" default>
                <em>Nenhum</em>
              </MenuItem>
              {eventos.map((a, i) => (
                <MenuItem key={i} value={a.id}>
                  {format(new Date(a.start_date), 'dd/MM/yyyy')} - {a.city} -{' '}
                  {a.state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="MenuDireita">
          <button
            className="cadastroManual"
            onClick={() =>
              setDialogoApoiador({ status: true, editar: false, dados: '' })
            }
          >
            {' '}
            + Novo Expositor
          </button>
        </div>
      </div>

      <div className="campoGeral">
        {!filtro.descricao && exhibitors.length > 0 ? (
          exhibitors.map((a, i) => (
            <div className="card" key={i}>
              <div className="campo1">
                <img src={imageUrl(a.image)} alt="" />
              </div>
              <div className="campo2">
                <div className="infor">
                  <div className="linha">
                    <label>Nome:</label>
                    <br /> {a.name.slice(0, 44)}
                    {a.name.length > 23 ? '(...) ' : ''}
                  </div>
                  <div className="linha">
                    <label>Link:</label>{' '}
                    <a href={a.link} target="_blank" rel="noreferrer">
                      {a.link.slice(0, 23)}
                      {a.link.length > 23 ? '(...) ' : ''}
                    </a>
                  </div>
                  <div className="linha criado">
                    {' '}
                    Cadastrado em <br />
                    {format(new Date(a.created_at), 'dd/MM/yyyy')}
                  </div>
                </div>
                <div className="campoMenu">
                  <IconButton
                    className="botoes btnedit"
                    onClick={() =>
                      setDialogoApoiador({
                        status: true,
                        editar: true,
                        dados: a,
                        id: a.id,
                      })
                    }
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    className="botoes btndel"
                    onClick={() => setConfirmacao([true, a.id])}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          ))
        ) : filtro.descricao ? (
          ''
        ) : (
          <h2>Nenhum expositor encontrado !</h2>
        )}
      </div>

      <div className="campoGeral">
        {filtro.descricao && dadosfilter.length > 0 ? (
          dadosfilter.map((a, i) => (
            <div className="card" key={i}>
              <div className="campo1">
                {console.log('Expositor: ', a.objExhibitor)}
                <img src={imageUrl(a.objExhibitor.image)} alt="" />
              </div>
              <div className="campo2">
                <div className="infor">
                  <div className="linha">
                    <label>Nome:</label>
                    <br /> {a.objExhibitor.name.slice(0, 44)}
                    {a.objExhibitor.name.length > 23 ? '(...) ' : ''}
                  </div>
                  <div className="linha">
                    <label>Link:</label>{' '}
                    <a
                      href={a.objExhibitor.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {a.objExhibitor.link.slice(0, 23)}
                      {a.objExhibitor.link.length > 23 ? '(...) ' : ''}
                    </a>
                  </div>
                  <div className="linha criado">
                    {' '}
                    Cadastrado em <br />
                    {format(new Date(a.objExhibitor.created_at), 'dd/MM/yyyy')}
                  </div>
                </div>
                <div className="campoMenu">
                  <IconButton
                    className="botoes btnedit"
                    onClick={() =>
                      setDialogoApoiador({
                        status: true,
                        editar: true,
                        dados: a.objExhibitor,
                        id: a.objExhibitor.id,
                      })
                    }
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    className="botoes btndel"
                    onClick={() => setConfirmacao([true, a.objExhibitor.id])}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          ))
        ) : filtro.descricao ? (
          <h2>Nenhum expositor encontrado !</h2>
        ) : (
          ''
        )}
      </div>

      {confirmacao[0] ? (
        <Dialogodeconfirmacao
          tamanho="xs"
          message="Tem certeza de que deseja apagar este expositor?"
          confirmar={async () => {
            await removeExhibitors(confirmacao[1]);
            await getExhibitors();
            setConfirmacao([false, '']);
          }}
          negar={() => setConfirmacao([false, ''])}
        />
      ) : (
        ''
      )}
      {dialogoApoiador.status ? (
        <Novoexpositor
          user={props.user}
          editar={dialogoApoiador.editar}
          dados={dialogoApoiador.dados}
          id={dialogoApoiador.id}
          tamanho="xs"
          negar={() =>
            setDialogoApoiador({ status: false, editar: false, dados: '' })
          }
          atualizar={async () => {
            setDialogoApoiador({ status: false, editar: false, dados: '' });
            await getExhibitors();
          }}
        />
      ) : (
        ''
      )}
      {backdrop && <Backdrop />}
    </Container>
  );
}

export default Configuracoes;
