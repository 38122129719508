import styled from 'styled-components';

export const Container = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  /* font-family: 'Poppins', sans-serif !important; */
  font-family: 'Quicksand', sans-serif !important;

  .realizadoComSucesso {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 1rem;
    margin: auto;
    text-align: center;
    font-size: 18px;

    h3 {
      margin-top: 1rem;
    }

    .divisaoTitu {
      margin-top: 1rem;
      height: 2px;
      width: 200px;
      background-color: var(--primary);
      border-radius: 30px;
    }

    .icones {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 1rem;

      svg {
        font-size: 35px;
        color: ${(props) => props.theme.colors.primaryDark};
      }
    }

    button {
      margin-top: 2rem;
      border: 1px solid ${(props) => props.theme.colors.primaryDark};
      color: ${(props) => props.theme.colors.primaryDark};
      background-color: #ffffff;
      border-radius: 20px;
      padding: 0.5rem 2rem;
      font-size: 16px;
    }
    button:hover {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    }
  }
  form {
    display: flex;
    flex-direction: column;

    h5 {
      text-align: center;
    }
    .tipoDeInscrito {
      display: flex;
      flex-direction: row;
      margin: auto;
      margin-bottom: 3rem;

      input[type='radio'] {
        /* visibility:hidden; */
        visibility: hidden;
        display: none;
      }

      .cadSelect {
        border-bottom: 2px solid ${(props) => props.theme.colors.primaryDark} !important;
        color: var(--primary) !important;
      }

      .tipoVisitante {
        label {
          border-top: 1px solid rgba(0, 0, 0, 0.2);
          border-left: 1px solid rgba(0, 0, 0, 0.2);
          border-bottom: 2px solid rgba(0, 0, 0, 0.2);
          border-right: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
      .tipoExpositor {
        label {
          border-top: 1px solid rgba(0, 0, 0, 0.2);
          /* border-left: 1px solid rgba(0,0,0,0.2); */
          border-bottom: 2px solid rgba(0, 0, 0, 0.2);
          border-right: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
      .tipoVisitante,
      .tipoExpositor {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        justify-items: center;

        label {
          cursor: pointer;
          margin: 0rem;
          padding: 1rem 0rem;
          flex: 1;
          font-size: 18px;
          text-align: center;
          align-items: center;
          justify-content: center;
        }
        label:select {
          background-color: #000;
        }
      }
    }

    label {
      margin: 1rem 1rem 0.5rem 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      text-transform: uppercase;
      font-size: 14px;
      svg {
        font-size: 15px;
        margin-top: -0.1rem;
      }
    }
    .reparticao {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      input,
      select,
      label {
        flex: 1;
        padding: 0.5rem 0.5rem;
        margin: 0.5rem 0.5rem;
        font-style: italic;
        border: 1px solid #e6e6e6;
      }
    }
  }

  .cancelar {
    border: 1px solid #606062;
    color: #606062;
  }
  .confirmar {
    border-color: 1px solid ${(props) => props.theme.colors.primaryDark};
    background-color: ${(props) => props.theme.colors.primaryDark};
    color: #ffffff;
  }
  .confirmar:hover {
    background-color: ${(props) => props.theme.colors.primaryDark};
    opacity: 0.8;
    color: #ffffff;
  }

  @media only screen and (max-width: 675px) {
    margin: 0rem;
    form {
      display: flex;
      flex-direction: column;

      select {
        width: 100%;
        padding: 0rem !important;
        margin: 0.3rem 0rem !important;
      }
      .reparticao {
        flex-direction: column;

        input {
          width: 100%;
        }
      }
    }
    input {
      margin: 0.3rem 0rem !important;
      width: 100%;
    }

    .ladoalado {
      flex-direction: row !important;
      label {
        flex-direction: column !important;
      }
    }
  }
`;
