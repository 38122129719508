import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';

import imgExpedicao from '../../assets/login/expo-back.jpg';
import imgNacional from '../../assets/login/expoisp-brasil.jpg';

import { EventCard } from './styles';

export function CardEvento({ event, setConfirmDelete }) {
  return (
    <EventCard
      imgBackground={event.is_national_event ? imgNacional : imgExpedicao}
      key={event.id}
      isPublished={event.is_published}
      isNational={event.is_national_event}
    >
      <div className="gradient">
        <div className="id_corner">
          {event.id < 10
            ? '00' + event.id
            : event.id < 100
            ? '0' + event.id
            : event.id}
        </div>
        <div className="type_event_corner">
          {event.is_national_event ? 'Nacional' : 'Expedição'}
          {!event.is_published && <h6>Rascunho</h6>}
        </div>
        <div className="state">
          <h1>
            {event.city ? event.city : 'A definir'} -{' '}
            {event.state ? event.state : ''}
          </h1>
        </div>
        <div className="state">
          <h1>{event.address ? event.address : 'A definir'}</h1>
        </div>
      </div>

      <div className="options">
        <div className="date">
          <span>
            {event.start_date
              ? new Date(event.start_date).toLocaleDateString('pt-BR', {
                  day: '2-digit',
                  month: '2-digit',
                })
              : 'Sem data'}
          </span>
          <h1>
            {event.start_date
              ? new Date(event.start_date).toLocaleDateString('pt-BR', {
                  year: 'numeric',
                })
              : ''}
          </h1>
        </div>
        <div className="buttons">
          {/* <Button
                  size="small"
                  color="secondary"
                  onClick={() => setConfirmDelete([true, event.id])}
                >
                  <DeleteIcon />
                </Button> */}

          {event.is_published ? (
            <a
              href={
                event.is_national_event
                  ? `http://${event.state === 'SP' ? 'saopaulo' : 'olinda'}.${
                      process.env.REACT_APP_TYPE_SITE === 'isp'
                        ? 'expoispbrasil'
                        : 'expobrasilsolar'
                    }.com.br/eventos/${event.id}/`
                  : `http://expedicao.${
                      process.env.REACT_APP_TYPE_SITE === 'isp'
                        ? 'expoispbrasil'
                        : 'expobrasilsolar'
                    }.com.br/evento/${event.id}/`
              }
              target="_blank"
              rel="noreferrer"
            >
              <button size="small">
                <VisibilityIcon style={{ color: '#555' }} />
              </button>
            </a>
          ) : (
            ''
          )}

          <Link to={`/editar-evento/${event.id}`}>
            <button size="small" style={{ color: '#555' }} onClick={() => {}}>
              <CreateIcon onClick={() => {}} />
            </button>
          </Link>
          <a href="#delete">
            <button
              size="small"
              color="secondary"
              className="delete"
              onClick={() => setConfirmDelete([true, event.id])}
            >
              <DeleteIcon style={{ color: '#f44336' }} />
            </button>
          </a>
        </div>
      </div>
    </EventCard>
  );
}
