import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';

// tema
import { useTheme } from 'styled-components';

// api
import API from '../../services/api';

import { Container } from './styles';

export default function MaxWidthDialog(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState(props.tamanho);

  const [dados, setDados] = React.useState({
    name: props.editar ? props.dados.name : '',
    email: props.editar ? props.dados.email : '',
    is_admin: props.editar ? props.dados.is_admin : '',
    password: '',
  });

  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleClose = () => {
    setOpen(false);
    props.negar();
  };

  const enviarNovo = (e) => {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    if (props.editar) {
      API.put(`/users/${props.dados.id}`, dados, config)
        .then((a) => {
          props.alerta({
            open: true,
            status: 'success',
            message: 'Dados do usuário editados com sucesso.',
          });
          props.negar();
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    } else {
      API.post(`/users`, dados, config)
        .then((a) => {
          props.alerta({
            open: true,
            status: 'success',
            message: 'Novo usuário cadastrado com sucesso.',
          });
          props.negar();
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        scroll={'body'}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <Container>
          <form onSubmit={enviarNovo}>
            <DialogTitle id="max-width-dialog-title">
              {props.editar ? 'Editar Usuário' : '+ Novo Usuário'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div className="subtitulo">
                  Preencha corretamente os campos abaixo para{' '}
                  {props.editar ? 'editar' : 'adicionar'} o usuário.
                </div>
                <div className="formulario">
                  <p style={{ fontSize: '16px' }}>{props.message}</p>
                  <TextField
                    required
                    id="standard-basic"
                    className="campo"
                    label="Nome"
                    onChange={(e) =>
                      setDados({ ...dados, name: e.target.value })
                    }
                    value={dados.name}
                  />
                  <TextField
                    type="email"
                    required
                    id="standard-basic"
                    className="campo"
                    label="E-mail"
                    onChange={(e) =>
                      setDados({ ...dados, email: e.target.value })
                    }
                    value={dados.email}
                  />

                  <FormControl
                    variant=""
                    margin="dense"
                    required
                    className="campo"
                  >
                    <InputLabel id="id-selctor-zone" required>
                      {'Função'}
                    </InputLabel>
                    <Select
                      labelId="id-selctor-zone"
                      id="id-selctor-zone-select"
                      value={dados.is_admin}
                      required
                      onChange={(e) => {
                        setDados({ ...dados, is_admin: e.target.value });
                      }}
                      label="Tipo"
                      // style={{ height:'40px',}}
                      margin="dense"
                    >
                      {/* <MenuItem value="" default>
                          <em>Nenhum</em>
                      </MenuItem>                   */}
                      <MenuItem value={false}>Moderador</MenuItem>
                      <MenuItem value={true}>Administrador</MenuItem>
                    </Select>
                  </FormControl>

                  {props.editar ? (
                    ''
                  ) : (
                    <FormControl className={'campo'} required>
                      <InputLabel
                        htmlFor="standard-adornment-password"
                        required
                      >
                        Senha
                      </InputLabel>
                      <Input
                        id="standard-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={dados.password}
                        required
                        onChange={(e) =>
                          setDados({ ...dados, password: e.target.value })
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setValues({
                                  ...values,
                                  showPassword: !values.showPassword,
                                })
                              }
                              // onMouseDown={()=> setValues({...values, showPassword: !values.showPassword})}
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  )}
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => props.negar()}
                color=""
                variant="contained"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                onClick={''}
                style={{
                  backgroundColor: theme.colors.primaryDark,
                  color: '#fff',
                }}
                variant="contained"
              >
                {props.editar ? 'Editar' : 'Adicionar'}
              </Button>
            </DialogActions>
          </form>
        </Container>
      </Dialog>
    </React.Fragment>
  );
}
