import styled from 'styled-components';

export const Container = styled.div`
  .header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding: 0.1rem 0.5rem;

    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    .left {
      display: flex;

      .icon {
        transform: ${(props) =>
          props.active
            ? 'rotate(90deg) translateX(-20%) translateY(+10%)'
            : ''};
        transition: 0.3s;
        transition-timing-function: ease-in;
      }

      span {
        font-size: 1.5rem;
        margin-right: 1rem;
      }
    }
  }

  .content {
    display: ${(props) => (props.active ? 'block' : 'none')};
    margin-top: 0.5rem;

    .a {
      .div__campo_de_texto {
        width: 80%;
        margin: 1.5rem auto;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.05);
        padding: 1rem 1.1rem;
        border-radius: 0.5rem;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);

        input {
          width: 100%;
        }

        b {
          margin: 0rem 0.5rem;
        }

        a {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          text-align: left;
          /* justify-content:center; */
          color: #000;
          svg {
            margin-right: 0.25rem;
          }
        }
        a:hover {
          opacity: 0.8;
        }
      }

      .container {
        width: 80%;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        margin-top: 20px;
        padding-bottom: 30px;
      }
      .dropzone {
        grid-column: 1/4;
      }

      .image-card {
        margin: 5px;
        border-radius: 2px;
        position: relative;
      }

      .icones {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5em;

        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 1em;
      }
      .icone-del {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #f44336;
        padding: 1em;
        cursor: pointer;
      }
      .icone-del:hover {
        color: #fff;
        background-color: #f44336;
        border-radius: 50%;
        padding: 1em;
      }
      .icone-vision {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        padding: 1em;
        cursor: pointer;
      }
      .icone-vision:hover {
        color: #777;
        background-color: #fff;
        border-radius: 50%;
        padding: 1em;
      }
      .image {
        width: 100%;
        object-fit: cover;
        height: 10em;
        border-radius: 0.8rem;
      }
    }
  }

  .button {
    display: flex;
    justify-content: flex-end;

    .salvarinfo {
      padding: 0.8rem 1rem;
      background: ${(props) => props.theme.colors.primaryDark};
      border: 0;
      outline: none;
      margin-bottom: -0.2rem;

      color: #fff;
      font-size: 1rem;
      border-radius: 5px 5px 0rem 0rem !important;
    }
    .cancel {
      padding: 0.8rem 1rem;
      margin-bottom: -0.2rem;
      border-radius: 5px 5px 0rem 0rem !important;
      border: 0;
      outline: none;
      background: rgba(0, 0, 0, 0.1);
      color: #3f51b5;
      text-decoration: none;
      margin-left: 1rem;
    }
  }

  .campoDeEdicao {
    width: 100%;
    .header2 {
      width: 100%;
      margin: 0rem 1rem;

      .cadastroManual {
        float: right;
        background-color: ${(props) => props.theme.colors.primaryDark};
        /* margin: 0.8rem auto; */
        align-self: center;
        border: 0px;
        color: #fff;
        padding: 1.15rem 1.25rem;
        display: flex;
        flex-direction: row;
        justify-items: center;
        align-items: center;
        margin-bottom: 1rem;
        margin-right: 2rem;
        margin-top: 1rem;
        img {
          width: 20px;
          margin-right: 0.5rem;
        }
      }
      .cadastroManual:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
      }
    }
    .ul {
      display: flex;
      flex-direction: column;
      width: 100%;
      .li {
        display: flex;
        flex-direction: row;
        width: 95%;
        margin: 0.05rem auto;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        /* padding: 1rem; */
        div {
          padding: 1rem 0.5rem;
        }
        .hora {
          /* text-align:left; */
          font-size: 14px;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100% !important;
            height: auto;
          }
        }
        .descr {
          padding: 1rem !important;
          div {
            display: flex;
            flex-direction: column;
            label {
              font-weight: 600;
              font-size: 16px;
            }
            a {
              display: flex;
              flex-direction: row;
              /* justify-content:center; */
              color: #000;
              svg {
                margin-right: 0.25rem;
              }
            }
            a:hover {
              opacity: 0.8;
            }
            margin: 0rem;
            padding: 0rem;
          }
        }
        .campoMenu {
          .botoes {
            padding: 0.5rem;
          }
          .btnedit {
            bottom: 0.5rem;
            right: 1rem;
            padding: 0.5rem;
          }
          .btnedit:hover {
            background-color: #6c6c6c;
            svg {
              color: #fff;
            }
          }
          .btndel {
            bottom: 0.5rem;
            right: 0.5rem;
            padding: 0.5rem;
          }
          .btndel:hover {
            background-color: #f44336;
            svg {
              color: #fff;
            }
          }
        }
      }
    }
  }
`;
