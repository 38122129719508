import React from 'react';

import { Container } from './styles';
// api
import API from '../../../services/api';
import { format } from 'date-fns';
import xlsx from 'json-as-xlsx';

// notificação
import { toast } from 'react-toastify';

// icones
import PublishIcon from '@material-ui/icons/Publish';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

// icone do excel
import excelicon from '../../../files/images/excel-icon.png';

// backdrop
import Backdrop from '../../../components/Backdrop';
// mascara
import { mask } from 'remask';

function Exportardados(props) {
  const [arquivo, setArquivo] = React.useState();
  const [backdrop, setBackdrop] = React.useState(false);
  const [listaTxt, setListatxt] = React.useState([]);
  const [listaConsulta, setListaconsulta] = React.useState([]);
  const [show, setShow] = React.useState(true);

  const loadArchive = (event) => {
    if (event.target.files[0] && event.target.files[0].type === 'text/plain') {
      setArquivo(event.target.files[0]);
    } else {
      toast.error('Tipo de arquivo inválido, insira .txt');
    }
  };

  function findData() {
    const file = arquivo;
    const reader = new FileReader();

    reader.onload = (event) => {
      const file = event.target.result;
      const allLines = file.split(/\r\n|\n/);
      setListatxt(allLines);

      atualizarLista(allLines);

      // Reading line by line
    };

    reader.onerror = (event) => {
      alert(event.target.error.name);
    };

    reader.readAsText(file);
  }

  const atualizarLista = async (txtIds) => {
    setBackdrop(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
      // params: {
      //   ids: JSON.stringify(txtIds),
      // },
    };

    await API.post('enrollments/ids', { ids: txtIds }, config)
      .then((a) => {
        let aux = [];

        txtIds.forEach((line) => {
          a.data.map(
            (lista, idx) =>
              lista.id === mask(line.replace('EY-', ''), '999-999999') &&
              aux.push(lista),
          );
        });

        setListaconsulta([...aux]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setBackdrop(false));
  };

  const limparCampos = (e) => {
    setArquivo();
    setListatxt([]);
    setListaconsulta([]);
    setShow(false);
    setTimeout(() => setShow(true), 1);
  };

  var convert = function () {
    var columns = [
      {
        label: 'Data de Inscrição',
        value: (row) =>
          format(new Date(row.created_at), 'dd/MM/yyyy') +
          ' às ' +
          format(new Date(row.created_at), 'HH:mm'),
      }, // Top level data
      { label: 'Cód. Inscrição', value: (row) => row.id }, // Top level data
      { label: 'Nome', value: (row) => row.name }, // Run functions
      { label: 'E-mail', value: (row) => row.email }, // Run functions
      { label: 'CPF', value: (row) => row.cpf || ' - ' }, // Run functions
      { label: 'RNE', value: (row) => row.rne || ' - ' }, // Run functions
      { label: 'Telefone', value: (row) => row.phone }, // Run functions
      { label: 'Estado', value: (row) => row.state }, // Run functions
      { label: 'Cidade', value: (row) => row.city }, // Run functions
      { label: 'Bairrro', value: (row) => row.district }, // Run functions
      { label: 'Endereço', value: (row) => row.street }, // Run functions
      { label: 'Número', value: (row) => row.house_number }, // Run functions
      {
        label: 'Complemento',
        value: (row) => row.complement || '-',
      }, // Run functions
      {
        label: 'Categoria',
        value: (row) => row.category || 'Normal',
      },
      {
        label: 'Visitante',
        value: (row) => (row.is_exhibitor ? 'Não' : 'Sim'),
      }, // Run functions
      { label: 'Nome da Credencial', value: (row) => row.credential_name }, // Run functions
      { label: 'Empresa/Instituição', value: (row) => row.credential_company }, // Run functions
      { label: 'Ocupação/Cargo', value: (row) => row.credential_role }, // Run functions
      //{ label: 'Tipo de Inscrição', value: (row) => row.type_inscription }, // Run functions

      {
        label: 'Tipo de Inscrição',
        value: (row) =>
          row.type_inscription
            ? row.type_inscription === 'Outro'
              ? `Outro - ${row.another_type_inscription || 'Não informado'}`
              : row.type_inscription
            : 'Não informado',
      },
      {
        label: 'Aceitou os termos de uso',
        value: (row) => (row.agree_privacy_policies ? 'Sim' : 'Não'),
      },
      {
        label: 'Permissão para usar imagem',
        value: (row) => (row.allow_use_image ? 'Sim' : 'Não'),
      },
      {
        label: 'Quem indicou',
        value: (row) =>
          row.who_indicated
            ? row.who_indicated === 'Outro'
              ? `Outro - ${row.another_indication || 'Não informado'}`
              : row.who_indicated
            : 'Não informado',
      },
      {
        label: 'Serviços de IPTV, telefonia, etc',
        value: (row) => row.has_service_tv_iptv,
      }, // Run functions
      {
        label: 'Rede de fibra óptica FTTx',
        value: (row) => (row.has_fiber_optic ? 'Sim' : 'Não'),
      }, // Run functions
      { label: 'Porte da empresa', value: (row) => row.company_size }, // Run functions
      {
        label: 'Quantidade de link contratado',
        value: (row) => row.contracted_link,
      }, // Run functions
    ];
    var content = listaConsulta;

    let data = [
      {
        sheet: 'Mailing',
        columns: columns,
        content: content,
      },
    ];

    let settings = {
      fileName: `Dados importados para ${arquivo.name
        .toLowerCase()
        .replace('.txt', '')}`,
      extraLength: 3, // A bigger number means that columns will be wider
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };

    xlsx(data, settings);
  };

  return (
    <Container>
      <header>
        <h1>Exportar dados</h1>
        <p>
          Importe o arquivo .txt com a lista de inscrições e exporte seus dados
          em excel.
        </p>
      </header>
      <body>
        {show && (
          <div className="div__duasCol">
            <label
              for="dadostxt"
              className={arquivo ? 'label__file onArchive' : 'label__file'}
            >
              <input
                onChange={loadArchive}
                type="file"
                name="dadostxt"
                id="dadostxt"
                className="input__file"
              />
              {arquivo ? (
                <>
                  <AttachFileIcon /> {arquivo.name} <span>Substituir</span>
                </>
              ) : (
                <>
                  <PublishIcon /> Enviar arquivo
                </>
              )}
            </label>
            <button
              disabled={arquivo ? false : true}
              style={{
                opacity: !arquivo && 0.5,
                pointerEvents: !arquivo && 'none',
              }}
              type="button"
              className="button__excluir"
              onClick={() => limparCampos()}
            >
              <DeleteOutlineIcon /> Limpar campo
            </button>
          </div>
        )}
        <button
          disabled={listaConsulta.length > 0 ? true : false}
          style={{
            opacity: listaConsulta.length > 0 && 0.5,
            pointerEvents: listaConsulta.length > 0 && 'none',
          }}
          onClick={() => findData()}
          type="button"
          className={
            arquivo ? 'button_coletar onArchiveButton' : 'button_coletar'
          }
        >
          Buscar dados
        </button>

        {listaConsulta.length > 0 && (
          <div className="div__encontrado">
            <div className="div__infor">
              <b>Informações:</b>
              <ul>
                <li>
                  O arquivo .txt possui {listaTxt.length} linhas, e foram
                  encontrados {listaConsulta.length} inscritos na base de dados.
                </li>
                {listaConsulta.length !== listaTxt.length && (
                  <li>
                    Quantidade de dados encontrada é diferente do número de
                    linhas do .txt, confira as informações do arquivo e tente
                    novamente.
                  </li>
                )}
              </ul>

              <button className="botaoBaixarExcel" onClick={() => convert()}>
                {' '}
                <img src={excelicon} alt="Baixar" />
                Baixar Lista
              </button>
            </div>
          </div>
        )}
      </body>

      {backdrop ? <Backdrop /> : ''}
    </Container>
  );
}

export default Exportardados;
