import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  // backgroundColor:'transparent',
  border: 0px;
  border-radius: 6px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  //  max-width:1000px;
  margin: auto;
  padding: 2rem;

  header {
    padding-bottom: 1rem;
    border-bottom: 1px solid #d3d3d3;

    h1 {
      font-weight: 800;
      color: rgba(0, 0, 0, 0.8);
    }

    p {
      color: rgba(0, 0, 0, 0.7);
      font-weight: 600;
    }
  }

  .funcoesdefiltro {
    margin: 1rem 0rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .MenuDireita {
    display: flex;
    flex-direction: row;
    margin: 0.5rem;

    .cadastroManual {
      background-color: ${(props) => props.theme.colors.primaryDark};
      /* margin: 0.8rem auto; */
      align-self: center;
      border: 0px;
      color: #fff;
      padding: 1rem 1rem;
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      border-radius: 0.5rem;
      img {
        width: 20px;
        margin-right: 0.5rem;
      }
    }
    .cadastroManual:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    }
  }

  .cards-area {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .card {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 5rem;
      background: #fff;
      border: 1px solid var(--gray-200);
      border-top: 0px;
      overflow: hidden;

      h3 {
        width: 40%;
        font-size: 16px;
        font-weight: 500;
        color: #333;
        text-align: left;

        padding-right: 2rem;
        padding-left: 2rem;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: wrap;
      }

      strong {
        font-weight: 500;
        width: 100%;
        margin-top: 5px;
        margin-bottom: 0px;
        /* text-align: center; */
        font-size: 14px;
        color: #333;
        text-align: left;

        /* padding-right: 2rem; */
        /* padding-left: 2rem; */
        /* background-color: red; */
      }

      p {
        width: 40%;
        margin-top: 5px;
        margin-bottom: 0px;
        text-align: center;
        font-size: 14px;
        color: #333;
        text-align: left;

        padding-right: 2rem;
        padding-left: 2rem;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: wrap;
      }

      .buttons {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        button {
          display: flex;
          //padding: 1rem 1rem;
          height: 3rem;
          width: 3rem;
          margin-right: 1rem;
          background: none;
          border: 0;
          justify-content: center;
          align-items: center;

          color: rgba(0, 0, 0, 0.75);

          :hover {
            background: #777;
            border-radius: 50%;

            &.delete {
              background: #f44336;
            }

            svg {
              color: #fff !important;
            }
          }
        }
      }

      :hover {
        background: #f2f2f2;
      }

      &.first {
        border-top: 1px solid var(--gray-200);
      }
    }

    .card-title {
      display: flex;
      flex-direction: row;
      /* justify-content: center; */
      align-items: center;
      margin: 0.3rem;
      width: 100%;
      height: 3rem;
      background: #fff;
      overflow: hidden;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      margin-bottom: 1rem;

      h3 {
        width: 20%;
        font-size: 16px;
        color: #333;
        text-align: left;
        padding: 0 30px;

        /* padding-right: 2rem; */
        /* padding-left: 2rem; */

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: wrap;

        &.options {
          text-align: right;
        }
      }
    }
  }
`;
