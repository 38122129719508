import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  padding: 1rem 2rem;
  min-height: ${window.innerHeight};

  header {
    padding-bottom: 1rem;
    border-bottom: 1px solid #d3d3d3;

    h1 {
      font-weight: 800;
      color: rgba(0, 0, 0, 0.8);
    }

    p {
      color: rgba(0, 0, 0, 0.7);
      font-weight: 600;
    }
  }

  content {
    .div__part1 {
      form {
        display: flex;
        justify-content: center;
        align-items: center;

        .autoComplete {
          max-width: 350px;
          margin: 2rem;
        }
        .searchButton {
          border-radius: 5px;
          padding: 0.75rem 1rem;
          background-color: ${(props) => props.theme.colors.primaryDark};
          color: #fff;
          border: 0px solid rgba(0, 0, 0, 0);
          margin: 0.5rem;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: 3rem;
        }
      }
    }

    .div__part2 {
      margin: 3rem auto;
      max-width: 1290px;
      overflow-x: scroll;
      .div__titleEventField {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      .div__infoEventField {
        display: flex;
        color: ${(props) => props.theme.colors.primaryDark};
        padding: 2rem 1rem;
        width: 100% !important;
        flex-direction: column;
        h1 {
          align-items: center;
          display: flex;
          font-size: 1.9rem;
          font-weight: 200;
          svg {
            font-size: 2rem;
            margin: 0rem 0.75rem;
          }
        }
        span {
          margin: 0rem 1rem;
          font-size: 1rem;
          color: var(--gray-500);
        }
      }
      .div__eventFieldSync {
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.75rem 1.25rem;
          border-radius: 2rem;
          border: 0;
        }
        .btn__syncData {
          background: var(--gray-100);
          color: ${(props) => props.theme.colors.primaryDark};
          :hover {
            color: var(--gray-100);
            background: ${(props) => props.theme.colors.primaryDark};
          }
        }

        .btn__syncData_play {
          background: var(--naples_yellow);
          color: var(--brown);
          min-width: 200px;
          :hover {
            color: var(--naples_yellow);
            background: var(--error);
          }
          svg {
            transition: 1s;
            animation: rotate 3s linear infinite;
            margin-right: 0.25rem;
          }
        }
      }

      @keyframes rotate {
        from {
          transform: rotate(359deg);
        }

        to {
          transform: rotate(0deg);
        }
      }

      .div__oneRow {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .div__oneColumn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: auto;
      }
      .columnGraf {
        width: 100%;
        position: relative;
        h2 {
          margin: auto;
          margin-left: 3rem;
          padding: 1rem;
          color: ${(props) => props.theme.colors.primaryDark};
          font-weight: 400;
        }
        .top_button {
          position: absolute;
          top: 1rem;
          right: 2rem;

          button {
            padding: 0.4rem 0.75rem;
            background-color: rgba(0, 0, 0, 0.1);
            color: gray;
            border: 0rem;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 1rem;

            :hover {
              background-color: ${(props) => props.theme.colors.primaryDark};
              color: var(--white);
              box-shadow: 0px 10px 10px rgba(var(--black_rgb), 0.25);
            }
          }
          :hover {
            transition: 0.2s;
            /* top: -0.2rem; */
          }
        }
      }

      .div__deck {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;

        .div__card {
          /* width: 19rem; */
          min-width: 23%;
          flex: 1;
          height: auto;
          box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
          border: 1px solid rgba(0, 0, 0, 0.05);
          border-radius: 1rem;
          padding: 1rem;
          display: flex;
          justify-content: space-between;

          svg {
            font-size: 5rem;
          }
          h3 {
            font-size: 1.1rem;
            margin-top: -0.75rem;
            margin-bottom: 0.25rem;
            color: rgba(0, 0, 0, 0.6);
          }
          p {
            font-size: 2.8rem;
            font-weight: 600;
          }
          span {
            font-size: 0.9rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
            margin-top: -0.75rem;
          }
          :hover {
            transition: 0.5s;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.3);
          }
        }
        /* opções de card  */
        .div__card[name='card1'] {
          color: ${(props) => props.theme.colors.primaryDark};
        }
        .div__card[name='card2'] {
          color: var(--success);
        }
        .div__card[name='card3'] {
          color: var(--error);
        }
        .div__card[name='card1 cardColumn'] {
          color: ${(props) => props.theme.colors.primaryDark};
          width: 100%;
        }

        .div__card[name='card1 cardColumn lateralCol'] {
          color: ${(props) => props.theme.colors.primaryDark};
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2) !important;

          :hover {
            transition: 0.5s;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3) !important;
          }
        }
      }
    }
  }

  .fundo_black {
    background: rgba(0, 0, 0, 0.5);
    color: #fff;

    position: fixed;
    z-index: 999999999999;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    min-width: ${window.innerWidth}px;
    min-height: ${window.innerHeight}px;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
`;

export const Popmenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.3rem;
  gap: 0.25rem;
  button {
    padding: 0.5rem 0.5rem;
    background: var(--white);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    color: ${(props) => props.theme.colors.primaryDark};
    border-radius: 0.5rem;
    cursor: pointer;
  }

  .destaque {
    background-color: ${(props) => props.theme.colors.primaryDark};
    color: var(--white) !important;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    input,
    select {
      padding: 0.5rem 0.5rem;
      background: var(--white);
      border: 1px solid rgba(var(--black_rgb), 0.1);
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.2);
      color: ${(props) => props.theme.colors.primaryDark};
      border-radius: 0.5rem;
      cursor: pointer;
      outline-color: ${(props) => props.theme.colors.primaryDark};
    }
  }
`;
