import React, { useEffect, useState, useCallback } from 'react';
import { Container } from './styles';
import api from '../../services/api';

// components

// icons
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';

// libs
import NovoVinculoApoiador from '../NovoVinculoApoiador';
import Dialogodeconfirmacao from '../Dialogodeconfirmacao';

function DetalhesEvento({ program, user, event }) {
  // let { id } = useParams();

  const [update, setUpdate] = useState(false);
  const [active, setActive] = useState(false);
  const [confirmacao, setConfirmacao] = useState([false, '']);
  const [novosapoiadores, setNovosapoiadores] = useState([]);
  const [dadosEditar, setDadosEditar] = useState([]);

  const [dialogoProgramacao, setDialogoProgramacao] = React.useState({
    status: false,
    editar: false,
    dados: '',
  });

  const [dados, setDados] = useState([]);

  const cancelarAtualizazao = () => {
    setUpdate(false);
    setDadosEditar([]);
    enviarForm();
  };

  const enviarForm = useCallback(async () => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'multipart/form-data',
      },
    };

    await api
      .get(`/related-support-event/${event.id}`, config)
      .then((a) => {
        setDados(a.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, [event.id, user.token]);

  const submeterAtualizacao = async (e) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + user.token,
        // 'Content-Type': 'multipart/form-data',
      },
    };

    novosapoiadores.map((b, j) =>
      api
        .post(
          `/related-support-event/`,
          { event_id: event.id, support_id: b.id },
          config
        )
        .then((a) => {
          enviarForm();
          // console.log(a.data);
        })
        .catch((error) => {
          console.log(error.response);
        })
    );
    // await
  };

  const deletarCampo = (e) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    };

    api
      .delete(`/related-support-event/${e.id}`, config)
      .then((a) => {
        enviarForm();
        dadosEditar.splice(e, 1);
        // console.log(' RETORNO, ', a.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    enviarForm();
  }, [enviarForm]);

  return (
    <Container update={update} active={active}>
      <div className="header">
        <div
          className="left"
          onClick={() => (update ? '' : setActive(!active))}
        >
          <span className="icon">&#10148;</span>
          <h2 className="title">Apoiadores</h2>
        </div>

        <div className="button">
          {update ? (
            <>
              <button
                className="salvarinfo"
                onClick={() => {
                  setUpdate(!update);
                  // atualizarDados();
                  submeterAtualizacao();
                }}
              >
                Salvar informações
              </button>
              <button className="cancel" onClick={() => cancelarAtualizazao()}>
                Cancelar
              </button>
            </>
          ) : (
            <button
              className="cancel"
              onClick={() => {
                setDadosEditar(dados);
                setUpdate(true);
                setActive(true);
              }}
            >
              Editar
            </button>
          )}
        </div>
      </div>
      <div className="content">
        <div className="a">
          <div className="campoDeEdicao">
            <div className="header2">
              {update && (
                <button
                  className="cadastroManual"
                  onClick={() =>
                    setDialogoProgramacao({
                      status: true,
                      editar: false,
                      dados: dadosEditar,
                    })
                  }
                >
                  {' '}
                  + Vincular
                </button>
              )}
            </div>
            <br />
            <div className="ul">
              {update ? (
                dadosEditar.length > 0 ? (
                  dadosEditar.map((a, i) => (
                    <div className="li">
                      <div className="hora" style={{ flex: 1 }}>
                        <img
                          src={
                            a.image_url ? a.image_url : a.objSupport.image_url
                          }
                          alt="imagem"
                        />
                      </div>
                      <div className="descr" style={{ flex: 9 }}>
                        <div>
                          <label>{a.name ? a.name : a.objSupport.name}</label>
                        </div>
                        <div>
                          {(
                            a.link
                              ? a.link
                              : a.objSupport
                              ? a.objSupport.link
                              : ''
                          ) ? (
                            <a
                              href={
                                a.link
                                  ? a.link
                                  : a.objSupport
                                  ? a.objSupport.link
                                  : ''
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <LinkIcon />{' '}
                              {a.link
                                ? a.link
                                : a.objSupport
                                ? a.objSupport.link
                                : ''}
                            </a>
                          ) : (
                            <p>Não possui link</p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <h3>Nenhum apoiador vinculado !</h3>
                )
              ) : dados.length > 0 ? (
                dados.map((a, i) => (
                  <div className="li">
                    <div className="hora" style={{ flex: 1 }}>
                      <img
                        src={a.image_url ? a.image_url : a.objSupport.image_url}
                        alt="imagem_Support"
                      />
                    </div>
                    <div className="descr" style={{ flex: 9 }}>
                      <div>
                        <label>{a.name ? a.name : a.objSupport.name}</label>
                      </div>
                      <div>
                        {(
                          a.link
                            ? a.link
                            : a.objSupport
                            ? a.objSupport.link
                            : ''
                        ) ? (
                          <a
                            href={
                              a.link
                                ? a.link
                                : a.objSupport
                                ? a.objSupport.link
                                : ''
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <LinkIcon />{' '}
                            {a.link
                              ? a.link
                              : a.objSupport
                              ? a.objSupport.link
                              : ''}
                          </a>
                        ) : (
                          <p>Não possui link</p>
                        )}
                      </div>
                    </div>
                    <div className="campoMenu">
                      <IconButton
                        className="botoes btndel"
                        onClick={() => setConfirmacao([true, a])}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                ))
              ) : (
                <h3>Nenhum apoiador vinculado !</h3>
              )}
            </div>
          </div>

          <form onSubmit=""></form>
        </div>
      </div>

      {dialogoProgramacao.status ? (
        <NovoVinculoApoiador
          user={user}
          editar={(e) => setDadosEditar(e)}
          novos={(e) => {
            var aux = novosapoiadores;
            aux.push(e);
            setNovosapoiadores(aux);
          }}
          dados={dadosEditar}
          tamanho="xs"
          negar={() =>
            setDialogoProgramacao({ status: false, editar: false, dados: '' })
          }
        />
      ) : (
        ''
      )}
      {confirmacao[0] ? (
        <Dialogodeconfirmacao
          tamanho="xs"
          message="Tem certeza de que deseja retirar o apoiador deste evento?"
          confirmar={() => {
            deletarCampo(confirmacao[1]);
            setConfirmacao([false, '']);
          }}
          negar={() => setConfirmacao([false, ''])}
        />
      ) : (
        ''
      )}
    </Container>
  );
}

export default DetalhesEvento;
