import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';

import { DropzoneArea } from 'material-ui-dropzone';
import { Container } from './styles';

// diagolo confirm
import Dialogodeconfirmacao from '../Dialogodeconfirmacao';

import api from '../../services/api';
// tema
import { useTheme } from 'styled-components';
import imageUrl from '../../utils/imageUrl';
import { toast } from 'react-toastify';
import {
  EDIT_BANNER,
  POST_NEW_BANNER,
  DELETE_BANNER,
} from '../../services/endpoints';
import Backdrop from '../Backdrop';

export default function Novobanner(props) {
  console.log('[DADOS]=>', props);
  const theme = useTheme();
  const [open] = useState(true);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState(props.tamanho);
  const [backdrop, setBackdrop] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [confirmDelete, setConfirmDelete] = useState([false, '']);

  const [dados, setDados] = React.useState({
    id: props.editar ? props.data.id : '',
    event_id: props.editar ? props.data.event_id : props.id,
    link: props.editar ? props.data.link : '',
    image_web: '',
    image_mobile: '',
    show_mobile_img: props.editar && props.data.image_mobile ? true : false,
  });

  const [files, setFiles] = React.useState([]);
  function handleChangeImageWeb(file) {
    setDados({
      ...dados,
      image_web: file[0],
    });
  }
  function handleChangeImageMobile(file) {
    setDados({
      ...dados,
      image_mobile: file[0],
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (!props.editar && !dados.image_web)
      return toast.warn('Anexar banner é obrigatório');
    if (!props.editar && dados.show_mobile_img && !dados.image_mobile)
      return toast.warn(
        'Anexar banner para mobile é obrigatório. Caso não queira anexar, desmarque o campo indicado'
      );

    if (props.editar) {
      EDIT_BANNER(
        {
          ...dados,
          removeMobile:
            props?.data?.image_mobile && !dados?.show_mobile_img ? true : false,
        },
        props.success,
        setBackdrop,
        props.user.token
      );
    } else {
      POST_NEW_BANNER(dados, props.success, setBackdrop, props.user.token);
    }
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        scroll={'body'}
        aria-labelledby="max-width-dialog-title"
      >
        <Container>
          <form onSubmit={handleSubmit}>
            <DialogTitle id="max-width-dialog-title">
              {props.editar ? 'Editar Banner' : '+ Novo Banner'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div className="subtitulo">
                  Preencha corretamente os campos abaixo para{' '}
                  {props.editar ? 'editar o' : 'adicionar novo'} banner.
                </div>
                <div className="formulario">
                  <p style={{ fontSize: '16px' }}>{props.message}</p>
                  <br />
                  <h3>
                    {props.editar ? 'Editar' : 'Adicionar'} o banner principal *
                  </h3>
                  <DropzoneArea
                    required
                    onChange={handleChangeImageWeb}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                    filesLimit={'1'}
                    dropzoneText="Arraste a imagem para este campo ou clique aqui para buscar"
                    maxFileSize="6000000"
                    getFileAddedMessage={(fileName) =>
                      `Arquivo ${fileName} adicionado com sucesso.`
                    }
                    getFileRemovedMessage={(fileName) =>
                      `Arquivo ${fileName} removido.`
                    }
                    getFileLimitExceedMessage={(filesLimit) =>
                      `Número máximo de ${filesLimit} arquivos excedido.`
                    }
                    getDropRejectMessage={() =>
                      `Tipo de arquivo não permitido.`
                    }
                  />
                  {props.editar && props.data.image_web && (
                    <div className="imagematual">
                      <h3>Imagem atual</h3>
                      <img
                        src={imageUrl(props.data.image_web)}
                        alt="image_web_selected"
                        loading="lazy"
                      />
                    </div>
                  )}
                  <TextField
                    id="standard-basic"
                    className="campo"
                    label="Link para a imagem (Opcional)"
                    type="url"
                    onChange={(e) =>
                      setDados({ ...dados, link: e.target.value })
                    }
                    value={dados.link}
                  />
                  <br />
                  Agora você também tem opção de adicionar uma imagem
                  alternativa para a visualização do banner na versão mobile de
                  forma com que o usuário tenha melhor experiência ao visualizar
                  as informações.
                  <br />
                  <br />
                  <label
                    className={
                      dados.show_mobile_img
                        ? 'chkbox__checked chkbox__img_mobile'
                        : 'chkbox__img_mobile'
                    }
                  >
                    <input
                      type="checkbox"
                      checked={dados.show_mobile_img}
                      onChange={() =>
                        setDados({
                          ...dados,
                          show_mobile_img: !dados.show_mobile_img,
                        })
                      }
                    />{' '}
                    Incluir imagem mobile
                  </label>
                  <br />
                  {dados.show_mobile_img && (
                    <h3>
                      {' '}
                      {props.editar ? 'Editar' : 'Adicionar'} o banner para
                      mobile *
                    </h3>
                  )}
                  {dados.show_mobile_img && (
                    <DropzoneArea
                      required
                      onChange={handleChangeImageMobile}
                      acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                      filesLimit={'1'}
                      dropzoneText="Arraste a imagem para este campo ou clique aqui para buscar"
                      maxFileSize="6000000"
                      getFileAddedMessage={(fileName) =>
                        `Arquivo ${fileName} adicionado com sucesso.`
                      }
                      getFileRemovedMessage={(fileName) =>
                        `Arquivo ${fileName} removido.`
                      }
                      getFileLimitExceedMessage={(filesLimit) =>
                        `Número máximo de ${filesLimit} arquivos excedido.`
                      }
                      getDropRejectMessage={() =>
                        `Tipo de arquivo não permitido.`
                      }
                    />
                  )}
                  {props.editar &&
                    dados.show_mobile_img &&
                    props.data.image_mobile && (
                      <div className="imagematual">
                        <h3>Imagem Mobile atual</h3>
                        <img
                          src={imageUrl(props.data.image_mobile)}
                          alt="image_mobile_selected"
                          loading="lazy"
                          style={{
                            width: '75% !important',
                            margin: 'auto',
                            maxWidth: '200px',
                          }}
                        />
                      </div>
                    )}
                  {props.editar && (
                    <div className="div__delede_field">
                      <span>Deseja deletar este banner? </span>
                      <button
                        type="button"
                        onClick={() => setConfirmDelete([true, props.data])}
                      >
                        Deletar
                      </button>
                    </div>
                  )}
                </div>
              </DialogContentText>
            </DialogContent>

            {messageError && (
              <Alert
                style={{ margin: '1rem 2rem' }}
                variant="outlined"
                severity="error"
              >
                {messageError.replace('!', '')}. Corrija e tente novamente.
              </Alert>
            )}

            <DialogActions>
              <Button
                onClick={() => props.close()}
                color=""
                variant="contained"
              >
                Cancelar
              </Button>
              <Button
                onClick={() => true}
                style={{
                  backgroundColor: theme.colors.primaryDark,
                  color: '#fff',
                }}
                variant="contained"
                type="submit"
              >
                {props.editar ? 'Editar' : 'Adicionar'}
              </Button>
            </DialogActions>
          </form>
        </Container>

        {confirmDelete[0] ? (
          <Dialogodeconfirmacao
            tamanho="xs"
            message="Tem certeza de que deseja apagar este banner?"
            confirmar={() => {
              DELETE_BANNER(
                confirmDelete[1],
                props.success,
                setBackdrop,
                props.user.token
              );
            }}
            negar={() => setConfirmDelete([false, ''])}
          />
        ) : (
          ''
        )}

        {backdrop && <Backdrop />}
      </Dialog>
    </React.Fragment>
  );
}
