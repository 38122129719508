import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';

import { Container } from './styles';

// tema
import { useTheme } from 'styled-components';

import { mask } from 'remask';
// editor de texto
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import api from '../../services/api';

export default function MaxWidthDialog(props) {
  const theme = useTheme();
  const [open] = React.useState(true);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState(props.tamanho);
  const [messageError, setMessageError] = React.useState('');

  const [dados, setDados] = React.useState({
    name: props.editar ? props.dados.name : '',
    cpf: props.editar ? props.dados.cpf : '',
  });

  const enviarForm = async (type) => {
    type.preventDefault();

    let data = {
      name: dados.name,
      cpf: dados.cpf,
    };

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    props.editar
      ? await api
          .put(`/notify-me/${props.dados.id}`, data, config)
          .then((a) => {
            // console.log(a.data);

            // handleClose();
            setMessageError('');
            props.atualizar();
            //setLoad(false);
          })
          .catch((error) => {
            console.log(error.response);
            const message = error.response.data.messages
              ? error.response.data.messages[0].message
              : error.response.data.message;
            setMessageError(message);
          })
      : await api
          .post('/notify-me', data, config)
          .then((a) => {
            // console.log(a.data);

            // handleClose();
            setMessageError('');
            props.atualizar();
            //setLoad(false);
          })
          .catch((error) => {
            console.log(error.response);
            console.log(error.response);
            const message = error.response.data.messages
              ? error.response.data.messages[0].message
              : error.response.data.message;
            setMessageError(message);
          });
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        scroll={'body'}
        //onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <Container>
          <form onSubmit={enviarForm}>
            <DialogTitle id="max-width-dialog-title">
              {props.editar
                ? 'Editar notificação de inscrito'
                : '+ Novo inscrito para notificar'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div className="subtitulo">
                  Preencha corretamente os campos abaixo para{' '}
                  {props.editar ? 'editar' : 'adicionar'} inscrito.
                </div>
                <div className="formulario">
                  <p style={{ fontSize: '16px' }}>{props.message}</p>
                  <TextField
                    required
                    id="standard-basic"
                    className="campo"
                    label="Nome"
                    onChange={(e) =>
                      setDados({ ...dados, name: e.target.value })
                    }
                    value={dados.name}
                  />

                  <TextField
                    required
                    id="standard-basic"
                    className="campo"
                    label="CPF"
                    onChange={(e) =>
                      setDados({
                        ...dados,
                        cpf: mask(e.target.value, ['999.999.999-99']),
                      })
                    }
                    value={dados.cpf}
                  />
                </div>
              </DialogContentText>
            </DialogContent>

            {messageError && (
              <Alert
                style={{ margin: '1rem 2rem' }}
                variant="outlined"
                severity="error"
              >
                {messageError.replace('!', '')}. Corrija e tente novamente.
              </Alert>
            )}

            <DialogActions>
              <Button
                onClick={() => props.negar()}
                color=""
                variant="contained"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                style={{
                  backgroundColor: theme.colors.primaryDark,
                  color: '#fff',
                }}
                variant="contained"
              >
                {props.editar ? 'Editar' : 'Adicionar'}
              </Button>
            </DialogActions>
          </form>
        </Container>
      </Dialog>
    </React.Fragment>
  );
}
