import styled from 'styled-components';

export const Container = styled.div`
  .header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding: 0.1rem 0.5rem;

    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    .left {
      display: flex;

      .icon {
        transform: ${(props) =>
          props.active
            ? 'rotate(90deg) translateX(-20%) translateY(+10%)'
            : ''};
        transition: 0.3s;
        transition-timing-function: ease-in;
      }

      span {
        font-size: 1.5rem;
        margin-right: 1rem;
      }
    }
  }

  .content {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    .a {
      display: flex;
    }

    display: ${(props) => (props.active ? 'flex' : 'none')};

    table {
      flex: 1;
      margin: 0.5rem 2.25rem;
      border-collapse: collapse;
      width: 100%;

      tbody {
        font-size: 1rem;
        width: 100%;
        tr {
          th {
            padding: 0.25rem 0.5rem;
            text-align: right;
            padding-right: 2rem;
            /* display:none; */
          }

          td {
            /* width: 100%; */
            input {
              margin: 0.5rem;
              padding: 0.4rem 0.75rem;
              /* border: 1px solid rgba(0, 0, 0, 0.5); */
              border-radius: 0.25rem;
            }

            select {
              margin: 0.5rem;
              padding: 0.4rem 0.75rem;
              background: #fff;

              border: 1px solid rgba(0, 0, 0, 0.5);
              border-radius: 0.25rem;
            }

            .checkboxContainer {
              margin-top: 0.5rem;

              display: flex;
              justify-content: flex-end;
              align-items: center;
            }

            .paiCheckboxContainer {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }
        }
      }
    }

    .content__image {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        max-width: 500px;
        height: 200px;
        border-radius: 5px;
      }

      button {
        margin-top: 0.25rem;
        width: 200px;

        padding: 0.25rem 0.5rem;
      }
    }

    .content__description {
      padding: 0 3rem;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 1.5rem;
      }

      p {
        font-size: 1rem;
        text-align: justify;
      }
    }
  }

  .button {
    display: flex;
    justify-content: flex-end;

    .salvarinfo {
      padding: 0.8rem 1rem;
      background: ${(props) => props.theme.colors.primaryDark};
      border: 0;
      outline: none;
      margin-bottom: -0.2rem;

      color: #fff;
      font-size: 1rem;
      border-radius: 5px 5px 0rem 0rem !important;
    }
    .cancel {
      padding: 0.8rem 1rem;
      margin-bottom: -0.2rem;
      border-radius: 5px 5px 0rem 0rem !important;
      border: 0;
      outline: none;
      background: rgba(0, 0, 0, 0.1);
      color: #3f51b5;
      text-decoration: none;
      margin-left: 1rem;

      font-size: 1rem;
      font-weight: 500;
    }
  }

  input[type='file'] {
    display: none;
  }
  .campodemandarimagem {
    margin: 0.5rem 0rem;
    background-color: #e5e5e5;
    padding: 0.5rem;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 0.5rem;
    }
  }
`;
