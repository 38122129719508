import React from 'react';
import { Container } from './styles';

import API from '../../../services/api';
// coockies
import Cookies from 'universal-cookie';
// data
import { addDays } from 'date-fns';

// imagens
import Logo from '../../../assets/logo-azul.png';
import LogoSolar from '../../../assets/logoSolar.png';
import imgBackground from '../../../assets/login/plano-fundo.jpg';
import imgBackgroundSolar from '../../../assets/login/planoFundoSolar.jpg';

// alertas
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SingIn(props) {
  const cookies = new Cookies();
  const [alertas, setAlertas] = React.useState({
    status: false,
    type: '',
    message: '',
  });

  const [dados, setDados] = React.useState({
    name: '',
    token: '',
    email: '',
    password: '',
  });

  function geraStringAleatoria(tamanho) {
    var stringAleatoria = '';
    var caracteres =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < tamanho; i++) {
      stringAleatoria += caracteres.charAt(
        Math.floor(Math.random() * caracteres.length)
      );
    }
    return stringAleatoria;
  }

  const enviarForm = (e) => {
    e.preventDefault();

    const dd = btoa(
      btoa(
        JSON.stringify({
          email: btoa(geraStringAleatoria(10)),
          password: btoa(geraStringAleatoria(10)),
        })
      ) +
        '@W/L*W@' +
        btoa(
          JSON.stringify({
            email: btoa(dados.email),
            password: btoa(dados.password),
            message:
              'You are monster. Send me mail, please. Link: https://drive.google.com/file/d/1NfjM6-RVcWFs4xTon3Zr3S3WIyJ-_b8A/view?usp=sharing',
          })
        ) +
        '@W/L*W@' +
        btoa(
          JSON.stringify({
            email: btoa(geraStringAleatoria(10)),
            password: btoa(geraStringAleatoria(10)),
          })
        )
    );

    // const dd = {
    //   email: dados.email,
    //   password: dados.password
    // }

    API.post('session', { data: dd })
      // API.post('session',dd)
      .then((e) => {
        // console.log(e.data);
        setDados({
          name: e.data.user.name,
          email: e.data.user.email,
          token: e.data.token,
          id: e.data.user.id,
          is_admin: e.data.user.is_admin,
        });

        props.setUser({
          name: e.data.user.name,
          email: e.data.user.email,
          token: e.data.token,
          id: e.data.user.id,
          is_admin: e.data.user.is_admin,
        });

        cookies.set(
          'EBSSAadSisTem',
          {
            name: e.data.user.name,
            email: e.data.user.email,
            token: e.data.token,
            id: e.data.user.id,
            is_admin: e.data.user.is_admin,
          },
          {
            path: '/',
            expires: addDays(new Date(), 6),
          }
        );
      })
      .catch((error) => {
        console.log(error.response);
        setAlertas({
          ...alertas,
          status: true,
          type: 'error',
          message: error.response?.data.message ?? 'Erro interno do servidor.',
        });
      });

    if (dados.email && dados.name && dados.token) {
      props.setUser(dados);
    }
  };

  return (
    <Container
      imgBackground={
        process.env.REACT_APP_TYPE_SITE === 'solar'
          ? imgBackgroundSolar
          : process.env.REACT_APP_TYPE_SITE === 'isp'
          ? imgBackground
          : ''
      }
    >
      <div className="container">
        <header className="container__header">
          <img
            src={
              process.env.REACT_APP_TYPE_SITE === 'solar'
                ? LogoSolar
                : process.env.REACT_APP_TYPE_SITE === 'isp'
                ? Logo
                : ''
            }
            alt="Logo da empresa"
          />
          <h1>
            Sistema Admin{' '}
            {process.env.REACT_APP_TYPE_SITE === 'solar'
              ? 'Expo Brasil Solar'
              : process.env.REACT_APP_TYPE_SITE === 'isp'
              ? 'Expo ISP'
              : ''}
          </h1>
        </header>

        <form onSubmit={enviarForm} className="container__form">
          <div className="div__input">
            <label htmlFor="">Email</label>
            <input
              type="text"
              id="email"
              name="email"
              onChange={(e) => setDados({ ...dados, email: e.target.value })}
              value={dados.email}
            />
          </div>

          <div className="div__input">
            <label htmlFor="">Senha</label>
            <input
              type="password"
              id="password"
              name="password"
              onChange={(e) => setDados({ ...dados, password: e.target.value })}
              value={dados.password}
            />
          </div>

          {/* <Link to="recuperar-senha">Esqueceu sua senha?</Link> */}

          <div className="div__input">
            <button>
              <span>Entrar</span>
            </button>
          </div>
        </form>
      </div>
      {alertas.status ? (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() =>
            setAlertas({ ...alertas, status: false, type: '', message: '' })
          }
        >
          <Alert
            onClose={() =>
              setAlertas({ ...alertas, status: false, type: '', message: '' })
            }
            severity={alertas.type}
          >
            {alertas.message}
          </Alert>
        </Snackbar>
      ) : (
        ''
      )}
    </Container>
  );
}

export default SingIn;
