import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';

// api
import API from '../../services/api';
import { Container } from './styles';

export default function MaxWidthDialog(props) {
  const [open, setOpen] = React.useState(true);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState(props.tamanho);

  const [dados, setDados] = React.useState({
    id: props.dados ? props.dados.id : '',
    password: '',
  });

  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleClose = () => {
    setOpen(false);
    props.negar();
  };

  const enviarNovo = (e) => {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    API.put(`/users/change-password/${dados.id}`, dados, config)
      .then((a) => {
        props.alerta({
          open: true,
          status: 'success',
          message: 'Senha do usuário alterada com sucesso.',
        });
        props.negar();
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        scroll={'body'}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <Container>
          <form onSubmit={enviarNovo}>
            <DialogTitle id="max-width-dialog-title">
              Atualizar senha
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div className="subtitulo">
                  Preencha corretamente os campos abaixo para trocar a senha.
                </div>
                <div className="formulario">
                  <br />
                  <br />
                  <FormControl className={'campo'} required>
                    <InputLabel htmlFor="standard-adornment-password" required>
                      Nova Senha
                    </InputLabel>
                    <Input
                      id="standard-adornment-password"
                      type={values.showPassword ? 'text' : 'password'}
                      value={dados.password}
                      required
                      onChange={(e) =>
                        setDados({ ...dados, password: e.target.value })
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setValues({
                                ...values,
                                showPassword: !values.showPassword,
                              })
                            }
                            // onMouseDown={()=> setValues({...values, showPassword: !values.showPassword})}
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <br />
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => props.negar()}
                color=""
                variant="contained"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                onClick={''}
                style={{ backgroundColor: 'var(--primary)', color: '#fff' }}
                variant="contained"
              >
                Substituir Senha
              </Button>
            </DialogActions>
          </form>
        </Container>
      </Dialog>
    </React.Fragment>
  );
}
