import React from 'react';
import ReactDOM from 'react-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import App from './App';

import { EtiquetaProvider } from './context/EtiquetaContexto';
import { ThemeProvider } from 'styled-components';

// temas
import { expoispTheme } from './styles/themes/expoisp';
import { exposolarTheme } from './styles/themes/exposolar';

ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <EtiquetaProvider>
        <ThemeProvider
          theme={
            process.env.REACT_APP_TYPE_SITE === 'solar'
              ? exposolarTheme
              : process.env.REACT_APP_TYPE_SITE === 'isp'
              ? expoispTheme
              : ''
            // expoispTheme
          }
        >
          <App />
        </ThemeProvider>
      </EtiquetaProvider>
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById('root')
);
