export const exposolarTheme = {
  title: 'exposolar',

  colors: {
    primaryDark: '#f6921e',
    primaryDarkRgb: '246, 146, 30',
    primary: '#e4b00a',
    primaryRgb: '228, 176, 10',
    primaryLight: '#124b51',
    primaryLightRgb: '18, 75, 81',

    secondary: '#124b51',
    secondaryRgb: '18, 75, 81',

    // inversao de cores
    // primaryDark: "#124b51",
    // primaryDarkRgb: "18, 75, 81",
    // primary: "#17656E",
    // primaryRgb: "23, 101, 110",
    // primaryLight: "#f6921e",
    // primaryLightRgb: "246, 146, 30",

    // secondary: "#f6921e",
    // secondaryRgb: "246, 146, 30 ",

    success: '37, 211, 102',
    delete: '#f44336',
    deleteRgb: '231,76,60',

    white: '#fff',
    black: '#121214',
    blackRgb: '18, 18, 20',
  },
};
