import styled from 'styled-components';

export const Container = styled.div`
  .subtitulo {
    font-size: 16px;
  }

  .formulario {
    display: flex !important;
    flex-direction: column !important;

    .cidade-uf {
      display: flex !important;
      flex-direction: row !important;
      margin-top: 0.75rem;
    }

    .local-data {
      display: flex !important;
      flex-direction: row !important;
    }

    .campo,
    .apresentarHome {
      margin: 0.5rem 1rem;
    }

    .imagematual {
      text-align: center;
      h3 {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
        margin: 1rem 0rem;
        text-align: left;
      }
      img {
        margin: auto;
      }
    }
  }

  //Butão [adicionar evento]
  .Button {
    background-color: #245c9d;
    color: #fff;
    font-weight: bold;
  }
  .Button:hover {
    background-color: #245c9d;
    color: #fff;
  }

  //Butão Modal

  .modal .paper .buttonSave .buttonModal {
    .MuiButton-contained {
      background-color: #245c9d;
      color: #fff;
    }
  }

  .date-date {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    padding: 1rem 0;
    /* border-bottom: 1px solid gray; */
    /* border-radius: 0.2rem; */

    p {
      min-width: 10rem;
      margin-right: 1rem;
      font-size: 1.1rem;
    }

    .checkboxContainer {
      min-width: 12rem;
      display: flex;
      justify-content: flex-start;
      /* align-items: center; */
      padding: 0.5rem;

      input {
        margin: 0 0.5rem;
      }
    }

    .paiCheckboxContainer {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }

  .div__inColumn{
    display:flex;
    flex-direction: column;
    flex:0.2;
    padding:0.5rem 0rem;

    p{
      flex:1;
    }
  }

  .div__inLine{
    display:flex;
    flex-direction: row;
    flex:1;
    padding:0.5rem 0rem;

    p{
      flex:0.8;
    }
  }
  .date-date-date {
    display: flex;
    flex:1;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;

  
  }
`;
