import React, { useContext, useEffect } from 'react';

import { Container } from './styles';

import DetalhesEnviarPesquisa from '../../../components/DetalhesEnviarPesquisa';

import DetalhesEvento from '../../../components/DetalhesEvento';
import DetalhesProgramacao from '../../../components/DetalhesProgramacao';
import Detalhespatrocinadores from '../../../components/Detalhespatrocinadores';
import DetalhesApoiadores from '../../../components/DetalhesApoiadores';
import DetalhesExpositores from '../../../components/DetalhesExpositores';
import DetalhesFotos from '../../../components/DetalhesFotos';
import { EdicaoEvento } from '../../../context/edicaoEvento';
import DetalhesAuditorio from '../../../components/DetalhesAuditorio';
import DetalhesBanners from '../../../components/DetalhesBanners';

function EditarEvento(props) {
  const { dados, controle, getData } = useContext(EdicaoEvento);
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Container style={{ margin: '1rem 1rem' }}>
      {dados ? (
        <div>
          <h1>
            {dados && controle ? (
              <>
                Edição do evento {dados.event?.city} - {dados.event?.state}{' '}
                <div className={dados.event?.is_published ? 'publi' : 'rascu'}>
                  {dados.event?.is_published ? 'Publicado' : 'Em rascunho'}
                </div>{' '}
              </>
            ) : (
              ''
            )}
          </h1>
        </div>
      ) : (
        ''
      )}

      <div className="">
        {dados && controle ? (
          <DetalhesEvento
            event={dados.event}
            user={props.user}
            atualizar={() => getData()}
          />
        ) : (
          ''
        )}
      </div>

      {dados && controle && dados?.event?.is_national_event && (
        <DetalhesBanners user={props.user} />
      )}

      {dados && controle && dados?.event?.is_national_event && (
        <DetalhesAuditorio
          event={dados.event}
          program={dados.arrayShedules}
          user={props.user}
          atualizar={() => getData()}
        />
      )}

      {dados && controle ? (
        <DetalhesProgramacao
          event={dados.event}
          program={dados.arrayShedules}
          user={props.user}
          atualizar={() => getData()}
        />
      ) : (
        ''
      )}

      {dados && controle ? (
        <Detalhespatrocinadores
          event={dados.event}
          program={dados.arrayShedules}
          user={props.user}
          atualizar={() => getData()}
        />
      ) : (
        ''
      )}

      {dados && controle ? (
        <DetalhesApoiadores
          user={props.user}
          event={dados.event}
          atualizar={() => getData()}
        />
      ) : (
        ''
      )}
      {dados && controle ? (
        <DetalhesExpositores
          program={dados.arrayShedules}
          user={props.user}
          atualizar={() => getData()}
        />
      ) : (
        ''
      )}

      {dados && controle ? (
        <DetalhesFotos
          /* program={dados.arrayShedules} */
          user={props.user}
          event={dados.event}
          atualizar={() => getData()}
        />
      ) : (
        ''
      )}

      {/* {dados && controle ? (
        <DetalhesGanhadores
          user={props.user}
          atualizar={() => getData()}
        />
      ) : (
        ''
      )} */}

      {dados && controle ? (
        <DetalhesEnviarPesquisa
          event={dados.event}
          user={props.user}
          atualizar={() => getData()}
        />
      ) : (
        ''
      )}

      {/* <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
          className={classes.subAreas}
        >
          <div className="">
            <h2>Dados do evento</h2>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.containerEdit}>
          <div>
            <p>Data de Evento: {event.start_date}</p>
            <p>Endereço: {event.address}</p>
            <p>Cidade: {event.city}</p>
            <p>Estado: {event.state}</p>
            <p>Local: {event.place}</p>
            <p>Data de término: {event.end_date}</p>
            <p>Descrição: {event.description}</p>
            <p>imagem: {event.image_description}</p>
          </div>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" color="primary">
            Editar
          </Button>
        </AccordionActions>
      </Accordion> */}
      {/* </div> */}
      {/* {loading && <Backdrop />} */}
    </Container>
  );
}

export default EditarEvento;
