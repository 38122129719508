import React from 'react';

import { Container } from './styles';

// mascara
import format from 'date-fns/format';
import JsBarcode from 'jsbarcode';

import PrintIcon from '@material-ui/icons/Print';
import Alert2 from '@material-ui/lab/Alert';

// mascaras
import MaskedInput from 'react-text-mask';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import Divider from '@material-ui/core/Divider';
import Backdrop from '../../../components/Backdrop';

// api
import API from '../../../services/api';

// icones
import SearchIcon from '@material-ui/icons/Search';

// alertas
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { EtiquetaContexto } from '../../../context/EtiquetaContexto';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function CPFMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
    />
  );
}

function InscricaoMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      // showMask
      // required
    />
  );
}

function Etiquetas(props) {
  const [dadopesquisa, setDadopesquisa] = React.useState({
    value: '',
  });
  const [filtro, setFiltro] = React.useState('inscricao');
  const [backdrop, setBackdrop] = React.useState(false);
  const [listagem, setListagem] = React.useState([]);
  const [dados, setDados] = React.useState();
  const [habimp, setHabimp] = React.useState(true);
  const [paraConfirmar, setParaConfirmar] = React.useState({
    event_id: '',
    id: '',
    type: '',
  });
  const [multiplos, setMultiplos] = React.useState({
    open: false,
    multi: [],
  });
  const [alertas, setAlertas] = React.useState({
    status: false,
    type: '',
    message: '',
  });

  const atualizarLista = async () => {
    setBackdrop(true);

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    await API.get(
      `enrollments/${filtro === 'inscricao' ? 'id' : 'cpf-list'}/${
        dadopesquisa.value
      }`,
      config,
    )
      .then(async (a) => {
        setListagem(a.data);

        gerarEtiqueta(a.data);
      })
      .catch((error) => {
        console.log(error.response);
      })
      .finally(() => setBackdrop(false));
  };

  const { setDadosEtiqueta } = React.useContext(EtiquetaContexto);

  const gerarEtiqueta = async (dataList) => {
    setHabimp(false);

    if (filtro === 'inscricao') {
      let dadopesquisa2 = dadopesquisa.value.replace(/\s/g, '');

      if (dadopesquisa2.length < 9) {
        setAlertas({
          ...alertas,
          status: true,
          type: 'warning',
          message: 'Preencha a inscrição corretamente',
        });
        setDados('');
      } else {
        if (dataList.length === 0) {
          setAlertas({
            ...alertas,
            status: true,
            type: 'error',
            message: 'Nenhuma inscrição encontrada',
          });
          setDados('');
        } else {
          setDados({
            category: dataList[0].category,
            credential_name: dataList[0].credential_name,
            credential_company: dataList[0].credential_company,
            credential_role: dataList[0].credential_role,
            another_type_inscription: dataList[0].another_type_inscription,
            type_inscription: dataList[0].type_inscription,
            event: `${dataList[0].event.city} - ${dataList[0].event.state}`,
            is_national_event: dataList[0].event.is_national_event,
          });

          setDadosEtiqueta({
            category: dataList[0].category,
            credential_name: dataList[0].credential_name,
            credential_company: dataList[0].credential_company,
            credential_role: dataList[0].credential_role,
            another_type_inscription: dataList[0].another_type_inscription,
            type_inscription: dataList[0].type_inscription,
          });

          setParaConfirmar({
            event_id: dataList[0].event_id,
            id: dataList[0].id,
            type: 'id',
          });

          setTimeout(() => {
            JsBarcode('#barcode1', dataList[0].id, {
              fontSize: 14,
              height: 25,
              width: '1.5cm',
              // background: "#0e0e0e",
              lineColor: '#000000',
              margin: 0,
              marginTop: 7,
            });
          }, 100);
        }
      }
    }

    if (filtro === 'cpf') {
      let dadopesquisa2 = dadopesquisa.value.replace(/\s/g, '');

      if (dadopesquisa2.length < 14) {
        setAlertas({
          ...alertas,
          status: true,
          type: 'warning',
          message: 'Preencha o CPF corretamente',
        });
        setDados('');
      } else {
        if (dataList.length === 0) {
          setAlertas({
            ...alertas,
            status: true,
            type: 'error',
            message: 'Nenhuma inscrição encontrada',
          });
          setDados('');
        } else {
          if (dataList.length > 1) {
            setMultiplos({ open: true, multi: dataList });
          } else {
            setDados({
              category: dataList[0].category,
              credential_name: dataList[0].credential_name,
              credential_company: dataList[0].credential_company,
              credential_role: dataList[0].credential_role,
              another_type_inscription: dataList[0].another_type_inscription,
              type_inscription: dataList[0].type_inscription,
              event: `${dataList[0].event.city} - ${dataList[0].event.state}`,
              is_national_event: dataList[0].event.is_national_event,
            });

            setDadosEtiqueta({
              category: dataList[0].category,
              credential_name: dataList[0].credential_name,
              credential_company: dataList[0].credential_company,
              credential_role: dataList[0].credential_role,
              another_type_inscription: dataList[0].another_type_inscription,
              type_inscription: dataList[0].type_inscription,
            });

            setParaConfirmar({
              event_id: dataList[0].event_id,
              id: dataList[0].cpf,
              type: 'cpf',
            });

            setTimeout(() => {
              JsBarcode('#barcode1', dataList[0].id, {
                fontSize: 14,
                height: 25,
                width: '1.5cm',
                // background: "#0e0e0e",
                lineColor: '#000000',
                margin: 0,
                marginTop: 7,
              });
            }, 100);
          }
        }
      }
    }
  };

  const confirmarPresenca = async (e) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
      },
    };

    await API.put(
      `/enrollments/${paraConfirmar.event_id}/credential/`,
      { id: paraConfirmar.id, type: paraConfirmar.type },
      config,
    )
      .then((a) => {
        setParaConfirmar({
          id: '',
          event_id: '',
          type: '',
        });
        // setInfoback([]);
      })
      .catch((error) => {
        console.log('ERRO: ', error);
        setParaConfirmar({
          id: '',
          event_id: '',
          type: '',
        });
      });
  };

  const novaImpressao = (e) => {
    window.print();
    setHabimp(true);

    setTimeout(() => {
      setDados({
        category: null,
        credential_name: '',
        credential_company: '',
        credential_role: '',
        another_type_inscription: '',
        type_inscription: '',
        event: '',
        is_national_event: '',
      });
    }, 5000);
  };

  const verificarNome = (e) => {
    let name;
    let aux;

    if (e.length > 15) {
      aux = e.split(' ');
      name = aux[0] + ' ' + aux[aux.length - 1];
      if (name.length > 15) {
        name = name.slice(0, 15) + '.';
      }
    } else {
      name = e;
    }
    return name;
  };

  function selectCredencial(value) {
    console.log('Category: ', value);
    // alert(`${value.category} ${value.is_national_event}`);
    if (process.env.REACT_APP_TYPE_SITE === 'solar') {
      if (!value.is_national_event) {
        return "url('./credenciais/expedicao/credencial.jpg') !important";
      } else if (
        value.category === 'VIP' &&
        process.env.REACT_APP_EVENT === 'events/pe'
      ) {
        return "url('./credenciais/olinda/solar/vip.png') !important";
      } else if (
        value.category === 'Normal' &&
        process.env.REACT_APP_EVENT === 'events/pe'
      ) {
        return "url('./credenciais/olinda/solar/visitante.png') !important";
      } else if (
        value.category === 'VIP' &&
        process.env.REACT_APP_EVENT === 'events/sp'
      ) {
        return "url('./credenciais/saopaulo/solar/vip.png') !important";
      } else if (
        value.category === 'Normal' &&
        process.env.REACT_APP_EVENT === 'events/sp'
      ) {
        return "url('./credenciais/saopaulo/solar/visitante.png') !important";
      }
    } else if (process.env.REACT_APP_TYPE_SITE === 'isp') {
      if (!value.is_national_event) {
        return "url('./credenciais/expedicao/credencial.jpg') !important";
      } else if (
        value.category === 'VIP' &&
        process.env.REACT_APP_EVENT === 'events/sp'
      ) {
        return "url('./credenciais/saopaulo/isp/vip.png') !important";
      } else if (
        value.category === 'Normal' &&
        process.env.REACT_APP_EVENT === 'events/sp'
      ) {
        return "url('./credenciais/saopaulo/isp/visitante.png') !important";
      } else if (
        value.category === 'VIP' &&
        process.env.REACT_APP_EVENT === 'events/pe'
      ) {
        return "url('./credenciais/olinda/isp/vip.png') !important";
      } else if (
        value.category === 'Normal' &&
        process.env.REACT_APP_EVENT === 'events/pe'
      ) {
        return "url('./credenciais/olinda/isp/visitante.png') !important";
      }
    }
  }

  return (
    <div>
      {/* <Container tipoCredential={dados ? dados.is_national_event : ''}> */}
      <Container tipoCredential={dados ? selectCredencial(dados) : ''}>
        <div className="paginaBranco">
          <header>
            <h1>Gerar Etiquetas</h1>
            <p>
              Neste campo é possível gerar etiquetas para credenciamento
              utilizando código de inscrição ou CPF.
            </p>
          </header>

          <br />
          <br />
          <div className="campoDeBusca">
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                await atualizarLista();
              }}
            >
              <FormControl
                variant=""
                margin="dense"
                className="typebusca"
                style={{
                  margin: '0rem 0.5rem',
                  marginLeft: '0rem',
                  width: '250px',
                }}
              >
                <Select
                  labelId="id-selctor-zone"
                  id="id-selctor-zone-select"
                  value={filtro}
                  onChange={(e) => {
                    setFiltro(e.target.value);
                    setDadopesquisa((dados) => ({ ...dados, value: '' }));
                    setMultiplos({ open: false, multi: [] });
                    setDados();
                    setHabimp(false);
                  }}
                  label="Tipo"
                  style={{ height: '40px' }}
                  margin="dense"
                >
                  {/* <MenuItem value="" default>
                          <em>Nenhum</em>
                      </MenuItem>                   */}
                  <MenuItem value={'inscricao'} default>
                    Inscrição
                  </MenuItem>
                  <MenuItem value={'cpf'}>CPF</MenuItem>
                </Select>
              </FormControl>
              <TextField
                required
                id="cnpj-campo"
                label="Inserir"
                value={dadopesquisa.value}
                onChange={(e) => {
                  setDadopesquisa((dados) => ({
                    ...dados,
                    value: e.target.value,
                  }));
                }} // onChange={e=>setDados({...dados, cpf:e.target.value})}
                InputProps={{
                  inputComponent:
                    filtro === 'inscricao'
                      ? InscricaoMaskCustom
                      : CPFMaskCustom,
                }}
                style={{
                  margin: '0rem 0.5rem',
                  marginTop: '-0.25rem',
                  width: '250px',
                }}
                margin="dense"
              />

              <button type="submit">
                <SearchIcon /> Buscar
              </button>
            </form>
          </div>
          <br />
          <br />
          <br />
          <Divider />

          {multiplos.open && (
            <Alert2
              style={{ margin: '1rem 2rem' }}
              variant="outlined"
              severity="warning"
            >
              Duas ou mais inscrições foram encontradas para este CPF, selecione
              abaixo a qual deseja:
              <br />
              <br />
              {multiplos.multi.map((a, i) => (
                <div
                  className="listagemDeInscricoes"
                  onClick={() => {
                    setDados({
                      category: listagem[i].category,
                      credential_name: listagem[i].credential_name,
                      credential_company: listagem[i].credential_company,
                      credential_role: listagem[i].credential_role,
                      another_type_inscription:
                        listagem[i].another_type_inscription,
                      type_inscription: listagem[i].type_inscription,
                      event: `${listagem[i].event.city} - ${listagem[i].event.state}`,
                      is_national_event: listagem[i].event.is_national_event,
                    });

                    setDadosEtiqueta({
                      category: listagem[i].category,
                      credential_name: listagem[i].credential_name,
                      credential_company: listagem[i].credential_company,
                      credential_role: listagem[i].credential_role,
                      another_type_inscription:
                        listagem[i].another_type_inscription,
                      type_inscription: listagem[i].type_inscription,
                    });

                    setParaConfirmar({
                      event_id: listagem[i].event_id,
                      id: listagem[i].cpf,
                      type: 'cpf',
                    });

                    setTimeout(() => {
                      JsBarcode('#barcode1', listagem[i].id, {
                        fontSize: 14,
                        height: 25,
                        width: '1.5cm',
                        // background: "#0e0e0e",
                        lineColor: '#000000',
                        margin: 0,
                        marginTop: 7,
                      });
                    }, 100);

                    setMultiplos({ open: false, multi: [] });
                  }}
                >
                  ▸{format(new Date(a.created_at), 'dd/MM/yyyy HH:mm')}{' '}
                  {listagem[i].event.city}-{listagem[i].event.state} (Nº {a.id})
                </div>
              ))}
            </Alert2>
          )}

          <div className="cardParaImpressao">
            {dados ? (
              <div className="comDados">
                <div className="div__campo_botoes">
                  <button
                    className="buttonImprimir"
                    onClick={(e) => novaImpressao()}
                    disabled={habimp}
                  >
                    {' '}
                    <PrintIcon /> Imprimir Para <br /> Teste
                  </button>
                  <button
                    className="buttonImprimir_presenca"
                    onClick={(e) => {
                      confirmarPresenca();
                      novaImpressao();
                    }}
                    disabled={habimp}
                  >
                    {' '}
                    <PrintIcon /> Imprimir e <br />
                    Contar Presença
                  </button>
                </div>
                {habimp && <div>Etiqueta impressa</div>}
                {!habimp && (
                  <div className="etiqueta">
                    <div className="informacoes" id="sua_div">
                      <p className="nomeCredencial">
                        {verificarNome(dados.credential_name)}
                      </p>
                      <p className="demaisCredencial">
                        {dados.credential_role}
                      </p>
                      <p className="demaisCredencial2">
                        {dados.credential_company}
                      </p>
                      <div className="div__cod_barra">
                        <svg id="barcode1"></svg>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </Container>
      {alertas.status ? (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() =>
            setAlertas({ ...alertas, status: false, type: '', message: '' })
          }
        >
          <Alert
            onClose={() =>
              setAlertas({ ...alertas, status: false, type: '', message: '' })
            }
            severity={alertas.type}
          >
            {alertas.message}
          </Alert>
        </Snackbar>
      ) : (
        ''
      )}
      {backdrop && <Backdrop />}
    </div>
  );
}

export default Etiquetas;
