import React, { useRef, useMemo, useCallback, useState } from 'react';

import './styles/global.css';

// React router dom
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

// context
import { EdicaoEventoProvider } from './context/edicaoEvento';
import { EtiquetaContexto } from './context/EtiquetaContexto';

// coockies
import Cookies from 'universal-cookie';
// data
import { addDays } from 'date-fns';

// icone
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';

// rota publica
import Login from './pages/public/Login';
//Tema principal
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

// notificações
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// api
import api from './services/api';

// rota privada
import Logado from './pages/private/Logado';
import Relatorios from './pages/private/Relatorios';
import Blog from './pages/private/Blog';
import Etiquetas from './pages/private/Etiquetas';
import EtiquetasSorteio from './pages/private/EtiquetasSorteio';
import Configuracoes from './pages/private/Configuracoes';
import Eventos from './pages/private/Eventos';
import EditarEvento from './pages/private/EditarEvento';
import Palestrante from './pages/private/Palestrante';
import Apoiadores from './pages/private/Apoiadores';
import Expositores from './pages/private/Expositores';
import Palestras from './pages/private/Palestras';
import Patrocinadores from './pages/private/Patrocinadores';
import Dashboard from './pages/private/Dashboard';
import Comparareventos from './pages/private/Comparareventos';
import Aviseme from './pages/private/Aviseme';
import Exportardados from './pages/private/Exportardados';
import Alterarmensagens from './pages/private/Alterarmensagens';

import { EventsContextProvider } from './context/eventsContext';

// temas
import { useTheme } from 'styled-components';

function App() {
  // tema
  const theme = useTheme();
  const [aberto, setAberto] = React.useState(true);
  const [backdrop, setBackdrop] = React.useState(true);
  const idinterval = useRef();
  const cookies = useMemo(() => new Cookies(), []);
  const [refreshCookie, setRefreshCookie] = useState(true);

  const [user, setUser] = React.useState({
    // name:'Werlley Gonçalves',
    // email:'werlley.registros@hotmail.com',
    // token:'121fe45ere5fse2',
    // id:'dfsdfassdf',
    name: '',
    email: '',
    token: '',
    id: '',
    is_admin: '',
  });

  const defaultMaterialTheme = createTheme({
    palette: {
      primary: {
        main: theme.colors.primaryDark,
      },
      secondary: {
        main: theme.colors.delete,
      },
    },
  });

  const handleUser = (e) => {
    // console.log('Dados login: ', e);
    cookies.set('EBSSAadSisTem', e, {
      path: '/',
      expires: addDays(new Date(), 6),
    });
    if (e.name && !user.name) regras(e);
  };

  const logOff = useCallback(() => {
    setUser({ name: '', token: '', email: '', id: '', is_admin: '' });
    cookies.set('EBSSAadSisTem', '', { path: '/' });
  }, [cookies]);

  const regras = async (e) => {
    setUser({
      name: e.name,
      token: e.token,
      email: e.email,
      id: e.id,
      is_admin: e.is_admin,
    });
    setBackdrop(false);
  };

  const callBackdrop = (e) => {
    setInterval(function () {
      setBackdrop(false);
    }, 1000);
  };

  // cancelar consulta periodica de dados do evento
  const cancelDataEventSync = () => {
    clearInterval(idinterval.current);
  };

  React.useEffect(() => {
    if (cookies.get('EBSSAadSisTem') && refreshCookie) {
      const e = cookies.get('EBSSAadSisTem');
      if (e.token && !user.name) {
        setBackdrop(true);
        const config = {
          headers: {
            Authorization: 'Bearer ' + e.token,
          },
        };

        api
          .get(`/token-user`, config)
          .then((response) => {
            if (response.data.token_is_valid) regras(e);
            setBackdrop(false);
          })
          .catch((error) => {
            logOff();
            setBackdrop(false);
          })
          .finally(() => setRefreshCookie(false));
      }
    }
    if (backdrop) callBackdrop();
    if (window.location.pathname !== '/dashboard') cancelDataEventSync();
  }, []);

  const { dadosEtiqueta, dadosEtiquetaSorteio } =
    React.useContext(EtiquetaContexto);

  const verificarNome = (e) => {
    let name;
    let aux;

    if (e.length > 15) {
      aux = e.split(' ');
      name = aux[0] + ' ' + aux[aux.length - 1];
      if (name.length > 15) {
        name = name.slice(0, 15) + '.';
      }
    } else {
      name = e;
    }
    return name;
  };

  // console.log('dados etiqueta sorteio APP: ', dadosEtiquetaSorteio)

  return (
    <>
      <div className="App">
        <ThemeProvider theme={defaultMaterialTheme}>
          <Router>
            {user.token ? (
              <div
                style={{
                  paddingTop: '5rem',
                  paddingLeft: '0rem',
                  paddingRight: '1rem',
                }}
              >
                <Route
                  path="/:id2"
                  children={
                    <Logado
                      user={user}
                      logOff={(e) => logOff()}
                      abrir={() => setAberto(!aberto)}
                    />
                  }
                />
                <div
                  style={{
                    marginLeft:
                      window.innerWidth > 675
                        ? aberto
                          ? '20rem'
                          : '7rem'
                        : '5rem',
                    transition: '1s',
                  }}
                >
                  {/* <ThemeProvider theme={defaultMaterialTheme}> */}
                  {/* <Conteudo>

            </Conteudo> */}
                  <br />
                  <Switch>
                    <EventsContextProvider user={user}>
                      <Route
                        path="/eventos"
                        children={<Eventos user={user} />}
                      />

                      <Route
                        exact
                        path="/dashboard"
                        children={
                          <Dashboard
                            user={user}
                            cancelDataSync={() => cancelDataEventSync()}
                            idinterval={idinterval}
                          />
                        }
                      />
                      <Route
                        exact
                        path="/dashboard/comparar"
                        children={<Comparareventos user={user} />}
                      />
                      <Route
                        path="/dashboard/comparar/:id"
                        children={<Comparareventos user={user} />}
                      />
                      <Route
                        path="/inscricoes"
                        children={<Relatorios user={user} />}
                      />

                      <Route
                        path="/editar-evento/:id"
                        children={
                          <>
                            <EdicaoEventoProvider user={user}>
                              <EditarEvento user={user} />
                            </EdicaoEventoProvider>
                          </>
                        }
                      />

                      <Route
                        path="/etiquetas"
                        children={<Etiquetas user={user} />}
                      />
                      <Route
                        path="/etiquetas-sorteio"
                        children={<EtiquetasSorteio user={user} />}
                      />
                      <Route
                        path="/configuracoes"
                        children={
                          <Configuracoes user={user} logOff={(e) => logOff()} />
                        }
                      />

                      <Route
                        path="/pessoas"
                        children={<Palestrante user={user} />}
                      />
                      <Route
                        path="/apoiadores"
                        children={<Apoiadores user={user} />}
                      />
                      <Route
                        path="/patrocinadores"
                        children={<Patrocinadores user={user} />}
                      />
                      <Route
                        path="/expositores"
                        children={<Expositores user={user} />}
                      />
                      <Route path="/blog" children={<Blog user={user} />} />
                      <Route
                        path="/programacoes"
                        children={<Palestras user={user} />}
                      />
                      <Route
                        path="/notificacao"
                        children={<Aviseme user={user} />}
                      />
                      <Route
                        path="/exportardados"
                        children={<Exportardados user={user} />}
                      />
                      <Route
                        path="/alterarmensagens"
                        children={<Alterarmensagens user={user} />}
                      />
                      <Route exact path="*">
                        <Redirect to="/eventos" />
                      </Route>
                    </EventsContextProvider>
                  </Switch>
                </div>
              </div>
            ) : (
              <Login setUser={(e) => handleUser(e)} />
            )}
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Router>
        </ThemeProvider>
      </div>
      {dadosEtiqueta.credential_name &&
      dadosEtiqueta.credential_role &&
      dadosEtiqueta.credential_company ? (
        <div
          className="etiqueta etiqueta2"
          id="printable"
          style={{
            width: '8cm',
            height: '3cm',
          }}
        >
          <div className="informacoes" id="sua_div">
            <span className="nomeCredencial">
              {verificarNome(dadosEtiqueta.credential_name)}
            </span>
            <span className="demaisCredencial">
              {dadosEtiqueta.credential_role}
            </span>
            <span className="demaisCredencial2">
              {dadosEtiqueta.credential_company}
            </span>
            <div className="div__cod_barra">
              <svg id="barcode1"></svg>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      {dadosEtiquetaSorteio.credential_id &&
      dadosEtiquetaSorteio.credential_name &&
      dadosEtiquetaSorteio.credential_role &&
      dadosEtiquetaSorteio.credential_company &&
      dadosEtiquetaSorteio.event_city &&
      dadosEtiquetaSorteio.event_state &&
      dadosEtiquetaSorteio.event_date ? (
        <div
          className="etiqueta_sorteio"
          id="printable"
          style={{
            width: '8cm',
            height: '5cm',
          }}
        >
          <div className="cod_inscricao">
            {dadosEtiquetaSorteio.credential_id}
          </div>
          <div className="dadosSorteioTicket">
            <h2>{dadosEtiquetaSorteio.credential_name}</h2>
            <h3>{dadosEtiquetaSorteio.credential_role}</h3>
            <h3>{dadosEtiquetaSorteio.credential_company}</h3>
            <h3>
              {/* {(dadosEtiquetaSorteio.another_type_inscription
                ? dadosEtiquetaSorteio.another_type_inscription
                : dadosEtiquetaSorteio.type_inscription
              ).slice(0, 35)} */}
            </h3>
            <h4>
              <LocationOnIcon />
              {dadosEtiquetaSorteio.event_city} -{' '}
              {dadosEtiquetaSorteio.event_state}{' '}
              {dadosEtiquetaSorteio.event_date &&
                new Intl.DateTimeFormat('pt-BR').format(
                  new Date(dadosEtiquetaSorteio.event_date)
                )}
            </h4>
          </div>
          <div className="descricaoSorteio">
            <EmojiEventsIcon /> SORTEIO
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}

export default App;
