import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import API from '../../../services/api';

import { CircularProgress, TextField } from '@material-ui/core';

// material ui
import Autocomplete from '@material-ui/lab/Autocomplete';
import EventIcon from '@material-ui/icons/Event';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import RemoveIcon from '@material-ui/icons/Remove';

import { isAfter, differenceInDays } from 'date-fns';

import { Container } from './styles';
import { dashboard } from '../../../utils/dashboard';
import { useEvents } from '../../../context/eventsContext';

function Comparareventos(props) {
  const { id } = useParams();

  const [loadind, setLoading] = useState(false);
  const [analise, setAnalise] = useState({
    cInscritos: null,
    cPresentes: null,
    cNaoPresentes: null,
  });

  const { events } = useEvents();
  const [eventSelected1, setEventSelected1] = useState(null);
  const [enrollmentData1, setEnrollmentData1] = useState(null);
  const [eventSelectedId1, setEventSelectedId1] = useState(null);
  const [dataExtraEvent1, setDataExtraEvent1] = useState(null);

  const [eventSelected2, setEventSelected2] = useState(null);
  const [enrollmentData2, setEnrollmentData2] = useState(null);
  const [eventSelectedId2, setEventSelectedId2] = useState(null);
  const [dataExtraEvent2, setDataExtraEvent2] = useState(null);

  const getEnrollmentsEvent1 = useCallback(async () => {
    eventSelectedId1 && setLoading(true);

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    eventSelectedId1 &&
      (await API.get(`/enrollments/${eventSelectedId1}`, config)
        .then((response) => {
          '';
          let enrollments = response.data;

          const endDate =
            eventSelected1.start_date &&
            isAfter(new Date(), eventSelected1.created_at)
              ? new Date(eventSelected1.start_date)
              : new Date();

          const period = differenceInDays(
            endDate,
            new Date(eventSelected1.created_at)
          );

          let data = dashboard(period, enrollments);
          setEnrollmentData1(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        }));
  }, [
    eventSelected1.created_at,
    eventSelected1.start_date,
    eventSelectedId1,
    props.user.token,
  ]);

  const getDataExtraEvent1 = useCallback(async () => {
    eventSelectedId1 && setLoading(true);

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    eventSelectedId1 &&
      (await API.get(`/events/data-numbers/${eventSelectedId1}`, config)
        .then((response) => {
          '';
          setDataExtraEvent1(response.data);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        }));
  }, [eventSelectedId1, props.user.token]);

  const getEnrollmentsEvent2 = useCallback(async () => {
    eventSelectedId2 && setLoading(true);

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    eventSelectedId2 &&
      (await API.get(`/enrollments/${eventSelectedId2}`, config)
        .then((response) => {
          '';
          let enrollments = response.data;

          const endDate =
            eventSelected2.start_date &&
            isAfter(new Date(), eventSelected2.created_at)
              ? new Date(eventSelected2.start_date)
              : new Date();

          const period = differenceInDays(
            endDate,
            new Date(eventSelected2.created_at)
          );

          let data = dashboard(period, enrollments);
          setEnrollmentData2(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        }));
  }, [
    eventSelected2.created_at,
    eventSelected2.start_date,
    eventSelectedId2,
    props.user.token,
  ]);

  const getDataExtraEvent2 = useCallback(async () => {
    eventSelectedId2 && setLoading(true);

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    eventSelectedId2 &&
      (await API.get(`/events/data-numbers/${eventSelectedId2}`, config)
        .then((response) => {
          '';
          setDataExtraEvent2(response.data);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        }));
  }, [props.user.token, eventSelectedId2]);

  const onChangeEventSelected1 = (event, newValue) => {
    setEventSelected1(newValue);
    setEnrollmentData1(null);
    setEventSelectedId1(newValue.id);
  };

  const onChangeEventSelected2 = (event, newValue) => {
    setEventSelected2(newValue);
    setEnrollmentData2(null);
    setEventSelectedId2(newValue.id);
  };

  const analiseDados = useCallback(() => {
    if (
      enrollmentData1 &&
      enrollmentData2 &&
      dataExtraEvent1 &&
      dataExtraEvent2
    ) {
      // comparação de números de inscritos
      let resInscritos = (
        ((enrollmentData2.number_enrolled - enrollmentData1.number_enrolled) /
          enrollmentData1.number_enrolled) *
        100
      ).toFixed(2);

      // comparação de números de presentes
      let resPresentes = (
        ((enrollmentData2.number_enrolled_participants -
          enrollmentData1.number_enrolled_participants) /
          enrollmentData1.number_enrolled_participants) *
        100
      ).toFixed(2);

      // comparação de números de não presentes
      let resNaoPresentes = (
        ((enrollmentData2.number_enrolled_not_participants -
          enrollmentData1.number_enrolled_not_participants) /
          enrollmentData1.number_enrolled_not_participants) *
        100
      ).toFixed(2);

      // comparação de números de expositores
      let resExhibitors = (
        ((dataExtraEvent2.exhibitors - dataExtraEvent1.exhibitors) /
          dataExtraEvent1.exhibitors) *
        100
      ).toFixed(2);

      // comparação de números de palestrantes
      let resSpeakers = (
        ((dataExtraEvent2.speakers - dataExtraEvent1.speakers) /
          dataExtraEvent1.speakers) *
        100
      ).toFixed(2);

      // comparação de números de patrocinadores
      let resSponsors = (
        ((dataExtraEvent2.sponsors - dataExtraEvent1.sponsors) /
          dataExtraEvent1.sponsors) *
        100
      ).toFixed(2);

      // comparação de números de apoiadores
      let resSupports = (
        ((dataExtraEvent2.supports - dataExtraEvent1.supports) /
          dataExtraEvent1.supports) *
        100
      ).toFixed(2);

      setAnalise({
        ...analise,
        cInscritos: resInscritos,
        cPresentes: resPresentes,
        cNaoPresentes: resNaoPresentes,
        cExhibitors: resExhibitors,
        cSpeakers: resSpeakers,
        cSponsors: resSponsors,
        cSupports: resSupports,
      });
    }
  }, [
    analise,
    dataExtraEvent1,
    dataExtraEvent2,
    enrollmentData1,
    enrollmentData2,
  ]);

  useEffect(() => {
    if (id && !isNaN(Number(id))) {
      let event = events.find((event) => event.id === Number(id));

      if (!event) {
        alert('evento não existe');
        return;
      }

      setEventSelected1(event);
      setEventSelectedId1(id);
    }
  }, [events, id]);

  useEffect(() => {
    if (eventSelectedId1) {
      getEnrollmentsEvent1();
      getDataExtraEvent1();
    } else {
      // setEventSelected1(null)
      setEnrollmentData1(null);
    }
  }, [eventSelectedId1, getDataExtraEvent1, getEnrollmentsEvent1]);

  useEffect(() => {
    if (eventSelectedId2) {
      getEnrollmentsEvent2();
      getDataExtraEvent2();
    } else {
      setEventSelected2(null);
      setEnrollmentData2(null);
    }
  }, [eventSelectedId2, getDataExtraEvent2, getEnrollmentsEvent2]);

  useEffect(() => {
    analiseDados();
  }, [analiseDados]);

  return (
    <Container>
      <div className="pagina">
        <div className="header">
          <h1>Comparar eventos</h1>
          <p>
            Adicione ou exclua palestras que poderão ser vinculados a eventos.
          </p>
        </div>
        <div className="corpo">
          <div>
            <h2>
              <EventIcon /> Evento 1
            </h2>
            <Autocomplete
              id="combo-box-demo"
              className="autoComplete"
              options={events}
              value={eventSelected1}
              getOptionLabel={(event) => event.option}
              style={{ width: '100%' }}
              noOptionsText={'Evento não encontrado'}
              onChange={(event, newValue) =>
                onChangeEventSelected1(event, newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecionar evento"
                  variant="standard"
                />
              )}
              clearText="Limpar"
            />
          </div>
          <div>
            <h2>
              <EventIcon /> Evento 2
            </h2>
            <Autocomplete
              id="combo-box-demo"
              className="autoComplete"
              disabled={eventSelected1 ? false : true}
              options={events}
              value={eventSelected2}
              getOptionLabel={(event) => event.option}
              style={{ width: '100%' }}
              noOptionsText={'Evento não encontrado'}
              onChange={(event, newValue) =>
                onChangeEventSelected2(event, newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecionar evento"
                  variant="standard"
                />
              )}
              clearText="Limpar"
            />
          </div>
        </div>
        <div className="div__comparacao">
          <div className="div__linha">
            <div className="div__coluna_titulo" />
            <div className="div__coluna image_evento1">
              <div className="gradiente">
                {eventSelected1 && (
                  <div className="titulo">
                    {eventSelected1.localization}
                    <br />
                  </div>
                )}
                {eventSelected1 && (
                  <div className="descricao">
                    {eventSelected1.date_event_long}
                  </div>
                )}
              </div>
            </div>
            <div className="div__coluna image_evento2">
              <div className="gradiente">
                {eventSelected2 && (
                  <div className="titulo">
                    {eventSelected2.localization}
                    <br />
                  </div>
                )}
                {eventSelected2 && (
                  <div className="descricao">
                    {eventSelected2.date_event_long}
                  </div>
                )}
              </div>
            </div>
            <div className="div__resultados" />
            <div className="indicador" style={{ flex: 0.2 }} />
          </div>

          <div className="div__linha">
            <div className="div__coluna_titulo">Nº de Inscritos</div>
            <div className="div__coluna">
              {enrollmentData1 ? enrollmentData1.number_enrolled : ' - '}
            </div>
            <div className="div__coluna">
              {enrollmentData2 ? enrollmentData2.number_enrolled : ' - '}
            </div>

            {/* TROCAR CALCULO */}
            <div className="div__resultados">
              {analise.cInscritos
                ? analise.cInscritos === 'NaN'
                  ? 'Não é possível inferir dados com estas informações.'
                  : analise.cInscritos === 'Infinity'
                  ? `O segundo evento teve crescimento expressivo com relação ao primeiro evento.`
                  : analise.cInscritos > 0
                  ? `O segundo evento teve acréscimo de ${analise.cInscritos}% com relação ao primeiro evento.`
                  : `O segundo evento teve decaimento de ${
                      -1 * analise.cInscritos
                    }% com relação ao primeiro evento.`
                : ' - '}
            </div>
            {/* TROCAR CALCULO */}
            <div className="indicador">
              {analise.cInscritos ? (
                analise.cInscritos === 'NaN' ? (
                  <RemoveIcon className="neutro" />
                ) : analise.cInscritos === 'Infinity' ? (
                  <KeyboardArrowUpIcon className="alta" />
                ) : analise.cInscritos > 0 ? (
                  <KeyboardArrowUpIcon className="alta" />
                ) : (
                  <KeyboardArrowDownIcon className="baixa" />
                )
              ) : (
                ' - '
              )}
            </div>
          </div>

          <div className="div__linha">
            <div className="div__coluna_titulo">Nº de Presentes</div>
            <div className="div__coluna">
              {enrollmentData1
                ? enrollmentData1.number_enrolled_participants
                : ' - '}
            </div>
            <div className="div__coluna">
              {enrollmentData2
                ? enrollmentData2.number_enrolled_participants
                : ' - '}
            </div>
            <div className="div__resultados">
              {analise.cPresentes
                ? analise.cPresentes === 'NaN'
                  ? 'Não é possível inferir dados com estas informações.'
                  : analise.cPresentes === 'Infinity'
                  ? `O segundo evento teve crescimento expressivo com relação ao primeiro evento.`
                  : analise.cPresentes > 0
                  ? `O segundo evento teve acréscimo de ${analise.cPresentes}% com relação ao primeiro evento.`
                  : `O segundo evento teve decaimento de ${
                      -1 * analise.cPresentes
                    }% com relação ao primeiro evento.`
                : ' - '}
            </div>
            <div className="indicador">
              {analise.cPresentes ? (
                analise.cPresentes === 'NaN' ? (
                  <RemoveIcon className="neutro" />
                ) : analise.cPresentes === 'Infinity' ? (
                  <KeyboardArrowUpIcon className="alta" />
                ) : analise.cPresentes > 0 ? (
                  <KeyboardArrowUpIcon className="alta" />
                ) : (
                  <KeyboardArrowDownIcon className="baixa" />
                )
              ) : (
                ' - '
              )}
            </div>
          </div>

          <div className="div__linha">
            <div className="div__coluna_titulo">Nº de Não Presentes</div>
            <div className="div__coluna">
              {enrollmentData1
                ? enrollmentData1.number_enrolled_not_participants
                : ' - '}
            </div>
            <div className="div__coluna">
              {enrollmentData2
                ? enrollmentData2.number_enrolled_not_participants
                : ' - '}
            </div>
            <div className="div__resultados">
              {analise.cNaoPresentes
                ? analise.cNaoPresentes === 'NaN'
                  ? 'Não é possível inferir dados com estas informações.'
                  : (analise.cNaoPresentes = 'Infinity'
                      ? `O segundo evento teve crescimento expressivo com relação ao primeiro evento.`
                      : analise.cNaoPresentes > 0
                      ? `O segundo evento teve acréscimo de ${analise.cNaoPresentes}% com relação ao primeiro evento.`
                      : `O segundo evento teve decaimento de ${
                          -1 * analise.cNaoPresentes
                        }% com relação ao primeiro evento.`)
                : ' - '}
            </div>
            <div className="indicador">
              {analise.cNaoPresentes ? (
                analise.cNaoPresentes === 'NaN' ? (
                  <RemoveIcon className="neutro" />
                ) : analise.cNaoPresentes === 'Infinity' ? (
                  <KeyboardArrowUpIcon className="alta" />
                ) : analise.cNaoPresentes > 0 ? (
                  <KeyboardArrowUpIcon className="alta" />
                ) : (
                  <KeyboardArrowDownIcon className="baixa" />
                )
              ) : (
                ' - '
              )}
            </div>
          </div>

          <div className="div__linha">
            <div className="div__coluna_titulo">Qtd. de Expositores</div>
            <div className="div__coluna">
              {dataExtraEvent1 ? dataExtraEvent1.exhibitors : ' - '}
            </div>
            <div className="div__coluna">
              {dataExtraEvent2 ? dataExtraEvent2.exhibitors : ' - '}
            </div>
            <div className="div__resultados">
              {analise.cExhibitors
                ? analise.cExhibitors === 'NaN'
                  ? 'Não é possível inferir dados com estas informações.'
                  : analise.cExhibitors === 'Infinity'
                  ? `O segundo evento teve crescimento expressivo com relação ao primeiro evento.`
                  : analise.cExhibitors > 0
                  ? `O segundo evento teve acréscimo de ${analise.cExhibitors}% com relação ao primeiro evento.`
                  : `O segundo evento teve decaimento de ${
                      -1 * analise.cExhibitors
                    }% com relação ao primeiro evento.`
                : ' - '}
            </div>
            <div className="indicador">
              {analise.cExhibitors ? (
                analise.cExhibitors === 'NaN' ? (
                  <RemoveIcon className="neutro" />
                ) : analise.cExhibitors === 'Infinity' ? (
                  <KeyboardArrowUpIcon className="alta" />
                ) : analise.cExhibitors > 0 ? (
                  <KeyboardArrowUpIcon className="alta" />
                ) : (
                  <KeyboardArrowDownIcon className="baixa" />
                )
              ) : (
                ' - '
              )}
            </div>
          </div>

          <div className="div__linha">
            <div className="div__coluna_titulo">Qtd. de Palestrantes</div>
            <div className="div__coluna">
              {dataExtraEvent1 ? dataExtraEvent1.speakers : ' - '}
            </div>
            <div className="div__coluna">
              {dataExtraEvent2 ? dataExtraEvent2.speakers : ' - '}
            </div>
            <div className="div__resultados">
              {analise.cSpeakers
                ? analise.cSpeakers === 'NaN'
                  ? 'Não é possível inferir dados com estas informações.'
                  : analise.cSpeakers === 'Infinity'
                  ? `O segundo evento teve crescimento expressivo com relação ao primeiro evento.`
                  : analise.cSpeakers > 0
                  ? `O segundo evento teve acréscimo de ${analise.cSpeakers}% com relação ao primeiro evento.`
                  : `O segundo evento teve decaimento de ${
                      -1 * analise.cSpeakers
                    }% com relação ao primeiro evento.`
                : ' - '}
            </div>
            <div className="indicador">
              {analise.cSpeakers ? (
                analise.cSpeakers === 'NaN' ? (
                  <RemoveIcon className="neutro" />
                ) : analise.cSpeakers === 'Infinity' ? (
                  <KeyboardArrowUpIcon className="alta" />
                ) : analise.cSpeakers > 0 ? (
                  <KeyboardArrowUpIcon className="alta" />
                ) : (
                  <KeyboardArrowDownIcon className="baixa" />
                )
              ) : (
                ' - '
              )}
            </div>
          </div>

          <div className="div__linha">
            <div className="div__coluna_titulo">Qtd. de Patrocinadores</div>
            <div className="div__coluna">
              {dataExtraEvent1 ? dataExtraEvent1.sponsors : ' - '}
            </div>
            <div className="div__coluna">
              {dataExtraEvent2 ? dataExtraEvent2.sponsors : ' - '}
            </div>
            <div className="div__resultados">
              {analise.cSponsors
                ? analise.cSponsors === 'NaN'
                  ? 'Não é possível inferir dados com estas informações.'
                  : analise.cSponsors === 'Infinity'
                  ? `O segundo evento teve crescimento expressivo com relação ao primeiro evento.`
                  : analise.cSponsors > 0
                  ? `O segundo evento teve acréscimo de ${analise.cSponsors}% com relação ao primeiro evento.`
                  : `O segundo evento teve decaimento de ${
                      -1 * analise.cSponsors
                    }% com relação ao primeiro evento.`
                : ' - '}
            </div>
            <div className="indicador">
              {analise.cSponsors ? (
                analise.cSponsors === 'NaN' ? (
                  <RemoveIcon className="neutro" />
                ) : analise.cSponsors === 'Infinity' ? (
                  <KeyboardArrowUpIcon className="alta" />
                ) : analise.cSponsors > 0 ? (
                  <KeyboardArrowUpIcon className="alta" />
                ) : (
                  <KeyboardArrowDownIcon className="baixa" />
                )
              ) : (
                ' - '
              )}
            </div>
          </div>

          <div className="div__linha">
            <div className="div__coluna_titulo">Qtd. de Apoiadores</div>
            <div className="div__coluna">
              {dataExtraEvent1 ? dataExtraEvent1.supports : ' - '}
            </div>
            <div className="div__coluna">
              {dataExtraEvent2 ? dataExtraEvent2.supports : ' - '}
            </div>
            <div className="div__resultados">
              {analise.cSupports
                ? analise.cSupports === 'NaN'
                  ? 'Não é possível inferir dados com estas informações.'
                  : analise.cSupports === 'Infinity'
                  ? `O segundo evento teve crescimento expressivo com relação ao primeiro evento.`
                  : analise.cSupports > 0
                  ? `O segundo evento teve acréscimo de ${analise.cSupports}% com relação ao primeiro evento.`
                  : `O segundo evento teve decaimento de ${
                      -1 * analise.cSupports
                    }% com relação ao primeiro evento.`
                : ' - '}
            </div>
            <div className="indicador">
              {analise.cSupports ? (
                analise.cSupports === 'NaN' ? (
                  <RemoveIcon className="neutro" />
                ) : analise.cSupports === 'Infinity' ? (
                  <KeyboardArrowUpIcon className="alta" />
                ) : analise.cSupports > 0 ? (
                  <KeyboardArrowUpIcon className="alta" />
                ) : (
                  <KeyboardArrowDownIcon className="baixa" />
                )
              ) : (
                ' - '
              )}
            </div>
          </div>
        </div>
      </div>

      {loadind && (
        <div className="fundo_black">
          <CircularProgress color="inherit" size={50} />
        </div>
      )}
    </Container>
  );
}

export default Comparareventos;
