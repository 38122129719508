import { createContext, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

// api
import api from '../services/api';
import { uf } from '../utils/defaultData';

// endpoints
import {
  GET_LIST_AUDITORIUMS,
  GET_LIST_GENERAL_SCHEDULES,
} from '../services/endpoints';

export const EdicaoEvento = createContext({});

export function EdicaoEventoProvider({ children, user }) {
  const id = useParams().id;

  const [dados, setDados] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [controle, setControle] = useState(true);

  // list auditoriums
  const [listAuditoriums, setListAuditoriums] = useState([]);
  // list generals schedules
  const [listSchedules, setListSchedules] = useState([]);

  // estados que definem cada parte como aberta ou fechada
  const [active, setActive] = useState({
    detalhesEvento: true,
    DetalhesProgramacao: false,
    DetalhesApoiadores: false,
    DetalhesExpositores: false,
  });
  // fim

  const getData = useCallback(async () => {
    setIsLoading(true);
    setControle(false);

    const config = {
      headers: {
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      },
    };

    await api
      .get(`/events/${id}`, config)
      .then((response) => {
        let ev = response.data;
        // console.log(response.data);
        setDados(ev);

        setControle(true);
        setIsLoading(false);
        // console.log(ev);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user.token, id]);

  async function getDataAuditoriums(queryNumber, token) {
    await GET_LIST_AUDITORIUMS(queryNumber, setListAuditoriums, token);
  }
  async function getDataGeneralSchedules(queryNumber, setBackdrop, token) {
    await GET_LIST_GENERAL_SCHEDULES(
      queryNumber,
      setListSchedules,
      setBackdrop,
      token
    );
  }

  return (
    <EdicaoEvento.Provider
      value={{
        dados,
        setDados,
        isLoading,
        setIsLoading,
        controle,
        setControle,
        active,
        setActive,
        getData,
        getDataAuditoriums,
        getDataGeneralSchedules,
        listAuditoriums,
        listSchedules,
        setListAuditoriums,
        setListSchedules,
        user,
        id,
        uf,
      }}
    >
      {children}
    </EdicaoEvento.Provider>
  );
}
