import React from 'react';
import { Container, TicketPrint } from './styles';

// mascara

import PrintIcon from '@material-ui/icons/Print';

// mascaras
import MaskedInput from 'react-text-mask';

import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import Divider from '@material-ui/core/Divider';

// api
import API from '../../../services/api';

// icones
import SearchIcon from '@material-ui/icons/Search';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';

// alertas
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { EtiquetaContexto } from '../../../context/EtiquetaContexto';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function InscricaoMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      // showMask
      // required
    />
  );
}

function EtiquetasSorteio(props) {
  const [dadopesquisa, setDadopesquisa] = React.useState({
    value: '',
  });
  const [filtro, setFiltro] = React.useState('inscricao');
  const [setBackdrop] = React.useState(true);

  const [dados, setDados] = React.useState();

  const [habimp, setHabimp] = React.useState(true);
  const [setParaConfirmar] = React.useState({
    event_id: '',
    id: '',
    type: '',
  });

  const [setMultiplos] = React.useState({
    open: false,
    multi: [],
  });
  const [alertas, setAlertas] = React.useState({
    status: false,
    type: '',
    message: '',
  });

  const handleChange = (e) => {
    setDadopesquisa((dados) => ({ ...dados, value: e.target.value }));
  };

  const atualizarLista = async () => {
    setBackdrop(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    await API.get(
      `enrollments/${filtro === 'inscricao' ? 'id' : 'cpf-list'}/${
        dadopesquisa.value
      }`,
      config
    )
      .then(async (a) => {
        gerarEtiqueta(a.data);
      })
      .catch((error) => {
        console.log(error.response);
      })
      .finally(() => setBackdrop(false));
  };

  const { setDadosEtiquetaSorteio } = React.useContext(EtiquetaContexto);

  const gerarEtiqueta = (dataList) => {
    setHabimp(false);

    if (filtro === 'inscricao') {
      let dadopesquisa2 = dadopesquisa.value.replace(/\s/g, '');
      if (dadopesquisa2.length < 9) {
        setAlertas({
          ...alertas,
          status: true,
          type: 'warning',
          message: 'Preencha a inscrição corretamente',
        });
        setDados('');
      } else {
        if (dataList.length === 0) {
          setAlertas({
            ...alertas,
            status: true,
            type: 'error',
            message: 'Nenhuma inscrição encontrada',
          });
          setDados('');
        } else {
          setDados({
            credential_id: dataList[0].id,
            credential_name: dataList[0].credential_name,
            credential_company: dataList[0].credential_company,
            credential_role: dataList[0].credential_role,
            another_type_inscription: dataList[0].another_type_inscription,
            type_inscription: dataList[0].type_inscription,
            event_city: dataList[0].event.city,
            event_state: dataList[0].event.state,
            event_date: dataList[0].event.start_date,
          });

          setDadosEtiquetaSorteio({
            credential_id: dataList[0].id,
            credential_name: dataList[0].credential_name,
            credential_company: dataList[0].credential_company,
            credential_role: dataList[0].credential_role,
            another_type_inscription: dataList[0].another_type_inscription,
            type_inscription: dataList[0].type_inscription,
            event_city: dataList[0].event.city,
            event_state: dataList[0].event.state,
            event_date: dataList[0].event.start_date,
          });

          setParaConfirmar({
            event_id: dataList[0].event_id,
            id: dataList[0].id,
            type: 'id',
          });
        }
      }
    }
  };

  const novaImpressao = (e) => {
    setHabimp(true);
    window.print();
    setTimeout(() => {
      setDadosEtiquetaSorteio({
        credential_id: '',
        credential_name: '',
        credential_company: '',
        credential_role: '',
        another_type_inscription: '',
        type_inscription: '',
        event_city: '',
        event_state: '',
        event_date: '',
      });
    }, 5000);
  };

  return (
    <div>
      <Container>
        <div className="paginaBranco">
          <header>
            <h1>Gerar Etiquetas para sorteio</h1>
            <p>
              Neste campo é possível gerar etiquetas para o sorteio utilizando
              código de inscrição ou CPF.
            </p>
          </header>

          <br />
          <br />
          <div className="campoDeBusca">
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                await atualizarLista();
              }}
            >
              <FormControl
                variant=""
                margin="dense"
                className="typebusca"
                style={{
                  margin: '0rem 0.5rem',
                  marginLeft: '0rem',
                  width: '250px',
                }}
              >
                <Select
                  labelId="id-selctor-zone"
                  id="id-selctor-zone-select"
                  value={filtro}
                  onChange={(e) => {
                    setFiltro(e.target.value);
                    setDadopesquisa((dados) => ({ ...dados, value: '' }));
                    setMultiplos({ open: false, multi: [] });
                    setDados();
                    setHabimp(false);
                  }}
                  disabled
                  label="Tipo"
                  style={{ height: '40px' }}
                  margin="dense"
                >
                  <MenuItem value={'inscricao'} default>
                    Inscrição
                  </MenuItem>
                  <MenuItem value={'cpf'}>CPF</MenuItem>
                </Select>
              </FormControl>
              <TextField
                required
                id="cnpj-campo"
                label="Inserir"
                value={dadopesquisa.value}
                onChange={handleChange}
                InputProps={{
                  inputComponent: InscricaoMaskCustom,
                }}
                style={{
                  margin: '0rem 0.5rem',
                  marginTop: '-0.25rem',
                  width: '250px',
                }}
                margin="dense"
              />

              <button type="submit">
                <SearchIcon /> Buscar
              </button>
            </form>
          </div>
          <br />
          <br />
          <br />
          <Divider />

          <div className="cardParaImpressao">
            {dados ? (
              <div className="comDados">
                {habimp && <div>Etiqueta impressa</div>}
                <TicketPrint>
                  <div className="div__prim_part">{dados.credential_id}</div>
                  <div className="div__second_part">
                    <h2>{dados.credential_name}</h2>
                    <h3>{dados.credential_role}</h3>
                    <h3>{dados.credential_company}</h3>
                    {/* <h3>{(dados.another_type_inscription ? dados.another_type_inscription : dados.type_inscription).slice(0, 35)}</h3> */}
                    <h4>
                      <LocationOnIcon />
                      {dados.event_city} - {dados.event_state}{' '}
                      {new Intl.DateTimeFormat('pt-BR').format(
                        new Date(dados.event_date)
                      )}
                    </h4>
                  </div>
                  <div className="div__terc_part">
                    <EmojiEventsIcon /> SORTEIO
                  </div>
                </TicketPrint>

                <button
                  className="buttonImprimir"
                  onClick={(e) => novaImpressao()}
                  disabled={habimp}
                >
                  {' '}
                  <PrintIcon /> Imprimir
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </Container>
      {alertas.status ? (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() =>
            setAlertas({ ...alertas, status: false, type: '', message: '' })
          }
        >
          <Alert
            onClose={() =>
              setAlertas({ ...alertas, status: false, type: '', message: '' })
            }
            severity={alertas.type}
          >
            {alertas.message}
          </Alert>
        </Snackbar>
      ) : (
        ''
      )}
    </div>
  );
}

export default EtiquetasSorteio;
