import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  // backgroundColor:'transparent',
  border: 0px;
  border-radius: 6px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  //  max-width:1000px;
  margin: auto;
  padding: 2rem;

  header {
    padding-bottom: 1rem;
    border-bottom: 1px solid #d3d3d3;

    h1 {
      font-weight: 800;
      color: rgba(0, 0, 0, 0.8);
    }

    p {
      color: rgba(0, 0, 0, 0.7);
      font-weight: 600;
    }
  }

  .myaccount {
    padding-top: 1rem;
    margin-bottom: 2rem;

    .header {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 0.25rem;

      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 0.5rem 1rem;

      p {
        font-size: 1.1rem;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.6);
      }

      button {
        padding: 0.5rem;
        background: none;
        outline: none;
        border: 0;
        border-radius: 50%;

        transition: background 0.3s;

        :hover {
          background: rgba(0, 0, 0, 0.3);
        }

        svg {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    .itens {
      padding: 1rem;

      form {
        th {
          font-size: 14px;
          font-weight: 600;
          line-height: 1rem !important;
        }
        span {
          cursor: pointer;
        }
        input,
        select {
          padding: 0.75rem 0.75rem;
          border: 1px solid rgba(0, 0, 0, 0.2) !important;
          option {
            border: 1px solid rgba(0, 0, 0, 0.2) !important;
          }
        }
      }

      table tbody {
        tr {
          font-size: 16px;
          padding: 0.5rem;

          th {
            padding: 0 1rem;
            text-align: right;
          }

          td {
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
              background: none;
              outline: none;
              border: 0;
              padding: 0.75rem 1rem;

              border-radius: 0.5rem;
              color: ${(props) => props.theme.colors.primaryDark};

              font-size: 1rem;

              &.update {
                background: ${(props) => props.theme.colors.primaryDark};
                color: #fff;
              }
            }
            span {
              margin: 0.75rem 1rem;
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  .othersaccount {
    .header {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 0.25rem;

      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 0.5rem 1rem;

      p {
        font-size: 1.1rem;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.6);
      }

      button {
        padding: 0.75rem 1rem;
        background: ${(props) => props.theme.colors.primaryDark};
        outline: none;
        border: 0;
        border-radius: 5px;
        color: #fff;

        transition: background 0.3s;

        :hover {
          filter: brightness(1.3);
        }

        svg {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    .itens {
      padding: 0 2rem;

      table {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-collapse: collapse;

        thead {
          tr {
            display: flex;
            th {
              flex: 1;
              text-align: left;
              padding: 1rem 0;
              font-size: 1rem;
              margin-left: 1rem;

              &.option {
                text-align: right;
              }
            }
          }
        }

        tbody {
          tr {
            font-size: 16px;
            padding: 0rem 0.25rem;
            display: flex;
            margin-bottom: 0.1rem;

            border-radius: 0.5rem;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
            justify-content: center;
            align-items: center;

            td {
              flex: 1;
              padding: 1rem 1rem;
              text-align: left;
              font-size: 1rem;

              &.option {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }

  .linkSenha {
    cursor: pointer;
    margin: 0rem !important;
    padding: 0rem !important;
    color: blue;
  }
  .linkSenha:hover {
    text-decoration: underline;
  }
`;
