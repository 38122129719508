import React, { useState, useCallback, useEffect } from 'react';

import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import { DropzoneArea } from 'material-ui-dropzone';
import { Container, Bannerfield } from './styles';
import api from '../../services/api';
import { TextField } from '@material-ui/core';

// icons
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LinkIcon from '@material-ui/icons/Link';

// libs
import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import Novobanner from '../Novobanner';
import Backdrop from '../Backdrop';
import { GET_LIST_BANNERS } from '../../services/endpoints';

function DetalhesBanners(props) {
  const [backdrop, setbackdrop] = useState(false);
  const [update, setUpdate] = useState(false);
  const [active, setActive] = useState(false);
  const [showNewbanner, setShownewbanner] = useState({
    open: false,
    editar: false,
    data: '',
  });
  let { id } = useParams();
  const [listBanners, setListbanners] = useState([]);

  useEffect(() => {
    GET_LIST_BANNERS(id, setListbanners, setbackdrop, props.user.token);
  }, []);

  return (
    <Container update={update} active={active}>
      <div className="header">
        <div
          className="left"
          onClick={() => (update ? '' : setActive(!active))}
        >
          <span className="icon">&#10148;</span>
          <h2 className="title">Banners</h2>
        </div>

        <div className="button"></div>
      </div>
      <div className="content">
        <div className="campoDeEdicao">
          <div className="header2">
            <p>
              Nesta sessão você poderá gerenciar os <i>banners</i> que estarão
              presentes no evento selecionado.{' '}
            </p>
            <button
              className="cadastroManual"
              onClick={() => setShownewbanner({ ...showNewbanner, open: true })}
            >
              + Adicionar
            </button>
          </div>
        </div>

        <div className="div__deck_banners">
          {listBanners.map((dataBanner, idx) => (
            <div className="div__container_banner">
              <Bannerfield {...dataBanner}>
                <div className="div__field_dateTime">
                  Criado em{' '}
                  {new Date(dataBanner.created_at).toLocaleString('pt-BR', {
                    day: '2-digit',
                    month: 'short',
                    year: '2-digit',
                  })}
                </div>
                <button
                  className="div__field_options"
                  onClick={() =>
                    setShownewbanner({
                      ...showNewbanner,
                      data: dataBanner,
                      editar: true,
                      open: true,
                    })
                  }
                >
                  Editar
                </button>
              </Bannerfield>
              <div className="div__field_link">
                <LinkIcon />
                {dataBanner.link ? (
                  <a href={dataBanner.link} target="_blank" rel="noreferrer">
                    {dataBanner.link}
                  </a>
                ) : (
                  'Sem link'
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {showNewbanner.open && (
        <Novobanner
          id={id}
          {...showNewbanner}
          user={props.user}
          success={() => {
            GET_LIST_BANNERS(id, setListbanners, setbackdrop, props.user.token);
            setShownewbanner({
              ...showNewbanner,
              editar: false,
              data: '',
              open: false,
            });
          }}
          close={() =>
            setShownewbanner({
              ...showNewbanner,
              editar: false,
              data: '',
              open: false,
            })
          }
        />
      )}
      {}

      {backdrop && <Backdrop />}
    </Container>
  );
}

export default DetalhesBanners;
