import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EventIcon from '@material-ui/icons/Event';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import RateReviewIcon from '@material-ui/icons/RateReview';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DashboardIcon from '@material-ui/icons/Dashboard';

import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import StarIcon from '@material-ui/icons/Star';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import LabelIcon from '@material-ui/icons/Label';

import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SubjectIcon from '@material-ui/icons/Subject';

import { Link, useParams } from 'react-router-dom';

import Logo from '../../assets/logo-azul.svg';
import LogoSolar from '../../assets/logoSolar.png';

import { Container, Outcontainer } from './styles';

// temas
import { useTheme } from 'styled-components';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  customlink: (props) => ({
    '& a': {
      color: 'rgba(0,0,0,1)',
      textDecoration: 'none',
      '&:hover': {
        color: props.colors.primaryDark,
      },
    },
  }),
  cabinfo: {
    // background: 'rgba(0,0,0,0.1)',
    padding: '0.9rem 1rem',
    flex: 1,
    float: 'right',
    '&:hover': {
      background: 'rgba(255,255,255,0.1)',
      cursor: 'default',
    },
  },
  cablogoff: {
    // margin:'auto 1.5rem',
    padding: 'auto 1.5rem',
    width: '50px',
    float: 'right',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background: 'rgba(255,255,255,0.1)',
    },
  },
  itemdomenu: (props) => ({
    '& 	.MuiListItemText-primary': {
      fontSize: '14px ',
    },
    '& .MuiListItemIcon-root': {
      '& svg': { width: '20px !important' },
      color: 'rgba(0,0,0, 0.3)',
    },
    '&:hover': {
      borderLeft: `3px solid ${props.colors.primaryDark}`,
      transition: '0.2s',
    },
  }),
  selecionado: (props) => ({
    borderLeft: `3px solid ${props.colors.primaryDark}`,
    color: props.colors.primaryDark,
    transition: '0.2s',
    '& 	.MuiListItemText-primary': {
      fontSize: '14px ',
    },
    '& .MuiListItemIcon-root': {
      '& svg': { width: '20px !important' },
      color: props.colors.primaryDark,
    },
  }),
}));

export default function MiniDrawer(props) {
  // tema
  const theme = useTheme();
  const classes = useStyles(theme);
  const [open, setOpen] = React.useState(
    window.innerWidth > 675 ? true : false
  );

  const handleDrawerOpen = () => {
    setOpen(true);
    props.abrir();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    props.abrir();
  };

  let { id2 } = useParams();

  return (
    <Outcontainer>
      <div className={classes.root} id="paginacaoMenu">
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
          style={{
            backgroundColor: theme.colors.primaryDark,
          }}
        >
          <Toolbar
            style={{
              padding: '0rem',
              display: 'flex !important',
              justifyContent: 'space-between !important',
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
              style={{ margin: '0rem 0.8rem', color: 'white' }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap={window.innerWidth > 675 ? false : true}
              style={{
                width: '100%',
                margin: '0rem 1rem',
                textAlign: 'left',
                color: '#fff',
              }}
            >
              Admin{' '}
              {process.env.REACT_APP_TYPE_SITE === 'solar'
                ? 'Expo Brasil Solar'
                : process.env.REACT_APP_TYPE_SITE === 'isp'
                ? 'Expo ISP'
                : ''}
            </Typography>
            <div
              style={{
                textAlign: 'right',
                display: `${window.innerWidth > 675 ? 'flex' : 'none'}`,
                flexDirection: 'row',
                backgroundColor: '#245C9D !important',
                color: '#fff',
                flex: 1,
                justifyContent: 'flex-end',
                wordWrap: 'break-word',
              }}
            >
              <div className={classes.cabinfo}>
                {props.user.name}
                <br />
                {props.user.email}
              </div>
              <div className={classes.cablogoff} onClick={() => props.logOff()}>
                <ExitToAppIcon />
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <Container>
            <div className={classes.toolbar}>
              <a
                href={'/'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '.25rem 0',
                }}
                rel="noreferrer"
              >
                <img
                  src={
                    process.env.REACT_APP_TYPE_SITE === 'solar'
                      ? LogoSolar
                      : process.env.REACT_APP_TYPE_SITE === 'isp'
                      ? Logo
                      : ''
                  }
                  alt="image_logo"
                  style={{
                    width: '40%',
                    transform: 'scale(1.6)',
                    marginRight: '-4rem',
                  }}
                />
              </a>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>

            <Divider />
            <List className={classes.customlink}>
              {window.innerWidth > 675 ? (
                <ListItem>
                  <ListItemText
                    secondary={
                      <div style={{ opacity: '90%', textAlign: 'center' }}>
                        Menu
                      </div>
                    }
                  />
                </ListItem>
              ) : (
                <ListItem button>
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <>
                        <small style={{ opacity: '90%' }}>Seja bem vindo</small>
                        <br />
                        {props.user.name}
                        <br />
                        <button
                          onClick={props.logOff}
                          style={{
                            border: '0px',
                            fontFamily:
                              "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                          }}
                        >
                          Sair
                        </button>
                      </>
                    }
                  />
                </ListItem>
              )}
              <Divider />

              <Link to="/dashboard">
                <ListItem
                  button
                  className={
                    id2 === 'dashboard'
                      ? classes.selecionado
                      : classes.itemdomenu
                  }
                >
                  <ListItemIcon>
                    <DashboardIcon />{' '}
                  </ListItemIcon>
                  <ListItemText primary={'Dashboard'} />
                </ListItem>
              </Link>

              <Link to="/eventos">
                <ListItem
                  button
                  className={
                    id2 === 'eventos' ? classes.selecionado : classes.itemdomenu
                  }
                >
                  <ListItemIcon>
                    <EventAvailableIcon />{' '}
                  </ListItemIcon>
                  <ListItemText primary={'Eventos'} />
                </ListItem>
              </Link>

              <Link to="/inscricoes">
                <ListItem
                  button
                  className={
                    id2 === 'inscricoes'
                      ? classes.selecionado
                      : classes.itemdomenu
                  }
                >
                  <ListItemIcon>
                    <ListAltIcon />{' '}
                  </ListItemIcon>
                  <ListItemText primary={'Inscrições'} />
                </ListItem>
              </Link>

              <Link to="/etiquetas">
                <ListItem
                  button
                  className={
                    id2 === 'etiquetas'
                      ? classes.selecionado
                      : classes.itemdomenu
                  }
                >
                  <ListItemIcon>
                    <LocalOfferIcon />{' '}
                  </ListItemIcon>
                  <ListItemText primary={'Etiquetas'} />
                </ListItem>
              </Link>

              <Link to="/etiquetas-sorteio">
                <ListItem
                  button
                  className={
                    id2 === 'etiquetas-sorteio'
                      ? classes.selecionado
                      : classes.itemdomenu
                  }
                >
                  <ListItemIcon>
                    <ConfirmationNumberIcon />{' '}
                  </ListItemIcon>
                  <ListItemText primary={'Etiquetas do Sorteio'} />
                </ListItem>
              </Link>

              <Link to="/pessoas">
                <ListItem
                  button
                  className={
                    id2 === 'pessoas' ? classes.selecionado : classes.itemdomenu
                  }
                >
                  <ListItemIcon>
                    <EmojiPeopleIcon />{' '}
                  </ListItemIcon>
                  <ListItemText primary={'Pessoas'} />
                </ListItem>
              </Link>

              <Link to="/programacoes">
                <ListItem
                  button
                  className={
                    id2 === 'programacoes'
                      ? classes.selecionado
                      : classes.itemdomenu
                  }
                >
                  <ListItemIcon>
                    <EventIcon />{' '}
                  </ListItemIcon>
                  <ListItemText primary={'Programações'} />
                </ListItem>
              </Link>

              <Link to="/patrocinadores">
                <ListItem
                  button
                  className={
                    id2 === 'patrocinadores'
                      ? classes.selecionado
                      : classes.itemdomenu
                  }
                >
                  <ListItemIcon>
                    <StarIcon />{' '}
                  </ListItemIcon>
                  <ListItemText primary={'Patrocinadores'} />
                </ListItem>
              </Link>

              <Link to="/apoiadores">
                <ListItem
                  button
                  className={
                    id2 === 'apoiadores'
                      ? classes.selecionado
                      : classes.itemdomenu
                  }
                >
                  <ListItemIcon>
                    <GroupAddIcon />{' '}
                  </ListItemIcon>
                  <ListItemText primary={'Apoiadores'} />
                </ListItem>
              </Link>
              <Link to="/expositores">
                <ListItem
                  button
                  className={
                    id2 === 'expositores'
                      ? classes.selecionado
                      : classes.itemdomenu
                  }
                >
                  <ListItemIcon>
                    <ViewCarouselIcon />{' '}
                  </ListItemIcon>
                  <ListItemText primary={'Expositores'} />
                </ListItem>
              </Link>

              <Link to="/blog">
                <ListItem
                  button
                  className={
                    id2 === 'blog' ? classes.selecionado : classes.itemdomenu
                  }
                >
                  <ListItemIcon>
                    <SubjectIcon />
                  </ListItemIcon>
                  <ListItemText primary="Blog" />
                </ListItem>
              </Link>

              <Link to="/notificacao">
                <ListItem
                  button
                  className={
                    id2 === 'notificacao'
                      ? classes.selecionado
                      : classes.itemdomenu
                  }
                >
                  <ListItemIcon>
                    <AnnouncementIcon />
                  </ListItemIcon>
                  <ListItemText primary="Notificação" />
                </ListItem>
              </Link>

              <Link to="/alterarmensagens">
                <ListItem
                  button
                  className={
                    id2 === 'alterarmensagens'
                      ? classes.selecionado
                      : classes.itemdomenu
                  }
                >
                  <ListItemIcon>
                    <RateReviewIcon />
                  </ListItemIcon>
                  <ListItemText primary="Alterar Mensagens" />
                </ListItem>
              </Link>

              <Link to="/exportardados">
                <ListItem
                  button
                  className={
                    id2 === 'exportardados'
                      ? classes.selecionado
                      : classes.itemdomenu
                  }
                >
                  <ListItemIcon>
                    <ImportExportIcon />
                  </ListItemIcon>
                  <ListItemText primary="Exportar Dados" />
                </ListItem>
              </Link>

              <a
                href="https://doity.com.br/admin/users/login"
                target="_blank"
                rel="noreferrer"
              >
                <ListItem button>
                  <ListItemIcon>
                    <LabelIcon />
                  </ListItemIcon>
                  <ListItemText primary="Acesso Doity" />
                </ListItem>
              </a>

              <a
                href="https://expositor.expoispbrasil.com.br/arearestrita"
                target="_blank"
                rel="noreferrer"
              >
                <ListItem button>
                  <ListItemIcon>
                    <LabelIcon />
                  </ListItemIcon>
                  <ListItemText primary="Área do Expositor" />
                </ListItem>
              </a>

              <Link to="/configuracoes">
                <ListItem
                  button
                  className={
                    id2 === 'configuracoes'
                      ? classes.selecionado
                      : classes.itemdomenu
                  }
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <>
                        Configurações de
                        <br /> Conta
                      </>
                    }
                  />
                </ListItem>
              </Link>
              <Divider />
            </List>
          </Container>
        </Drawer>
      </div>
    </Outcontainer>
  );
}
